/* eslint-disable @typescript-eslint/no-use-before-define */
import { CloseIcon, FeaturedIcon, MerchDropIcon } from "components/atoms/Icon";
import Modal from "components/atoms/modal";
import Button from "components/atoms/buttons/Button";
import { pluralize } from "utils/HelperFunctions";
import MagnetImageContainer from "components/atoms/images/MagnetImageContainer";
import useCountdown from "components/hooks/useCountDown";
import moment from "moment";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

export function ExpiryTimer({
  endTime,
  showTimeAfterExpiry = true,
}: {
  endTime: number | null;
  showTimeAfterExpiry?: boolean;
}) {
  const { days, hours, minutes, seconds } = useCountdown(Number(endTime));

  const getRemainingPollTime = () => {
    if (days) return pluralize(days, "day");
    if (hours) return pluralize(hours, "hour");
    if (minutes) return pluralize(minutes, "min");
    if (seconds) return pluralize(seconds, "sec");
    return "0 sec";
  };

  return (
    <span>
      {/* eslint-disable-next-line no-nested-ternary */}
      {showTimeAfterExpiry
        ? `Expiring in ${getRemainingPollTime()}`
        : days === 0 && hours === 0 && minutes === 0 && seconds === 0
        ? "Expired"
        : `Expiring in ${getRemainingPollTime()}`}
    </span>
  );
}

type Props = {
  merchDropDetails: MerchDropUserData;
  onClose: () => void;
  handleClaimButtonClick: () => void;
  disableClaimButtonClick?: boolean;
};

function UserMerchViewModal({ merchDropDetails, onClose, handleClaimButtonClick, disableClaimButtonClick }: Props) {
  const isMerchDropClaimed = merchDropDetails.is_claimed;
  const isMerchDropExpired = moment() > moment(merchDropDetails.end_time);

  return (
    <Modal open onClose={onClose} closeOnOutsideClick={false}>
      <div className="w-[calc(100vw-20px)] max-w-[600px] space-y-5 rounded-2xl bg-primary px-5 py-7 shadow-modal">
        {/* Section 1  */}
        <div className="flex justify-between">
          <div className="flex space-x-3">
            <img
              className="aspect-1 h-16 rounded-lg border border-white object-cover"
              src={merchDropDetails.magnet_image_url as string}
              alt={merchDropDetails.magnet_name}
            />
            <div className="flex flex-col font-satoshi text-white">
              <span className=" text-xl font-bold md:text-3xl">{merchDropDetails.magnet_name}</span>
              <span className="font-semibold opacity-70">{merchDropDetails.brand_name}</span>
            </div>
          </div>
          <div>
            <button type="button" onClick={onClose} className="cursor-pointer p-3">
              <CloseIcon className="h-4 w-4 text-white" />
            </button>
          </div>
        </div>
        {/* Section 2 */}
        <div className="mx-auto flex flex-col items-center font-satoshi text-white">
          <div className="relative h-48 w-48 rounded-2xl border border-white md:h-72 md:w-72">
            <div
              className="absolute -right-3 -top-3 flex  h-8 w-8 items-center justify-center rounded-full bg-accent md:-right-5 md:-top-5
             md:h-10 md:w-10"
            >
              <MerchDropIcon className="h-5 w-5 md:h-6 md:w-6" />
            </div>
            <MagnetImageContainer image={merchDropDetails.thumbnail_url as string} altText={merchDropDetails.name} />
          </div>
          <div className="mt-4 flex justify-center text-white">
            {isMerchDropClaimed ? (
              <div
                className="flex items-center  space-x-2 rounded-ll bg-secondary bg-opacity-20 p-2 font-satoshi
          text-sm font-semibold leading-5"
              >
                <div>
                  <FeaturedIcon />
                </div>

                <p className="font-semibold">Already Claimed</p>
              </div>
            ) : (
              <div
                className="rounded-ll bg-accent bg-opacity-20 p-2 font-satoshi
             text-sm font-semibold leading-5"
              >
                {isMerchDropExpired ? "Expired" : <ExpiryTimer endTime={merchDropDetails.end_time} />}
              </div>
            )}
          </div>
          <span className="mt-2 text-center font-satoshi text-2xl font-bold leading-tight text-white">
            {merchDropDetails.name}
          </span>
          <span className="secondary-scrollbar my-2 line-clamp-4 w-[90%] overflow-scroll overflow-x-hidden text-center font-satoshi text-xs font-450 text-white">
            {merchDropDetails.description}
          </span>
        </div>
        <div className="flex justify-center">
          <Button
            text={isMerchDropClaimed ? "LIST" : "CLAIM"}
            disabled={disableClaimButtonClick || isMerchDropExpired || isMerchDropClaimed}
            variant={isMerchDropClaimed ? "rustPinkSolid" : "magnetiqSolid"}
            additionalClasses="xl:px-6 xl:py-3 xl:text-xs 2xl:text-sm 2xl:px-8 2xl:py-4"
            onClick={handleClaimButtonClick}
          />
        </div>
      </div>
      {disableClaimButtonClick && (
        <div className="mx-auto -mt-5 flex items-center justify-center gap-2 rounded-b-lg bg-accent p-2">
          <ExclamationTriangleIcon className="h-4 w-4 text-primary" />
          <p className="font-sora text-xxs font-semibold text-primary">Acquire the Magnet to claim this Memento</p>
        </div>
      )}
    </Modal>
  );
}

export default UserMerchViewModal;
