import BrandDetailsIcon from "../assets/Icons/BrandDetails.svg";
import AddressDetailsIcon from "../assets/Icons/AddressDetails.svg";
import ProfileDetailsIcon from "../assets/Icons/ProfileDetails.svg";

import AddressDetailsSetting from "../assets/Icons/AddressDetailsSetting.svg";
import BrandInfoIconSetting from "../assets/Icons/BrandInfoIconSetting.svg";
import LinksSetting from "../assets/Icons/LinksSetting.svg";

export const BrandOnboardingStepsCopy = [
  {
    formHeading: "Brand Details",
    icon: BrandDetailsIcon,
    settingIcon: BrandInfoIconSetting,
    formDescription: "Please enter Brand Name and a little bit About the Brand.",
  },
  {
    formHeading: "Location Details",
    icon: AddressDetailsIcon,
    settingIcon: AddressDetailsSetting,
    formDescription: "Please Enter the Brand’s Registered Location.",
  },
  {
    formHeading: "Brand Profile Details",
    icon: ProfileDetailsIcon,
    settingIcon: LinksSetting,
    formDescription:
      "Please upload your Brand Logo and Cover Image.<br/>Also, please provide any relevant Links that you'd like to share.",
  },
];

export const TOOLTIP_HINT_TEXT = {
  GatedContentCheckboxTooltipText:
    "By default, content is exclusive to Magnet buyers. Disable this checkbox to make the content visible to everyone, regardless of ownership.",
  AutoAirdropCheckbox:
    "By enabling this option the new users who claim the Magnet will also receive this Memento automatically. If unchecked, only the users already owning the Magnet will receive this Memento.",
  ReleaseWhitelistingSwitch:
    "Please Confirm, to enable whitelisting for this release. Enabling this will restrict the release to a specific list of User Email IDs, allowing only those users to claim the Magnet associated with this release.",
  EventBasedReleaseSwitch:
    "If this option is enabled this release will be accessible to users only through the claim page of this release. Also, paid magnets don't have this feature enabled currently",
};
export const AutoAirdropCheckboxTooltipText =
  "By enabling this option the new users who claim the Magnet will also receive this Memento automatically. If unchecked, only the users already owning the Magnet will receive this Memento.";

export const skipUserOnboardingForBrandList = ["nolcha_shows", "magnetiq"];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const email_regex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const countryList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Angola",
  "Anguilla",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Democratic Republic of The Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Federated States of Micronesia",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern and Antarctic Lands",
  "Gabon",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-bissau",
  "Guyana",
  "Haiti",
  "Heard Island and Mcdonald Islands",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "North Korea",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn Islands",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of Macedonia",
  "Republic of The Congo",
  "Romania",
  "Russia",
  "Rwanda",
  "Réunion",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and The Grenadines",
  "Samoa",
  "San Marino",
  "Saudi Arabia",
  "Scotland",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "São Tomé and Príncipe",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "The Bahamas",
  "The Gambia",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Wales",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
