import { activationKeyUserDescriptionMap } from "../../../constants";

export default function IncentivePreview({ incentiveData }: { incentiveData: IncentiveMemento }) {
  return (
    <button
      title={incentiveData.name}
      type="button"
      className="relative flex w-56 shrink-0 items-center space-x-3 rounded-md bg-white bg-opacity-25 px-2 py-1 font-satoshi shadow-sm"
    >
      <div className="flex-shrink-0">
        <img className="h-10 w-10 rounded-md" src={incentiveData.thumbnail_url} alt="" />
      </div>
      <div className="min-w-0 flex-1">
        <p className="truncate text-ellipsis text-left text-xs font-bold text-white">{incentiveData.name}</p>
        <p className="-mt-1 text-left text-xxs font-medium text-gray-300">
          {activationKeyUserDescriptionMap[incentiveData.activation_key]}
        </p>
      </div>
    </button>
  );
}
