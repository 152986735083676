import { Popover } from "@headlessui/react";
import Button from "components/atoms/buttons/Button";
import DefaultImage from "assets/Images/BrandImage1.png";
import NotificationCard from "components/atoms/cards/user/NotificationCard";
import { LoadingIcon } from "components/atoms/Icon";
import { BellSnoozeIcon } from "@heroicons/react/24/solid";
import { useUserContext } from "components/context/UserContext";

function NotificationPopoverContent({
  notifications,
  error,
  handleMarkNotificationAsReadAndNavigateToCta,
}: {
  notifications: NotificationData[] | undefined;
  error: boolean;
  handleMarkNotificationAsReadAndNavigateToCta: (notification: NotificationData) => void;
}) {
  const { setNotificationSubType } = useUserContext();
  // Empty state render view
  if (notifications === undefined || notifications.length === 0) {
    return (
      <div className="flex h-full flex-col items-center justify-center gap-1 rounded-2xl border-1 border-dashed border-white text-white">
        <BellSnoozeIcon className="h-1/5" />
        <p className="font-satoshi">You have no notifications right now.</p>
      </div>
    );
  }
  return (
    <>
      {notifications?.map((notification: NotificationData) => (
        <NotificationCard
          key={`notification-${notification.id}`}
          title={notification.title}
          image={notification.image_url || DefaultImage}
          description={notification.description}
          createdAt={notification.created_at}
          isRead={notification.is_read}
          handleMarkNotificationAsReadAndNavigateToCta={() => {
            setNotificationSubType(notification.sub_type);
            handleMarkNotificationAsReadAndNavigateToCta(notification);
          }}
        />
      ))}
      {error && <div className="flex items-center justify-center font-satoshi text-xl">Something went wrong!</div>}
    </>
  );
}

type Props = {
  unreadNotificationsCount: number | undefined;
  handleMarkAllAsReadClick: () => void;
  handleNavigateToNotifcationPage?: () => void;
  notifications: NotificationData[] | undefined;
  error: boolean;
  handleMarkNotificationAsReadAndNavigateToCta: (notification: NotificationData) => void;
  isLoading: boolean;
};

function NotificationPopover({
  error,
  unreadNotificationsCount,
  handleMarkAllAsReadClick,
  handleNavigateToNotifcationPage,
  notifications,
  handleMarkNotificationAsReadAndNavigateToCta,
  isLoading,
}: Props) {
  return (
    <Popover.Panel className="absolute bottom-0  left-0 right-0 top-[66px] z-40   md:bottom-auto md:left-auto md:right-14 md:top-20 2xl:right-10">
      <div
        className="flex h-[calc(100vh-66px)] w-screen flex-col rounded-none border-none bg-gradient-to-b  from-[#000000] to-[#B58D8C] shadow-modal drop-shadow-none  md:h-auto  md:w-[400px] md:rounded-ll md:border-[0.5px] md:border-solid md:border-white md:border-opacity-70 md:from-primary md:to-primary
           md:drop-shadow-lg 2xl:w-[500px]"
      >
        {/* Top Header */}
        <div className="flex justify-between p-3 2xl:p-5">
          <div className="flex items-center space-x-1 2xl:space-x-2">
            {(unreadNotificationsCount || 0) > 0 && (
              <p
                className="flex h-5
                  w-5 items-center justify-center rounded-full bg-accent p-4 font-satoshi text-xxs
                 font-semibold leading-none text-primary 2xl:h-6 2xl:w-6 2xl:text-sm"
              >
                {unreadNotificationsCount}
              </p>
            )}
            <p className="font-sora text-xs font-semibold text-white 2xl:text-lg">NEW NOTIFICATIONS</p>
          </div>
          <Button
            additionalClasses="!font-medium xl:text-xxs 2xl:text-sm"
            text="Mark All as Read"
            onClick={handleMarkAllAsReadClick}
            variant="primary"
          />
        </div>

        {/* Notifications */}
        <div
          className="secondary-scrollbar mx-1
           flex h-full    flex-col gap-y-1.5 overflow-y-scroll px-2 md:h-96 2xl:mx-3
          2xl:h-[460px] 2xl:gap-y-2.5"
        >
          {isLoading ? (
            <div className="flex h-full w-full items-center justify-center">
              <div className="h-32 w-32">
                <LoadingIcon color="#102D4E" />
              </div>
            </div>
          ) : (
            <NotificationPopoverContent
              error={error}
              handleMarkNotificationAsReadAndNavigateToCta={handleMarkNotificationAsReadAndNavigateToCta}
              notifications={notifications}
            />
          )}
        </div>
        {/* Button */}
        <div className=" p-3 2xl:p-5 ">
          <Button
            text="View Notification History"
            onClick={handleNavigateToNotifcationPage}
            variant="magnetiqSolid"
            additionalClasses="w-full xl:rounded-lg 2xl:rounded-ll xl:h-12 2xl:h-16 font-satoshi
               xl:text-xs 2xl:text-lg"
          />
        </div>
      </div>
    </Popover.Panel>
  );
}
export default NotificationPopover;
