import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { getAllBrandsURL, getBrandWithIdURL, getBrandWithTagURL } from "../../apis/brand";
import { ROUTES } from "../../constants";
import { get } from "../../utils/request";
import Loading from "../atoms/Loading";
import { UserContext } from "./UserContext";

export type BrandContextType = {
  isError: boolean;
  brandDetails?: any;
  brandId: string | null | undefined;
  loading: boolean;
  isBrandSidbarVisible: boolean;
  revalidateBrandDetails: () => void;
  getBrands: (tagName?: string) => Promise<[]>;
};

const notInitialised = (): void => {
  toast.error("Context not intialised");
};

export const BrandContext = React.createContext<BrandContextType>({
  isError: false,
  loading: true,
  brandId: null,
  isBrandSidbarVisible: false,
  revalidateBrandDetails: notInitialised,
  getBrands: async () => Promise.resolve([]),
});

function shouldRenderBrandSideBar(pathname: string) {
  return [ROUTES.BRAND_SETTINGS_ROUTE, ROUTES.BRAND_DASHBOARD].includes(pathname);
}

export default function BrandProvider({ children }: any) {
  const { userDetails } = useContext(UserContext);

  const { primary_brand_id: brandId } = userDetails || {};

  const { pathname } = useLocation();

  const [isBrandSidbarVisible, setIsBrandSideBarVisible] = useState(shouldRenderBrandSideBar(pathname));

  const {
    refetch: revalidateBrandDetails,
    isError,
    isLoading,
    data: brandDetails,
    isFetching: isBrandDetailsBeingFetched,
  } = useQuery({
    queryKey: ["brand-with-id", brandId],
    queryFn: () =>
      brandId
        ? get(getBrandWithIdURL(brandId), {
            // eslint-disable-next-line no-console
            processError: () => console.error("Failed to fetch brand from BE"),
            // processError: () => toast.error("Failed to fetch brand from BE"),
          })
        : null,
    enabled: Boolean(brandId),
  });

  const loading = isLoading && isBrandDetailsBeingFetched;

  useEffect(() => {
    setIsBrandSideBarVisible(shouldRenderBrandSideBar(pathname));
  }, [pathname]);

  const getBrands = async (tagName?: string) => {
    if (tagName) {
      return get(getBrandWithTagURL(tagName));
    }
    return get(getAllBrandsURL());
  };

  return (
    <BrandContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isError,
        brandDetails: brandId ? brandDetails : undefined,
        brandId,
        loading,
        isBrandSidbarVisible,
        revalidateBrandDetails,
        getBrands,
      }}
    >
      <>
        {loading && <Loading />}
        {children}
      </>
    </BrandContext.Provider>
  );
}

export const useBrandContext = () => {
  const context = useContext(BrandContext);
  return context;
};
