import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { redirectToRouteWithRedirect } from "utils/HelperFunctions";
import { ROUTES } from "../../constants";
import Loading from "../atoms/Loading";
import { UserContext } from "../context/UserContext";

function AuthenticatedRoute() {
  const { isUserLoggedIn, loading } = useContext(UserContext);

  const navLink = redirectToRouteWithRedirect(ROUTES.BRANDS_DISCOVER, true);

  if (loading) return <Loading />;

  return isUserLoggedIn ? (
    <Outlet />
  ) : (
    // This will take the current route and redirect, which user is trying to access, but blocked due to not being logged in
    // This will set the current route along with search params and set it as the redirect route, after login.
    <Navigate to={navLink} />
  );
}
export default AuthenticatedRoute;
