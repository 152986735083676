import clsx from "clsx";
import { RefreshIcon } from "../Icon";

function RefreshButton({
  handleOnClick,
  isDisabled,
  isLoading,
}: {
  handleOnClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}) {
  return (
    <button type="button" onClick={handleOnClick} disabled={isDisabled} className="disabled:opacity-50">
      <RefreshIcon className={clsx("h-4 w-3 md:h-6 md:w-5", isLoading && " animate-spin")} />
    </button>
  );
}
export default RefreshButton;
