const loadImage = async (src: string) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (...args) => reject(args);
    img.src = src;
  });

const getImageData = (image: any) => {
  const canvas = document.createElement("canvas");
  canvas.width = image.width;
  canvas.height = image.height;
  const context = canvas.getContext("2d");
  if (!context) {
    return;
  }
  context.drawImage(image, 0, 0);
  return context.getImageData(0, 0, image.width, image.height);
};

export const getImageDataForEncoding = async (imageUrl: string) => {
  const imageData = await getImageDataForBlurHash(imageUrl);
  if (!imageData) throw new Error("Failed to get Image data");
  return [imageData.data, imageData.width, imageData.height, 6, 6];
};

export const getImageDataForBlurHash = async (imageUrl: string) => {
  const image = await loadImage(imageUrl);
  const imageData = getImageData(image);
  if (!imageData) {
    throw new Error("No image data found...");
  }

  return imageData;
};

export const generateVideoThumbnail = async (videoElement: HTMLVideoElement): Promise<File | null> => {
  const canvas = document.createElement("canvas");

  // @ts-ignore
  const video: HTMLVideoElement = videoElement;
  // Dynamically set the canvas size to match the video size
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  if (!video) {
    throw new Error("No video element found");
  }
  if (!canvas) {
    throw new Error("No video element found");
  }
  canvas.getContext("2d")?.drawImage(video, 0, 0);

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          return reject();
        }
        const file = new File([blob], "memento_preview.png");
        resolve(file);
      },
      ".png",
      1,
    );
  });
};
const getBlurHashForImage = async (imageUrl: string) => {
  try {
    console.log("IN", imageUrl);
    const imageData = await getImageDataForEncoding(imageUrl);

    let worker: Worker;

    console.log("INIT WORKER");

    console.log(process.env.PUBLIC_URL);

    // const EncodeWorker = require("../encode.worker.js");

    // console.log(EncodeWorker);
    // @ts-ignore
    // worker = new Worker(require(process.env.PUBLIC_URL + "/encode.worker.js"));

    return await new Promise((res, rej) => {
      const [data, width, height, componentX, componentY] = imageData;
      console.log("Posting Message to Worker.", imageData);
      worker.postMessage({
        payload: { data, width, height, componentX, componentY },
      });

      worker?.addEventListener("message", (e: MessageEvent<{ hash: string }>) => {
        console.log("Received message from worker", e);
        const { hash } = e.data;
        worker.terminate();
        res(hash);
      });
      worker?.addEventListener("error", (e) => {
        console.log("Encountered Error for worker associated with", imageUrl, "error:", e);
        console.log("Terminating Worker....");
        worker.terminate();
        rej();
      });
    });
  } catch (err) {
    console.log("Failed to encode image :", err);
  }
};

export default getBlurHashForImage;
