import { ReactElement } from "react";

type Props = {
  heading: number | string | ReactElement;
  sideLine: string;
  subHeading: string;
  headingSize?: string;
  subHeadingSize?: string;
  headingColor?: string;
  time?: boolean;
};

const HeadingClassLarge = "text-2xl lg:text-4xl 2xl:text-5xl font-bold";
const HeadingClassMedium = "text-[10px] sm:text-sm md:text-xl 2xl:text-3xl font-bold";
const SubHeadingClassLarge = "text-base sm:text-base md:text-lg 2xl:text-xl text-white font-semibold ";
const SubHeadingClassMedium = "opacity-60  md:opacity-100 text-[10px] 2xl:text-xs md:text-white font-semibold";

function DetailHeading({ heading, sideLine, subHeading, headingSize, subHeadingSize, headingColor, time }: Props) {
  return (
    <div className={`flex flex-col ${time ? "items-center" : ""} md:text-white`}>
      <div className={`${headingSize === "xl" ? HeadingClassLarge : HeadingClassMedium} ${headingColor}`}>
        {heading}
        <span>{sideLine}</span>
      </div>
      <div className={subHeadingSize === "xl" ? SubHeadingClassLarge : SubHeadingClassMedium}>{subHeading}</div>
    </div>
  );
}
export function DetailHeadingTemplate({ heading, subHeading }: Pick<Props, "heading" | "subHeading">) {
  return (
    <div className="flex flex-col text-center text-white">
      <div className="text-xxs">{heading}</div>
      <div className="text-base opacity-60">{subHeading}</div>
    </div>
  );
}
export default DetailHeading;
