type Props = {
  id: string;
  options: string[] | { display: string; value: string }[];
  optionsLoading?: boolean;
  placeHolder?: string;
  selectedValue?: string;
  additionalClasses?: string;
  register: any;
  isCountrySelector?: boolean;
};

function SelectField({
  id,
  selectedValue,
  options,
  placeHolder,
  additionalClasses,
  register,
  optionsLoading,
  isCountrySelector,
}: Props) {
  return (
    <div className="relative">
      <select
        value={isCountrySelector ? selectedValue?.toLowerCase() : selectedValue}
        id={id}
        name={id}
        disabled={optionsLoading}
        className={` block h-14 w-full rounded-md border border-white bg-primary
       p-4  py-2 font-satoshi font-450
       text-white placeholder:text-white placeholder:opacity-70 focus:border-white
       focus:outline-none focus:ring-white ${additionalClasses}`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register(id)}
      >
        <option className="p-2" hidden disabled value="">
          {placeHolder}
        </option>
        {(options || []).map((option: any, index: number) => (
          <option
            className="bg-primary"
            // eslint-disable-next-line react/no-array-index-key
            key={index + id}
            value={isCountrySelector ? option.toLowerCase() : option.value || option}
          >
            {option.display || option}
          </option>
        ))}
      </select>

      {/* Loader */}
      {optionsLoading && (
        <svg
          className="text-whit absolute right-0 top-5 -ml-1 mr-3 h-5 w-5 animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
          <path
            className="opacity-75"
            fill="white"
            // eslint-disable-next-line max-len
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      )}
    </div>
  );
}
export default SelectField;
