import { XMarkIcon } from "@heroicons/react/24/solid";
import Modal from "components/atoms/modal";
import { useState } from "react";
import SignInForm from "./SignInForm";

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

function SignInModal({ isOpen, onClose }: Props) {
  const [formType, setFormType] = useState<0 | 1 | 2 | 3>(1); // 0- signin, 1- signup, 2- reset password, 3- Otp Sent

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="w-full rounded-2xl bg-primary p-7 shadow-modal md:w-[450px] ">
        <div className="flex  items-end justify-end">
          <button type="button" onClick={onClose} className="w-fit">
            <XMarkIcon className="h-7 w-7 text-white" />
          </button>
        </div>
        <SignInForm formType={formType} setFormType={setFormType} />
      </div>
    </Modal>
  );
}
export default SignInModal;
