import { clsx } from "clsx";
import DefaultImage from "../../../assets/Images/BrandImage1.png";

export default function DesktopSidebarImageCard({
  isSidebarCustomized = false,
  isInteractionPending,
  name,
  image_url,
  handleCardClick,
  isDisabled,
  isActive,
  imageAdditionalClasses,
  collapsed = false,
}: {
  isSidebarCustomized?: boolean;
  image_url: string;
  name: string;
  handleCardClick: () => void;
  isDisabled?: boolean;
  isActive?: boolean;
  imageAdditionalClasses?: string;
  collapsed?: boolean;
  isInteractionPending?: boolean;
}) {
  return (
    <button
      type="button"
      key={name}
      disabled={isDisabled}
      onClick={handleCardClick}
      className={clsx(
        "group relative flex w-full items-center rounded-sm py-2 font-satoshi font-medium transition-colors disabled:cursor-not-allowed disabled:hover:bg-[#11202d] 2xl:rounded-md",
        // eslint-disable-next-line no-nested-ternary
        isActive ? (isSidebarCustomized ? "bg-[#22405a]" : "bg-[#1c1c1c]") : "",
        collapsed ? "justify-center" : "pl-2",
        isSidebarCustomized ? "hover:bg-[#22405a]" : "hover:bg-primary",
      )}
    >
      <div className="flex items-center">
        <div
          className={`${
            isActive && !collapsed ? " bg-secondary" : "bg-transparent"
          } mr-1.5 h-7 w-0.5 rounded-xl  2xl:w-1`}
        />

        <img
          src={image_url || DefaultImage}
          className={clsx(
            "aspect-1 h-[26px] w-[26px] flex-shrink-0 rounded-md object-cover object-center 2xl:h-7 2xl:w-7 2xl:rounded-lg",
            imageAdditionalClasses,
          )}
          alt={name}
          aria-hidden="true"
        />

        {/* No need to render text when item is collapsed */}
        {!collapsed && (
          <p
            title={name}
            className="ml-1 inline-block h-full w-52 truncate bg-gradient-to-r from-white  via-white to-black  bg-clip-text  text-left text-xs text-transparent  first-letter:uppercase  2xl:ml-2"
          >
            {name}
          </p>
        )}
        {isInteractionPending && <div className="ml-16 h-2 w-2 rounded-full bg-accent md:ml-3 " />}
      </div>
    </button>
  );
}
