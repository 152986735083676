import { createTheme, alpha } from "@mui/material/styles";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs2: 200,
      xs: 300,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      // need to update colors
      main: "#102D4E",
      text: "#fff",
      hover: "#DB4F1B",
      danger: "#FF0000",
      light: "#EFAB90",
    },

    secondary: {
      main: "#222222",
    },
    action: {
      main: "#979797",
    },

    mode: "dark",

    orange: {
      main: "#F05A22",
      dark: "#DB4F1B",
    },
    navyBlue: "#2C3E61",
    blueGray: "#577591",
    darkGray: "#979797",
    danger: "#FF0000",
  },
  forms: {
    default: "#FFFFFF",
    error: alpha("#F24E1E", 0.1),
    focus: "#FFFFFF",
  },
  buttons: {
    primary: {
      main: "#F05A22",
      hover: "#DB4F1B",
      disabled: "#C4C4C4",
    },
    secondary: {
      main: "#FFFFFF",
      hover: alpha("#F05A22", 0.2),
    },
  },
  icons: {
    primary: "#F05A22",
    secondary: "#979797",
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  },

  overrides: {
    MuiRadio: {
      root: {
        color: "#F05A22",
      },
      colorSecondary: {
        "&$checked": {
          color: "#ffffff",
        },
      },
    },
  },
} as any);
