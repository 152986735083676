import { useEffect, useState } from "react";
import { getCountryList } from "utils/HelperFunctions";
import UserMerchViewModal from "./UserMerchViewModal";
import MerchDropUserResponseModalContainer from "./MerchDropUserResponseModalContainer";

function MerchDropPublicViewModalContainer({
  mementoId,
  brandId,
  onClose,
  isMementoOwnedByUser,
  merchDropData,
  refetch,
}: {
  brandId: string;
  mementoId: string;
  onClose: () => void;
  isMementoOwnedByUser?: boolean;
  merchDropData: MerchDropUserData;
  refetch: () => void;
}) {
  const [isMerchDropClaimModalOpen, setIsMerchDropClaimModalOpen] = useState(false);

  const handleMerchDropClaimModalClose = () => {
    setIsMerchDropClaimModalOpen(false);
  };

  const handleClaimButtonClick = () => {
    setIsMerchDropClaimModalOpen(true);
  };

  const [hideAddress, setHideAddress] = useState(false);

  useEffect(() => {
    async function getcountries() {
      const country = await getCountryList(brandId);
      setHideAddress(country.hide_address);
    }
    getcountries();
  }, []);

  return (
    <>
      {isMerchDropClaimModalOpen && (
        <MerchDropUserResponseModalContainer
          merchDropDetails={merchDropData}
          onClose={handleMerchDropClaimModalClose}
          merchDropId={mementoId}
          refetchMerchDropData={refetch}
          hideAddress={hideAddress}
        />
      )}
      <UserMerchViewModal
        handleClaimButtonClick={handleClaimButtonClick}
        merchDropDetails={merchDropData}
        onClose={onClose}
        disableClaimButtonClick={!isMementoOwnedByUser}
      />
    </>
  );
}

export default MerchDropPublicViewModalContainer;
