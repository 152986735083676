import { useAutoAnimate } from "@formkit/auto-animate/react";

type Props = {
  children: React.ReactNode;
};

export default function ListAnimator({ children }: Props) {
  const [parent] = useAutoAnimate({ duration: 250, disrespectUserMotionPreference: true });

  return (
    // @ts-ignore
    <div className="contents" ref={parent}>
      {children}
    </div>
  );
}
