import { useState } from "react";
import { post } from "utils/request";
import toast from "react-hot-toast";
import { postPollResponse } from "apis/poll";
import { useRouter } from "components/hooks";
import PollModal from "./PollModal";

type Props = {
  isModalOpen: boolean;
  onModalClose: () => void;
  pollId: string;
  pollData: any;
  refetch: () => void;
  isLoading: boolean;
};

function UserPollModalContainer({ isModalOpen, onModalClose, pollId, pollData, refetch, isLoading }: Props) {
  const [isPollLoading, setIsPollLoading] = useState(false);

  const router = useRouter();
  const { magnetId } = router.query;

  const handlePollResponseSuccess = () => {
    refetch();
    setIsPollLoading(false);
  };

  const handlePollResponseError = (errorMessage: string) => {
    setIsPollLoading(false);
    toast.error(errorMessage);
    throw new Error(errorMessage);
  };

  const handlePollResponse = (payload: { response: number[] }) => {
    post(postPollResponse(magnetId, pollId), payload, {
      processData: handlePollResponseSuccess,
      processError: handlePollResponseError,
    });
  };

  const handlePollSubmit = (pollOptionIndex: number[]) => {
    setIsPollLoading(true);
    const payload = { response: pollOptionIndex.map((response) => response + 1) };
    handlePollResponse(payload);
  };

  const maxVotePercentages = pollData.choices.map((choices: any) =>
    Math.max(...choices.map((choice: any) => choice.percentage || -1)),
  );

  const handleRefreshButton = () => {
    refetch();
  };

  return (
    <PollModal
      maxVotePercentages={maxVotePercentages}
      pollData={pollData}
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      handlePollSubmit={handlePollSubmit}
      isPollLoading={isPollLoading}
      handleRefreshButton={handleRefreshButton}
      isLoading={isLoading}
    />
  );
}
export default UserPollModalContainer;
