import clsx from "clsx";
import NotificationDropDownSection from "components/pageComponents/Navbar/notification";
import useSideBarStore from "components/store/sidebarStore";
import SignInModal from "pages/signin/signin-modal";
import { useContext, useMemo, useState } from "react";
import { ROUTES } from "../../../constants";
import Button from "../../atoms/buttons/Button";
import { MagnetiqLogo, OnboardingBackIcon } from "../../atoms/Icon";
import { BrandContext } from "../../context/BrandContext";
import { UserContext } from "../../context/UserContext";
import { useRouter } from "../../hooks";
import UserProfileDropdown from "./UserProfileDropdown";

function Navbar({ isSidebarCustomized = false }: { isSidebarCustomized?: boolean }) {
  const { brandDetails, loading: brandLoading } = useContext(BrandContext);
  const { isUserLoggedIn, userDetails, loading: userLoading } = useContext(UserContext);
  const { id: brandId } = brandDetails || { id: undefined };

  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);

  const router = useRouter();

  const { m: magnetIdInUrl } = router.query;

  const isUserOnboard = isUserLoggedIn && userDetails?.magnetiq_program_status;

  const { pathname } = router.location;

  const isNavbarHidden = pathname.includes(ROUTES.SIGNIN);

  const { updateSideBarVisibility, sidebarExpanded: isOpen } = useSideBarStore();

  // eslint-disable-next-line operator-linebreak

  const renderMagnetiqLogo = ![
    ROUTES.BRAND_SETTINGS_ROUTE,
    ROUTES.CREATE_PUBLIC_MAGNET_ROUTE,
    ROUTES.CREATE_CONTROLLED_MAGNET_ROUTE,
    ROUTES.CREATE_SUBSCRIPTION_MAGNET_ROUTE,
    ROUTES.BRAND_DASHBOARD,
  ].includes(pathname);

  const isRouteBrandOnboarding = pathname === ROUTES.BRAND_ONBOARDING;

  const isBrandRoute =
    brandId &&
    (pathname === ROUTES.BRAND_DASHBOARD ||
      pathname.includes("airdrop-momento") ||
      pathname.includes("airdrop-memento") ||
      pathname.includes("poll") ||
      pathname.includes("claim-code") ||
      pathname.includes("merch-drop") ||
      pathname.includes(ROUTES.BRAND_SETTINGS_ROUTE) ||
      pathname.includes(ROUTES.EDIT_MAGNET) ||
      pathname.includes(ROUTES.CREATE_PUBLIC_MAGNET_ROUTE) ||
      pathname.includes(ROUTES.CREATE_CONTROLLED_MAGNET_ROUTE));

  const renderBackButton =
    (isBrandRoute && pathname !== ROUTES.BRAND_DASHBOARD) || (pathname === ROUTES.BRAND_DASHBOARD && magnetIdInUrl);

  const renderBrandCTAs = useMemo(() => {
    if (isUserOnboard) {
      if (isBrandRoute)
        return (
          <>
            <Button
              additionalClasses="px-0 text-xs xl:text-sm 2xl:text-lg my-fridge-button z-40 text-white font-satoshi"
              variant="primary"
              text="MY FRIDGE"
              onClick={() => {
                router.go(ROUTES.USER_PROFILE_PAGE);
              }}
            />

            <Button
              additionalClasses="px-0 text-xs xl:text-sm z-40 2xl:text-lg"
              variant="primary"
              text="PUBLIC PROFILE"
              onClick={() => {
                router.go(`${ROUTES.BRAND_PUBLIC_PROFILE}/${brandId}`);
              }}
            />
          </>
        );
    }
    return null;
  }, [brandId, pathname, isUserOnboard]);

  const renderMobileNav = useMemo(
    () => (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <></>
      // <Transition
      //   show={isOpen}
      //   enter="transition ease-out duration-100 transform"
      //   enterFrom="opacity-0 scale-95"
      //   enterTo="opacity-100 scale-100"
      //   leave="transition ease-in duration-75 transform"
      //   leaveFrom="opacity-100 scale-100"
      //   leaveTo="opacity-0 scale-95"
      // >
      //   {(ref) => (
      //     <div className="md:hidden" id="mobile-menu">
      //       <div ref={ref} className="p-2">
      //         <div className="flex flex-col space-y-2">
      //           <div className="flex justify-end">{renderBrandCTAs}</div>

      //           {isUserLoggedIn && (
      //             <div
      //               className=" flex flex-col items-end space-y-2 font-sora text-base font-semibold
      //                  text-white md:text-xs xl:text-lg"
      //             >
      //               {/* Notifications */}
      //               <Button
      //                 additionalClasses="px-0 text-xs xl:text-sm 2xl:text-lg text-end"
      //                 variant="primary"
      //                 text="NOTIFICATIONS"
      //                 onClick={() => {
      //                   router.go(ROUTES.COMING_SOON);
      //                 }}
      //               />
      //               {/* Logout */}
      //               <Button
      //                 additionalClasses="px-0 text-xs xl:text-sm 2xl:text-lg text-end"
      //                 variant="primary"
      //                 text="LOGOUT"
      //                 onClick={handleUserLogout}
      //               />
      //             </div>
      //           )}
      //         </div>
      //       </div>
      //     </div>
      //   )}
      // </Transition>
    ),
    [isUserLoggedIn, isOpen],
  );

  const MagnetiqLogoWithOnClick = useMemo(
    () => (
      <button
        type="button"
        onClick={() => router.go(ROUTES.BRANDS_DISCOVER)}
        className="relative z-40 cursor-pointer pt-1"
      >
        <MagnetiqLogo />
      </button>
    ),
    [],
  );

  if (isNavbarHidden || brandLoading || userLoading) return null;

  return (
    <>
      <SignInModal onClose={() => setIsSignInModalOpen(false)} isOpen={isSignInModalOpen} />
      <div className={clsx(isBrandRoute && "ml-64 2xl:ml-80")}>
        <div
          className={clsx(
            "h-[4.2rem] w-full transition-colors",
            // eslint-disable-next-line no-nested-ternary
            isRouteBrandOnboarding
              ? "bg-primary"
              : isSidebarCustomized
              ? "bg-[#2a5070] shadow-[20px_2px_4px_rgba(89,89,89,0.5)]"
              : "bg-black shadow-[0px_2px_4px_rgba(89,89,89,0.5)]",
          )}
        >
          <nav className="py-3">
            <div className="mx-5 flex flex-row items-center justify-between">
              {isSidebarCustomized ? (
                <div className="flex items-center gap-2 whitespace-nowrap font-satoshi text-xxs font-450 text-slate-200">
                  Powered by{MagnetiqLogoWithOnClick}
                </div>
              ) : null}
              <div className="hidden justify-start gap-x-5 md:flex">
                {renderMagnetiqLogo && isRouteBrandOnboarding ? MagnetiqLogoWithOnClick : null}
              </div>

              <div className={`flex justify-between ${renderBackButton && "flex-1"}`}>
                {/* BACK BUTTON */}
                {renderBackButton && (
                  <button
                    type="button"
                    className=" z-40 flex w-7 cursor-pointer items-center space-x-2 font-sora font-semibold  text-white "
                    onClick={() => router.go(-1)}
                  >
                    <span className="-mt-0">
                      <OnboardingBackIcon />
                    </span>
                    <p>BACK</p>
                  </button>
                )}

                {/* Right Side CTAs */}
                <div className="hidden md:block">
                  <div className="flex items-center justify-center gap-x-4">
                    {/* Back to User Profile */}

                    {/* Render Brand Pubic Profile/ Dashboard or Brand Signup */}
                    {renderBrandCTAs}

                    {renderMagnetiqLogo && !isRouteBrandOnboarding && !isSidebarCustomized
                      ? MagnetiqLogoWithOnClick
                      : null}

                    {/* <NotificationIcon /> */}

                    {isUserLoggedIn ? (
                      <>
                        <NotificationDropDownSection />
                        <UserProfileDropdown />
                      </>
                    ) : (
                      <Button
                        variant="magnetiqSolid"
                        additionalClasses={clsx(
                          "px-12 py-2 z-30",
                          isSidebarCustomized ? "!bg-[#842572] !text-white" : "",
                        )}
                        text="LOGIN"
                        onClick={() => {
                          // router.go(getLoginPageURLWithRedirect());
                          setIsSignInModalOpen(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`flex w-full text-white md:hidden ${
                  isSidebarCustomized ? "justify-end" : "justify-between md:justify-end"
                }`}
              >
                {!isSidebarCustomized && MagnetiqLogoWithOnClick}
                {isUserLoggedIn && (
                  <div className="flex w-max items-center space-x-2">
                    <NotificationDropDownSection />
                    <UserProfileDropdown />
                  </div>
                )}
              </div>
              {!isSidebarCustomized && (
                <div className="-mr-2 flex md:hidden">
                  {isUserLoggedIn ? (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        updateSideBarVisibility(!isOpen);
                      }}
                      type="button"
                      className="inline-flex items-center justify-center rounded-md p-2 text-white focus:outline-none"
                      aria-controls="mobile-menu"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Open main menu</span>
                      {!isOpen ? (
                        <svg
                          className="block h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="#fff"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="block h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      )}
                    </button>
                  ) : (
                    <Button
                      variant="magnetiqSolid"
                      additionalClasses={clsx(
                        "px-12 !py-2 z-30",
                        isSidebarCustomized ? "!bg-[#842572] !text-white" : "",
                      )}
                      text="LOGIN"
                      onClick={() => {
                        // router.go(getLoginPageURLWithRedirect());
                        setIsSignInModalOpen(true);
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            {renderMobileNav}
          </nav>
        </div>
      </div>
    </>
  );
}
export default Navbar;
