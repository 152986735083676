// import { LockClosedIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import Button from "components/atoms/buttons/Button";
import { CloseIcon } from "components/atoms/Icon";
import Modal from "components/atoms/modal";
import { ReactNode, useState } from "react";
import { ReactComponent as ControlledMagnetProgramIcon } from "assets/Icons/BrandControlMagnet.svg";
import { ReactComponent as PublicMagnetProgramIcon } from "assets/Icons/SimpleMagnet.svg";
import { CheckIcon } from "@heroicons/react/24/solid";
import { useRouter } from "components/hooks";
import { NavigateFunction } from "react-router-dom";
// import RadioButtonWithLabel from "components/molecules/forms/RadioButtonWithLablel";

type Props = {
  onClose?: any;
  open: boolean;
  handleCreateMagnet: (magnetType: MagnetProgramTypes, go: NavigateFunction) => void;
};

function MagnetCard({
  name,
  description,
  icon,
  selected,
  disabled = false,
  onClick,
}: {
  name: string;
  description: string | null;
  icon: ReactNode;
  selected: boolean;
  disabled?: boolean;
  onClick: () => void;
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type="button"
      className={clsx(
        "flex max-w-[400px] flex-col gap-1 overflow-hidden rounded-xl border-[3px] transition",
        selected ? "border-secondary" : "border-white border-opacity-10 hover:border-opacity-25",
      )}
    >
      <div
        className={clsx(
          "absolute m-3 flex h-4 w-4 shrink items-center justify-center rounded-[50%] border-2 border-secondary",
          selected ? "bg-secondary" : "bg-transparent",
        )}
      >
        <CheckIcon className={clsx(selected ? "text-primary" : "opacity-5")} />
      </div>
      <div
        className={clsx(
          "w-full grow from-slate-700 px-28 pb-6 pt-8 transition hover:opacity-100",
          selected ? "bg-gradient-to-tl" : "bg-gradient-to-t opacity-80",
        )}
      >
        {icon}
      </div>
      <h3 className="w-full font-satoshi text-lg font-bold leading-tight">{name}</h3>
      <p className="w-full px-1 pb-2 text-xxs text-gray-400">{description}</p>
    </button>
  );
}

export default function CreateMagnetModal({ handleCreateMagnet, onClose, open }: Props) {
  const { go } = useRouter();
  const [selectedType, setSelectedType] = useState<MagnetProgramTypes>(0);
  return (
    <Modal open={open} onClose={onClose}>
      <div className="relative space-y-6 rounded-2xl bg-primary text-white shadow-modal sm:w-full sm:p-2 xl:p-4">
        <div>
          <div className="flex justify-between text-white">
            <section className=" w-[95%] font-sora text-xl font-semibold xl:text-2xl">
              Select Magnet Program Type
            </section>
            <button type="button" onClick={onClose} className="my-auto cursor-pointer">
              <CloseIcon className="h-5 w-5 text-white" />
            </button>
          </div>
          <p className="font-satoshi text-lg font-medium opacity-50">
            What type of Magnet program would you like to launch?
          </p>
        </div>

        {/* Radio buttons */}
        <div className="flex items-stretch gap-2">
          <div className="relative">
            <MagnetCard
              selected={selectedType === 0}
              onClick={() => setSelectedType(0)}
              name="Public Magnet Program"
              description="Anyone can acquire the Magnet to become a part of the program and avail associated benefits"
              icon={<PublicMagnetProgramIcon fill="#b58d8c" />}
            />
          </div>
          <div className="relative">
            <MagnetCard
              selected={selectedType === 1}
              onClick={() => setSelectedType(1)}
              name="Permissioned Magnet Program"
              description="Only users listed and activated by the brand can acquire the Magnet and avail associated benefits."
              icon={<ControlledMagnetProgramIcon fill="#b58d8c" />}
            />
          </div>
          {/* <div className="relative">
            <MagnetCard
              selected={selectedType === 2}
              onClick={() => setSelectedType(2)}
              name="Subscription Magnet Program"
              description="Only users listed and activated by the brand can acquire the Magnet and avail associated benefits."
              icon={<ControlledMagnetProgramIcon fill="#b58d8c" />}
            />
          </div> */}
        </div>

        <Button
          additionalClasses="ml-auto !py-2 !px-3"
          text="CREATE"
          variant="magnetiqSolid"
          onClick={() => {
            handleCreateMagnet(selectedType, go);
            onClose();
          }}
        />
      </div>
    </Modal>
  );
}
