/* eslint-disable no-nested-ternary */
import clsx from "clsx";
import { RoundCheckIcon } from "../../atoms/Icon";

type Props = {
  isDisabled: boolean;
  label: string;
  handleOnClick?: () => void;
  isBrandPollButton?: boolean;
  isSelectedOption?: boolean;
  votePercentage?: number;
};

function PollAnswerButton({
  label,
  votePercentage,
  isSelectedOption,
  handleOnClick,
  isBrandPollButton,
  isDisabled,
}: Props) {
  const { backgroundColor } = (() => {
    if (isBrandPollButton)
      return isSelectedOption ? { backgroundColor: "bg-[#6DBE45]" } : { backgroundColor: " bg-accent" };

    if (isSelectedOption) {
      return {
        backgroundColor: "bg-secondary",
      };
    }
    return {
      backgroundColor: "bg-[#DBDBDB]",
    };
  })();

  return (
    <button
      type="button"
      className={clsx(
        "relative h-14 w-full rounded-ll border border-white text-start focus:outline-none focus:ring-0",
        isSelectedOption
          ? votePercentage === undefined
            ? "bg-slate-200 text-primary disabled:bg-slate-200"
            : "bg-transparent"
          : "text-white hover:bg-white hover:bg-opacity-10 disabled:bg-transparent",
      )}
      onClick={handleOnClick}
      disabled={isDisabled}
    >
      <div
        className={clsx(
          "absolute -left-[1px] -top-[1px] h-[calc(100%+2px)] origin-center rounded-ll duration-1000",
          backgroundColor,
        )}
        style={{ width: votePercentage ? `calc(${votePercentage}% + 2px)` : 0 }}
      />
      <div className="absolute left-0 top-0 flex h-full w-full justify-between p-4">
        <div className="flex items-center space-x-1">
          <p
            className={clsx(
              "line-clamp-1 break-all font-satoshi text-xs font-semibold leading-5  md:text-sm ",
              backgroundColor === "bg-[#DBDBDB]" && "mix-blend-difference",
            )}
          >
            {label}
          </p>
          {!isBrandPollButton && isSelectedOption && (
            <div className="flex-none">
              <RoundCheckIcon color="text-primary" />
            </div>
          )}
        </div>
        {typeof votePercentage === "number" && (
          <p className="font-satoshi text-xxs font-semibold text-white md:text-xs">{`${votePercentage}%`}</p>
        )}
      </div>
    </button>
  );
}
export default PollAnswerButton;
