import { LoadingIcon } from "./Icon";

function Loading({ subtext }: { subtext?: string }) {
  return (
    <div
      className={`fixed left-0 top-0 z-[100] flex h-full w-full flex-col items-center justify-center bg-black ${
        subtext ? "opacity-80" : "opacity-40"
      }`}
    >
      <div className=" h-1/12 w-1/12">
        <LoadingIcon />
      </div>
      {subtext && <p className="h-5/12 w-5/12 text-center text-base font-semibold text-white md:text-sm">{subtext}</p>}
    </div>
  );
}
export default Loading;
