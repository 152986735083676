import { ACTIVATIONS_ROUTE, ALL_MAGNET_NAME, MAGNET } from "../constants";

const API_ROUTES = {
  BRAND_ROUTE: "/api/v1/brand/",
  BRAND_ANALYTICS_ROUTE: "/api/v1/analytics/brand/",
};

export function getBrandWithIdURL(id: string) {
  return `${API_ROUTES.BRAND_ROUTE}${id}/`;
}

export function getBrandPublicProfile(id: string) {
  return `${API_ROUTES.BRAND_ROUTE}${id}/?type=public`;
}

export const getBrandUpdateURL = (brandId: string) => `${API_ROUTES.BRAND_ROUTE + brandId}/`;

export const getBrandWithTagURL = (tagName: string) => `${API_ROUTES.BRAND_ROUTE}?tags=${tagName}`;

export const getAllBrandsURL = () => API_ROUTES.BRAND_ROUTE;

export const getTagsForBrands = () => `${API_ROUTES.BRAND_ROUTE}tags/`;

export const getMagnetNamesForBrand = (brandId: string) => `${API_ROUTES.BRAND_ROUTE}${brandId}${ALL_MAGNET_NAME}`;

export const getBrandMagnet = (brandId: string, magnetId: string) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  `${API_ROUTES.BRAND_ROUTE}${brandId}${MAGNET}${magnetId}`;

export const getAudienceAnalyticsURL = (brandId: string) =>
  `${API_ROUTES.BRAND_ANALYTICS_ROUTE}${brandId}/get-audience`;

export const getBrandAnalyticsURL = (brandId: string) => `${API_ROUTES.BRAND_ANALYTICS_ROUTE}${brandId}`;

export const getMagnetAnalyticsURL = (brandId: string, magnetId: string) =>
  `${API_ROUTES.BRAND_ANALYTICS_ROUTE}${brandId}?magnet_id=${magnetId}`;

export const getActivationsForBrand = (brandId: string) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  `${ACTIVATIONS_ROUTE}brand/${brandId}/`;
