/* eslint-disable react/require-default-props */
import clsx from "clsx";
import { ReactElement } from "react";
import { LoadingIcon } from "../Icon";

type Props = {
  text: string;
  onClick: any;
  disabled?: boolean;
  additionalClasses?: string;
  variant?:
    | "primarySolid"
    | "secondarySolid"
    | "magnetiqSolid"
    | "primaryOutline"
    | "primary"
    | "tertiary"
    | "rustPinkSolid"
    | "whiteSolid"
    | "linkUnderline"
    | "whiteOutline";
  icon?: ReactElement;
  iconLeft?: boolean;
  type?: "submit" | "button";
  isLoading?: boolean;
};

function Button({ text, onClick, disabled, additionalClasses, variant, icon, iconLeft, type, isLoading }: Props) {
  return (
    <button
      disabled={disabled}
      className={clsx(
        "flex items-center justify-center gap-1.5 rounded-full  px-5",
        "py-3 font-sora text-xxs font-semibold",
        iconLeft === true ? "flex-row-reverse" : "",
        variant === "primarySolid" && "bg-primary-btn text-white",
        variant === "primary" && "px-0 py-0 text-white",
        variant === "primaryOutline" &&
          "border-primary-btn border-1.5 text-primary hover:bg-primary hover:bg-opacity-5",
        variant === "whiteOutline" && "border-1.5 border-white text-white hover:bg-white hover:bg-opacity-5",
        variant === "secondarySolid" && "bg-secondary-btn text-primary",
        variant === "magnetiqSolid" && "bg-accent text-primary",
        variant === "rustPinkSolid" && "bg-secondary text-primary",
        variant === "whiteSolid" && "bg-white text-primary",
        variant === "whiteOutline" && "border border-white text-white",
        variant === "linkUnderline" && "border-none text-white underline",

        variant === "tertiary" && "text-tertiary-btn px-0 py-0",
        disabled ? "opacity-25" : " cursor-pointer hover:opacity-90",
        additionalClasses,
      )}
      onClick={onClick}
      // eslint-disable-next-line react/button-has-type
      type={type}
    >
      <div className={clsx("leading-5", variant === "primary" && "hover:underline")}>{text}</div>
      {/* TODO: Verify This classes */}
      {icon && <div className="-mt-0.5">{icon}</div>}
      {isLoading && (
        <div className="relative">
          <div className="absolute -right-7 -top-[14px] h-8 w-8">
            <LoadingIcon color="#fff" />
          </div>
        </div>
      )}
    </button>
  );
}
export default Button;

Button.defaultProps = {
  disabled: false,
  additionalClasses: "",
  variant: "primarySolid",
};
