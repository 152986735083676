import { PlusIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import ListAnimator from "components/atoms/ListAnimator";
import { MouseEventHandler, ReactNode } from "react";

function Table({ children }: { children: JSX.Element | JSX.Element[] }) {
  return <table className="w-full table-auto">{children}</table>;
}

Table.Row = function ({
  children,
  isHeaderRow,
  headerRowClasses,
}: {
  children: JSX.Element | JSX.Element[];
  isHeaderRow?: boolean;
  headerRowClasses?: string;
}) {
  return <tr className={clsx(!isHeaderRow ? "" : "table-row", headerRowClasses)}>{children}</tr>;
};

Table.HeaderCell = function ({
  label,
  align = "center",
  additionalClasses,
}: {
  label: any;
  align?: "left" | "right" | "center";
  additionalClasses?: string;
}) {
  return (
    <th align={align} className="bg-secondary px-2  pb-4 pt-3.5 first:rounded-tl-xl last:rounded-tr-xl">
      <span className={clsx("whitespace-nowrap font-satoshi text-sm font-semibold 2xl:text-lg", additionalClasses)}>
        {label}
      </span>
    </th>
  );
};

Table.Header = function ({ children }: { children: JSX.Element | JSX.Element[] }) {
  return <thead className="table-header-group">{children}</thead>;
};

Table.Description = function ({
  children,
  textAlign,
  colSpan,
}: {
  children: React.ReactNode;
  textAlign?: "start" | "end" | "center";
  colSpan?: number;
}) {
  return (
    <td colSpan={colSpan || 1} className={clsx("px-1.5 py-3 align-middle", `text-${textAlign || "center"}`)}>
      {children}
    </td>
  );
};
Table.Body = function ({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <tbody className={className || ""}>
      <ListAnimator>{children}</ListAnimator>
    </tbody>
  );
};

Table.Button = function ({ label, handleOnClick }: { label: string; handleOnClick: () => void }) {
  return (
    <button
      type="button"
      onClick={handleOnClick}
      className="w-36 max-w-full  cursor-pointer whitespace-nowrap rounded-full bg-secondary  py-2.5 font-sora 
    text-xxs  font-semibold text-primary hover:opacity-90 2xl:text-xs"
    >
      {label}
    </button>
  );
};

Table.EmptyState = function ({
  emptyStatefor,
  onClick,
  isDisabled,
  title,
  emptyContent,
}: {
  emptyStatefor: string;
  onClick: MouseEventHandler;
  isDisabled?: boolean;
  title?: string;
  emptyContent?: ReactNode;
}) {
  return (
    <Table.Body>
      <Table.Row>
        <Table.Description colSpan={15}>
          {emptyContent || (
            <div title={title}>
              <button
                disabled={isDisabled}
                type="button"
                onClick={onClick}
                className={clsx(
                  "ml-2 flex h-auto w-[99%] justify-center gap-3 rounded-lg border-[0.5px] border-dashed border-white py-6 font-satoshi text-sm font-medium text-white",
                  isDisabled ? "cursor-not-allowed border-opacity-25 opacity-25" : "cursor-pointer opacity-80",
                )}
              >
                <p>{`No ${emptyStatefor} created for this Magnet yet. Add a new ${emptyStatefor}.`}</p>
                <span className="rounded-lg border-1 border-dashed border-white">
                  <PlusIcon className="h-7" />
                </span>
              </button>
            </div>
          )}
        </Table.Description>
      </Table.Row>
    </Table.Body>
  );
};

export default Table;
