import clsx from "clsx";
import { ReactElement } from "react";

type Props = {
  label: string;
  isActive?: boolean;
  icon?: ReactElement;
  handleOnClick?: any;
  isRounded?: boolean;
  bottomBarClasses?: string;
};
function TabButton({ label, isActive, handleOnClick, icon, isRounded, bottomBarClasses }: Props) {
  return (
    <button
      onClick={handleOnClick}
      type="button"
      className={clsx("cursor-pointer text-white", isActive ? "" : "opacity-20")}
    >
      <div className="flex items-center space-x-2">
        {icon}
        <p>{label}</p>
      </div>
      <div
        className={clsx(
          "h-1  w-1/2",
          isRounded ? " bg-tertiary rounded-full" : "bg-secondary",
          !isActive && "invisible",
          bottomBarClasses,
        )}
      />
    </button>
  );
}
export default TabButton;
TabButton.defaultProps = {
  handleOnClick: () => {},
  isRounded: false,
};
