import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { get } from "utils/request";
import { endMagnetiqProgram } from "apis/user";
import { getMagnetiqMagnetDetailsEndpoint } from "../../../apis/walkthrough";

type WalkthroughProps = {
  run: boolean;
  tourActive: boolean;
  brandId: string | undefined;
  magnetId: string | undefined;
};

const WalkthroughInitialValues: WalkthroughProps = {
  run: false,
  tourActive: false,
  brandId: undefined,
  magnetId: undefined,
};

const notInitialised = () => {
  toast.error("Ctx not init");
};

type UserWalkthroughProps = {
  walkthroughData: WalkthroughProps;
  setWalkthroughData: Dispatch<SetStateAction<WalkthroughProps>> | (() => void);
  setCurrentWalkthroughStep: (step: number) => void | (() => void);
  stepFromUrl: null | string;
  getMagnetiqMagnetDetails: (() => Promise<any>) | (() => void);
  setWalkthroughRun: ((run: boolean) => void) | (() => void);
  // handleNavigateToMagnetiqMagnetPublicProfile: () => Promise<void>;
  // handleNavigateToMagnetiqBrandPublicProfile: () => Promise<void>;
  endMagnetiqProgram: (successHandler: any, errorHandler: any) => void;
  setTourToState: (state: boolean) => void;
};

const WalkthroughContext = createContext<UserWalkthroughProps>({
  walkthroughData: WalkthroughInitialValues,
  setWalkthroughData: notInitialised,
  setCurrentWalkthroughStep: notInitialised,
  stepFromUrl: null,
  getMagnetiqMagnetDetails: notInitialised,
  setWalkthroughRun: notInitialised,
  // handleNavigateToMagnetiqMagnetPublicProfile: () => new Promise(() => {}),
  // handleNavigateToMagnetiqBrandPublicProfile: () => new Promise(() => {}),
  endMagnetiqProgram: () => {},
  setTourToState: notInitialised,
});

export function WalkthroughProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [walkthroughData, setWalkthroughData] = useState(WalkthroughInitialValues);
  const [searchParams, setSearchParams] = useSearchParams();
  const stepFromUrl = searchParams.get("onboarding-step");

  const setCurrentWalkthroughStep = (step: number) => {
    searchParams.set("onboarding-step", step.toString());
    setSearchParams(searchParams);
  };

  const setWalkthroughRun = (run: boolean) => {
    setWalkthroughData((prevState) => ({
      ...prevState,
      run,
    }));
  };

  const setTourToState = (state: boolean) => setWalkthroughData((prevState) => ({ ...prevState, tourActive: state }));

  const handleGetMagnetiqMagnetDetailsSuccess = ({
    data,
  }: {
    data: {
      magnet_id: string;
      brand_id: string;
    };
  }) => {
    setWalkthroughData((walkthroughStateData) => ({
      ...walkthroughStateData,
      magnetId: data.magnet_id,
      brandId: data.brand_id,
    }));
    return { magnetId: data.magnet_id, brandId: data.brand_id };
  };

  const handleGetMagnetiqMagnetDetailsError = (errorMessage: string) => {
    toast.error(errorMessage);
  };
  const getMagnetiqMagnetDetails = async () => {
    if (walkthroughData.brandId && walkthroughData.magnetId)
      return { magnetId: walkthroughData.magnetId, brandId: walkthroughData.brandId };
    return get(getMagnetiqMagnetDetailsEndpoint, {
      processData: handleGetMagnetiqMagnetDetailsSuccess,
      processError: handleGetMagnetiqMagnetDetailsError,
    });
  };

  // const handleNavigateToMagnetiqMagnetPublicProfile = async () => {
  //   const magnetData = await getMagnetiqMagnetDetails();
  //   if (magnetData) router.go(`${getMagnetPageLink(magnetData.brandId, magnetData.magnetId)}?onboarding-step=7`);
  // };

  // const handleNavigateToMagnetiqBrandPublicProfile = async () => {
  //   const magnetData = await getMagnetiqMagnetDetails();
  //   if (magnetData) router.go(`${ROUTES.BRAND_PUBLIC_PROFILE}/${magnetData.brandId}?onboarding-step=6`);
  // };

  const value = useMemo(
    () => ({
      walkthroughData,
      setWalkthroughData,
      setCurrentWalkthroughStep,
      stepFromUrl,
      getMagnetiqMagnetDetails,
      setWalkthroughRun,
      // handleNavigateToMagnetiqMagnetPublicProfile,
      // handleNavigateToMagnetiqBrandPublicProfile,
      endMagnetiqProgram,
      setTourToState,
    }),
    [walkthroughData, setWalkthroughData, setCurrentWalkthroughStep, stepFromUrl, getMagnetiqMagnetDetails],
  );

  return <WalkthroughContext.Provider value={value}>{children}</WalkthroughContext.Provider>;
}

export function useWalkthroughContext(): {
  setWalkthroughData: React.Dispatch<React.SetStateAction<WalkthroughProps>>;
  walkthroughData: WalkthroughProps;
  setCurrentWalkthroughStep: (step: number) => void;
  stepFromUrl: string | null;
  getMagnetiqMagnetDetails: (() => Promise<any>) | (() => void);
  setWalkthroughRun: (run: boolean) => void;
  // handleNavigateToMagnetiqMagnetPublicProfile: () => Promise<void>;
  // handleNavigateToMagnetiqBrandPublicProfile: () => Promise<void>;
  endMagnetiqProgram: (successHandler: any, errorHandler: any) => void;
  setTourToState: (state: boolean) => void;
} {
  const context = useContext(WalkthroughContext);

  if (!context) {
    throw new Error("walkthrough context must be used within a walkthrough context provider");
  }

  return context;
}
