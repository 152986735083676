import { useFormContext } from "react-hook-form";
import ChoiceCheckboxInputElement from "./ChoiceCheckboxInputElement";
import { MerchDropUserResponse } from "./MerchDropUserResponseModalContainer";

type Props = {
  merchDropDescription: string;
  merchDropOptions: Omit<MerchDropOptions, "id">[];
  isUserViewModal?: boolean;
};

function MerchDropBasicInfoForm({ merchDropDescription, merchDropOptions, isUserViewModal }: Props) {
  const {
    // register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<MerchDropUserResponse>();

  return (
    <>
      <p className="text-justify font-satoshi text-xs font-450 leading-6 text-white text-opacity-70">
        {merchDropDescription}
      </p>
      <div className="lg:w-4/4 mt-4 w-full space-y-2 2xl:space-y-6">
        {merchDropOptions?.map((option, idx) => (
          <ChoiceCheckboxInputElement
            // eslint-disable-next-line react/no-array-index-key
            key={`option-${idx}-${option.name}`}
            isDisabled={!isUserViewModal}
            optionIdx={idx}
            id={`options_response.${idx}.choices_selected`}
            // register={register}
            onClick={(id: string) => setValue(`options_response.${idx}.choices_selected`, id)}
            options={option.choices}
            label={option.name}
            selected={watch(`options_response.${idx}.choices_selected`)}
            errorMessage={errors.options_response?.[idx]?.choices_selected?.message || errors.options_response?.message}
          />
        ))}
      </div>
    </>
  );
}
export default MerchDropBasicInfoForm;
