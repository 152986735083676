/* eslint-disable import/no-extraneous-dependencies */
import { ClipboardIcon, CloseIcon, FeaturedIcon } from "components/atoms/Icon";
import Modal from "react-modal";
import { copyToClipboard } from "utils/HelperFunctions";
import MagnetImageContainer from "components/atoms/images/MagnetImageContainer";
import { ReactElement } from "react";

interface Props {
  magnetImageUrl: string;
  brandName: string;
  magnetName: string;
  onClose: () => void;
  mementoImage: string;
  mementoName: string;
  mementoDescription: string;
  mementoInstructions: string;
  getActionButtons?: ReactElement;
  claimCode?: string;
  isUserPreviewModal?: boolean;
  disabled?: boolean;
}

function ClaimCodeWithInstructionsModal({
  magnetImageUrl,
  brandName,
  magnetName,
  onClose,
  mementoImage,
  mementoName,
  mementoDescription,
  mementoInstructions,
  getActionButtons,
  claimCode = "Coupon Code",
  isUserPreviewModal,
  disabled = false,
}: Props) {
  return (
    <Modal
      ariaHideApp={false}
      isOpen
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      style={{
        content: {
          padding: 0,
          inset: "50% auto auto 50%",
          background: "none",
          border: "none",
          transform: "translate(-50%, -50%)",
          borderRadius: "1rem",
        },
        overlay: {
          zIndex: 500,
          backgroundColor: "rgba(255,255,255,0.5)",
          inset: 0,
        },
      }}
    >
      <div className="secondary-scrollbar relative max-h-[90vh] w-[1000px] max-w-[90vw] overflow-y-auto overflow-x-hidden rounded-2xl bg-primary p-4 text-white shadow-modal md:p-7 lg:p-10">
        {/* Section 1  */}
        <div className="flex justify-between">
          <div className="flex space-x-3 2xl:h-20">
            <img
              className="aspect-1 h-16 rounded-lg border border-white object-cover 2xl:h-20"
              src={magnetImageUrl}
              alt={magnetName}
            />
            <div className="flex flex-col">
              <span className="text-xl font-bold leading-tight 2xl:text-2xl">{magnetName}</span>
              <span className="-mt-1 text-xxs font-medium opacity-70 2xl:text-xs">{brandName}</span>
            </div>
          </div>
          <div>
            <button type="button" disabled={disabled} onClick={onClose} className="p-3">
              <CloseIcon className="h-4 w-4" />
            </button>
          </div>
        </div>
        <div className="mt-8">
          <div className="flex flex-col-reverse md:flex-col">
            {/* memento and Claim instructions */}
            <div className="flex flex-col items-center justify-start gap-2 md:flex-row md:items-start md:gap-5">
              <div className="aspect-1 h-56 w-56 rounded-xl border border-white 2xl:h-64 2xl:w-64">
                <MagnetImageContainer image={mementoImage} altText={mementoName} />
              </div>
              {/* Section 3 */}
              <div>
                <div className="relative mx-auto mt-8  rounded-lg border-1 border-white border-opacity-70 px-2 py-3 pr-20 md:mx-0 md:mt-0 md:w-fit 2xl:rounded-ll 2xl:py-4">
                  <p className="absolute -top-3 whitespace-nowrap bg-primary text-xxs font-bold 2xl:text-xs">
                    Your coupon code
                  </p>
                  <span className="overflow-x-auto break-all text-sm font-450 tracking-tighter md:tracking-widest 2xl:text-lg">
                    {claimCode}
                  </span>
                  <button
                    type="button"
                    disabled={disabled}
                    onClick={() => copyToClipboard(claimCode)}
                    className="absolute right-0 bg-primary px-3"
                  >
                    <ClipboardIcon className="h-5 w-5 2xl:h-6 2xl:w-6" />
                  </button>
                </div>
                <h2 className="mt-1.5 text-xs font-semibold underline 2xl:text-sm">Claim Code Instructions</h2>
                <div className="secondary-scrollbar max-h-max w-[98%] overflow-scroll overflow-x-hidden text-xxs leading-tight opacity-95 md:max-h-40 xl:text-xs">
                  {mementoInstructions.split("\n").map((line: string, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <p className="mb-1.5 whitespace-pre-wrap" key={index}>
                      {line} <br />
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <div className="mt-1 pb-4 pt-2 md:mt-6">
                <h1 className="text-center text-xl font-bold 2xl:mt-2 2xl:text-2xl">{mementoName}</h1>
                <p className="secondary-scrollbar mb-6 line-clamp-3 w-[99%] overflow-scroll overflow-x-hidden text-center text-xxs font-normal leading-tight 2xl:text-xs">
                  {mementoDescription}
                </p>
                {isUserPreviewModal && (
                  <div className="flex justify-center">
                    <div className="flex items-center space-x-1 rounded-ll bg-secondary p-1.5 md:p-2 2xl:space-x-2">
                      <div>
                        <FeaturedIcon width="20" height="20" />
                      </div>
                      <p className="text-base font-semibold leading-5 md:text-xs">Already Claimed</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {getActionButtons && <div className="flex justify-center gap-2 py-3">{getActionButtons}</div>}
        </div>
      </div>
    </Modal>
  );
}

export default ClaimCodeWithInstructionsModal;
