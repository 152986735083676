import { MAGNET_ACTIVATIONS } from "../constants";

export const getCreateMerchDropEndPoint = (magnetId: string) => `${MAGNET_ACTIVATIONS}${magnetId}/drop/merch_drop/`;
export const getMerchDropEndpoint = (magnetId: string, merchDropId: string) =>
  `${MAGNET_ACTIVATIONS}${magnetId}/drop/merch_drop/${merchDropId}/`;
export const getMerchDropClaimResponseEndPoint = (magnetId: string, merchDropId: string) =>
  `${getMerchDropEndpoint(magnetId, merchDropId)}claim/`;
export const getMerchDropDownloadEndpoint = (magnetId: string, merchDropId: string) =>
  `${MAGNET_ACTIVATIONS}${magnetId}/drop/merch_drop/${merchDropId}/download/`;
export const getEndMerchDropEndpoint = (magnetId: string, merchDropId: string) =>
  `${getMerchDropEndpoint(magnetId, merchDropId)}end/`;
