import { post } from "utils/request";
import {
  USER_SELF,
  VERIFY_SIGNATURE,
  USER_ROUTE,
  USER_ACTIVITY,
  MAGNET_ROUTE,
  BRAND_MAGNET,
  USER_INCENTIVES,
} from "../constants";
import { getEndWalkthroughEndpoint, getRestartWalkthroughEndpoint } from "./walkthrough";

export const getUserSelfURL = () => USER_SELF;
export const getVerifySignatureURL = () => VERIFY_SIGNATURE;
export const getUserPublicProfile = (userId: string) => `${USER_ROUTE}${userId}/`;
export const getUserActivity = (userName: string) => `${USER_ACTIVITY}${userName}`;
export const getUserIncentives = () => USER_INCENTIVES;
export const getClaimMagnetURL = (magnetId: string) => `${MAGNET_ROUTE}${magnetId}/claim/`;
export const getSettlePurchaseURL = (magnetId: string) => `${BRAND_MAGNET}${magnetId}/settle-purchase/`;
export const getLoginURL = `${USER_ROUTE}login/`;
export const getLogoutURL = `${USER_ROUTE}logout/`;
export const getResendOtpURL = `${USER_ROUTE}resend-otp/`;
export const getNewUserOtpRequestURL = `${USER_ROUTE}signup/`;
export const getResetPasswordOtpRequestURL = getResendOtpURL;
export const getVerifyOtpURL = `${USER_ROUTE}verify-otp/`;
export const getChangePasswordURL = `${USER_ROUTE}change-password/`;

export const endMagnetiqProgram = (successHandler: any, errorHandler: any) => {
  post(
    getEndWalkthroughEndpoint,
    {},
    {
      processData: successHandler,
      processError: errorHandler,
    },
  );
};

export const restartMagnetiqProgram = (successHandler: any, errorHandler: any) => {
  post(
    getRestartWalkthroughEndpoint,
    {},
    {
      processData: successHandler,
      processError: errorHandler,
    },
  );
};
