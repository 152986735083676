import { post } from "utils/request";
import toast from "react-hot-toast";
import { useContext } from "react";
import { LoaderContext } from "components/context/LoaderContext";
// import Button from "components/atoms/buttons/Button";
import { getClaimCodeRoute } from "apis/claimCode";

import ClaimCodeWithInstructionsModal from "./ClaimCodeWithInstructionsModal";
import ClaimModal from "./ClaimModal";

// function BottomBarButtons({ handleClaimButtonClick }: { handleClaimButtonClick: () => void }) {
//   return (
//     <Button
//       text="LIST"
//       disabled
//       variant="magnetiqSolid"
//       additionalClasses="xl:py-2 px-9 xl:text-xxs 2xl:text-sm 2xl:py-4"
//       onClick={handleClaimButtonClick}
//     />
//   );
// }

type Props = {
  onModalClose: () => void;
  claimId: string;
  claimData: any;
  isMementoOwnerByUser: boolean;
  refetch: () => void;
};

function ClaimModalContainer({ onModalClose, claimId, claimData, refetch, isMementoOwnerByUser }: Props) {
  const { setLoading } = useContext(LoaderContext);

  const handleClaimCodeSuccess = () => {
    toast.success("Code Claimed Successfully");
    refetch();
    setLoading(false);
  };

  const handleClaimCodeError = (errorMessage: string) => {
    toast.error(errorMessage);
    setLoading(false);
  };

  const handleClaimButtonClick = () => {
    setLoading(true);
    post(
      getClaimCodeRoute(claimData.magnet_id, claimId),
      {},
      {
        processData: handleClaimCodeSuccess,
        processError: handleClaimCodeError,
      },
    );
  };

  return claimData.is_claimed ? (
    <ClaimCodeWithInstructionsModal
      magnetImageUrl={claimData.magnet_image_url}
      brandName={claimData.brand_name}
      magnetName={claimData.magnet_name}
      mementoName={claimData.name}
      mementoDescription={claimData.description}
      mementoImage={claimData.thumbnail_url}
      mementoInstructions={claimData.claim_instructions}
      onClose={onModalClose}
      isUserPreviewModal
      claimCode={claimData.code}
    />
  ) : (
    <ClaimModal
      claimData={claimData}
      onClose={onModalClose}
      handleClaimButtonClick={handleClaimButtonClick}
      disableClaimButton={!isMementoOwnerByUser}
    />
  );
}
export default ClaimModalContainer;
