import { CloseIcon } from "components/atoms/Icon";
import Modal from "components/atoms/modal";
import MagnetImageContainer from "components/atoms/images/MagnetImageContainer";
import useCountdown from "components/hooks/useCountDown";
import { DetailHeadingTemplate } from "components/atoms/DetailHeading";
import { Moment } from "moment";
import { DateTimeCell } from "pages/Analytics/Components/TopReleasesTable";
import Button from "components/atoms/buttons/Button";
import { MouseEventHandler, useState } from "react";
import { useLoaderContext } from "components/context/LoaderContext";
import { post } from "utils/request";
import { getEndShareRequestEndPoint } from "apis/shareRequest";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useUserContext } from "components/context/UserContext";
import { SparklesIcon } from "@heroicons/react/24/solid";
import ShareRequestConfirmPostModal from "./ShareRequetConfirmPostModal";
import IncentivePreview from "./IncentivePreview";

type Props = {
  id: string;
  name: string;
  description: string;
  thumbnailUrl: string;
  mediaUrl?: string;
  mediaType?: string;
  postData: ShareRequestPostData;
  magnetId: string;
  magnetName: string;
  startTime: number | Moment;
  endTime: number | Moment;
  autoAirdropOnPurchase: boolean;
  magnetImageUrl: string;
  brandName: string;
  brandId: string;
  onClose: () => void;
  hasUserPosted?: boolean;
  refetchMementoData?: Function;
  eligibleIncentives?: IncentiveMemento[];
} & (
  | {
      isBrandPreview: true;
      disabled?: boolean;
      publishHandler: MouseEventHandler;
      dropStatus: MementoStatus;
    }
  | {
      isBrandPreview?: false;
      disabled?: never;
      publishHandler?: MouseEventHandler;
      dropStatus?: MementoStatus;
    }
);

function ShareRequestModal({
  id,
  name,
  description,
  thumbnailUrl,
  mediaUrl,
  mediaType,
  postData,
  startTime,
  endTime,
  autoAirdropOnPurchase,
  magnetId,
  magnetName,
  magnetImageUrl,
  brandName,
  brandId,
  eligibleIncentives,
  onClose,
  isBrandPreview,
  disabled = false,
  publishHandler,
  dropStatus,
  hasUserPosted,
  refetchMementoData,
}: Props) {
  const { days, hours, minutes, seconds, saleTimePastCurrentTime } = useCountdown(endTime as number);
  const { loading, setLoading } = useLoaderContext();
  const [tweetPreviewVisible, setTweetPreviewVisible] = useState(false);
  const { isUserLoggedIn } = useUserContext();

  const isShareRequestExpired = saleTimePastCurrentTime || [5, 6, 7].includes(dropStatus || 0);

  const endShareRequest = () => {
    try {
      setLoading(true, "Please wait, ending Share Request.");
      post(
        getEndShareRequestEndPoint(magnetId, id),
        {},
        {
          processData: () => {
            setLoading(false);
            onClose();
            toast.success("Share Request Ended Successfully");
          },
          processError: () => {
            setLoading(false);
            toast.error("Something went wrong! Couldn't End Share Request.");
          },
        },
      );
    } catch {
      setLoading(false);
      console.log(autoAirdropOnPurchase);
    }
  };

  const onTweetPreviewClick = () => {
    if (isUserLoggedIn) {
      setTweetPreviewVisible(true);
    } else {
      toast.error("Login to view Share Request!");
    }
  };

  const redundendClick = () => {
    toast.success(`Share Request ${loading ? "Processing" : "Completed"}`);
  };

  return (
    <>
      {isUserLoggedIn && (
        <ShareRequestConfirmPostModal
          isOpen={tweetPreviewVisible && !!postData?.post_content}
          onClose={() => {
            setTweetPreviewVisible(false);
          }}
          id={id}
          name={name}
          magnetId={magnetId}
          brandId={brandId}
          postPreview={postData?.post_content}
          brandName={brandName}
          magnetImageUrl={magnetImageUrl}
          magnetName={magnetName}
          media={postData?.is_media_shared && mediaType && mediaUrl ? { mediaType, mediaUrl } : undefined}
          refetchMementoData={refetchMementoData}
        />
      )}
      <Modal open onClose={onClose} closeOnOutsideClick={false}>
        <div className="m-2 w-[97vw] max-w-[900px] rounded-2xl bg-primary p-5 font-satoshi text-white shadow-modal 2xl:p-7">
          {/* Section 1  */}
          <div className="flex justify-between">
            <div className="flex space-x-3 2xl:h-20">
              <img
                className="aspect-1 h-16 rounded-lg border border-white object-cover 2xl:h-20"
                src={magnetImageUrl}
                alt={magnetName}
              />
              <div className="flex flex-col">
                <Link
                  to={`/brand/${brandId}/magnet/${magnetId}`}
                  onClick={onClose}
                  className="text-xl font-bold leading-tight 2xl:text-2xl"
                >
                  {magnetName}
                </Link>
                <Link
                  to={`/brand-public-profile/${brandId}`}
                  onClick={onClose}
                  className="-mt-1 text-xxs font-medium opacity-70 2xl:text-xs"
                >
                  {brandName}
                </Link>
              </div>
            </div>
            <div>
              <button type="button" disabled={disabled || loading} onClick={onClose} className="p-3">
                <CloseIcon className="h-4 w-4" />
              </button>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex flex-col-reverse md:flex-col">
              {/* memento and Claim instructions */}
              <div className="flex flex-col items-center justify-start gap-2 md:flex-row md:items-start md:gap-5">
                <div className="aspect-1 h-56 w-56 shrink-0 overflow-hidden rounded-xl border border-white 2xl:h-64 2xl:w-64">
                  {mediaType?.startsWith("image") ? (
                    <MagnetImageContainer image={thumbnailUrl} altText={magnetName} />
                  ) : (
                    <video src={mediaUrl} className="h-full w-full" controls>
                      <track kind="captions" />
                    </video>
                  )}
                </div>
                {/* Section 3 */}
                <div>
                  <h1 className="text-xl font-bold 2xl:text-2xl">{name}</h1>
                  <h2 className="text-xs font-semibold underline 2xl:text-sm">Share Request Instructions:</h2>
                  <div className="secondary-scrollbar max-h-max w-[98%] overflow-scroll overflow-x-hidden text-xxs leading-tight opacity-95 md:max-h-40 xl:text-xs">
                    {description.split("\n").map((line: string, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <p className="mb-1.5 whitespace-pre-wrap" key={index}>
                        {line}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <div className="py-2 text-center">
                  {!isShareRequestExpired && (
                    <div className="mb-4 inline-block rounded-xl border-1 border-secondary border-opacity-30 px-7 py-2">
                      <h2 className="mb-1 text-center text-base font-semibold underline-offset-4">Time Remaining</h2>
                      <div className=" flex justify-center gap-1.5">
                        <DetailHeadingTemplate heading={days} subHeading="Days" />
                        <DetailHeadingTemplate heading={hours} subHeading="Hours" />
                        <DetailHeadingTemplate heading={minutes} subHeading="Mins" />
                        <DetailHeadingTemplate heading={seconds} subHeading="Secs" />
                      </div>
                    </div>
                  )}
                  {isBrandPreview ? (
                    <div className="-mb-3 rounded-xl border-1 border-dashed border-slate-600 border-opacity-60 bg-slate-900 py-4">
                      <div className="flex w-full justify-center space-x-14 tracking-wide">
                        <div>
                          <p className="mb-0.5 ml-2 text-xxs font-semibold">From</p>
                          <div className="rounded-lg border-1 border-secondary p-1.5">
                            <DateTimeCell timestamp={startTime as number} />
                          </div>
                        </div>
                        <div>
                          <p className="mb-0.5 ml-2 text-xxs font-semibold">Till</p>
                          <div className="rounded-lg border-1 border-secondary p-1.5">
                            <DateTimeCell timestamp={endTime as number} />
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 flex justify-center gap-4">
                        <Button
                          disabled={disabled || loading}
                          variant="whiteOutline"
                          text="CANCEL"
                          additionalClasses="xl:py-1.5"
                          onClick={onClose}
                        />
                        {![5, 6, 7].includes(dropStatus) &&
                          ([1, 2, 3, 4].includes(dropStatus) ? (
                            <Button
                              disabled={disabled || loading}
                              text="END SHARE REQUEST"
                              variant="magnetiqSolid"
                              additionalClasses="xl:py-1.5"
                              onClick={endShareRequest}
                            />
                          ) : (
                            <Button
                              disabled={disabled || loading}
                              text="PUBLISH"
                              variant="magnetiqSolid"
                              additionalClasses="xl:py-1.5"
                              onClick={publishHandler}
                            />
                          ))}
                      </div>
                    </div>
                  ) : (
                    <div className="flex justify-center gap-4">
                      <Button
                        disabled={disabled || loading || isShareRequestExpired}
                        text={
                          // eslint-disable-next-line no-nested-ternary
                          hasUserPosted
                            ? "Share Request Completed"
                            : isShareRequestExpired
                            ? "Share Request Closed"
                            : "TWEET PREVIEW"
                        }
                        variant="magnetiqSolid"
                        additionalClasses={clsx("xl:py-1.5", hasUserPosted ? "opacity-60" : "")}
                        onClick={hasUserPosted || loading ? redundendClick : onTweetPreviewClick}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {eligibleIncentives && eligibleIncentives.length !== 0 && (
            <div>
              <div className="inline-block cursor-pointer border-b-1 border-white font-semibold opacity-90">
                <SparklesIcon className="mb-1 mr-1 inline-block h-4 w-4 text-white" />
                Eligible For Incentives
              </div>
              <div className="secondary-scrollbar flex gap-2 overflow-x-scroll pb-3 pt-2.5">
                {eligibleIncentives?.map((incentive) => (
                  <IncentivePreview key={incentive.id} incentiveData={incentive} />
                ))}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default ShareRequestModal;
