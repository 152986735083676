import InputFieldWithLabel from "components/molecules/forms/InputFieldWithLabel";
import SelectElement from "components/molecules/forms/SelectElement";
// import { countryList } from "constants/constants";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { getAndSetStatesForCountry, handleAllowWholeNumberAsInput, getCountryList } from "utils/HelperFunctions";
// import { useLocation } from "react-router-dom";
import { MerchDropUserResponse } from "./MerchDropUserResponseModalContainer";

function MerchDropShippingInfoForm({ brandId }: { brandId: string }) {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<MerchDropUserResponse>();
  const [availableStateOptions, setAvailableStatesOptions] = useState<string[]>([]);
  const [stateOptionsLoading, setStateOptionsLoading] = useState(false);

  const selectedCountry = watch("shipping_response.country");
  const selectedState = watch("shipping_response.state");

  const [countries, setCountries] = useState<Array<string>>([]);

  useEffect(() => {
    async function getcountries() {
      const countrylist = await getCountryList(brandId);
      setCountries(countrylist.countries);
    }
    getcountries();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      getAndSetStatesForCountry(selectedCountry, setStateOptionsLoading, setAvailableStatesOptions, brandId);
    }
  }, [selectedCountry]);

  return (
    <div className="mt-4 grid grid-cols-10 gap-x-7 pb-4">
      <div className="col-span-10 md:col-span-5">
        <InputFieldWithLabel
          labelClasses="text-base sm:text-base xl:text-xs "
          additionalClasses="text-xxs sm:text-xxs 2xl:text-xs"
          register={register}
          label="First Name"
          id="shipping_response.first_name"
          placeHolder="Enter first name"
          errorMessage={errors.shipping_response?.first_name?.message}
        />
      </div>
      <div className="col-span-10 md:col-span-5">
        <InputFieldWithLabel
          labelClasses="text-base sm:text-base xl:text-xs"
          additionalClasses="text-xxs sm:text-xxs 2xl:text-xs"
          register={register}
          label="Last Name"
          id="shipping_response.last_name"
          placeHolder="Enter last Name"
          errorMessage={errors.shipping_response?.last_name?.message}
        />
      </div>
      <div className="col-span-10  md:col-span-5">
        <InputFieldWithLabel
          labelClasses="text-base sm:text-base xl:text-xs "
          additionalClasses="text-xxs sm:text-xxs 2xl:text-xs"
          register={register}
          label="Address Line 1"
          id="shipping_response.address_line_1"
          placeHolder="Enter Street Address"
          errorMessage={errors.shipping_response?.address_line_1?.message}
        />
      </div>
      <div className=" col-span-10 md:col-span-5">
        <InputFieldWithLabel
          labelClasses="text-base sm:text-base xl:text-xs "
          additionalClasses="text-xxs sm:text-xxs 2xl:text-xs"
          register={register}
          label="Address Line 2"
          id="shipping_response.address_line_2"
          placeHolder="Enter Other Applicable Information"
          errorMessage={errors.shipping_response?.address_line_2?.message}
        />
      </div>

      <div className="col-span-10  md:col-span-5">
        <SelectElement
          labelClasses="text-base sm:text-base xl:text-xs "
          additionalClasses="text-xs"
          label="Country"
          id="shipping_response.country"
          register={register}
          placeHolder="Select Country"
          // @ts-ignore
          selectedValue={selectedCountry || ""}
          errorMessage={errors.shipping_response?.country?.message}
          options={countries}
          isCountrySelector
          optionsLoading={false}
        />
      </div>
      <div className=" col-span-10 md:col-span-5">
        <SelectElement
          labelClasses="text-base sm:text-base xl:text-xs "
          additionalClasses="text-xs"
          label="State"
          id="shipping_response.state"
          register={register}
          placeHolder="Select State or Province"
          // @ts-ignore
          selectedValue={selectedState || ""}
          errorMessage={errors.shipping_response?.state?.message}
          options={availableStateOptions || []}
          optionsLoading={stateOptionsLoading}
        />
      </div>
      <div className=" col-span-10 md:col-span-5">
        <InputFieldWithLabel
          labelClasses="text-base sm:text-base xl:text-xs "
          additionalClasses="text-xxs sm:text-xxs 2xl:text-xs"
          register={register}
          label="City"
          id="shipping_response.city"
          placeHolder="Enter City"
          errorMessage={errors.shipping_response?.city?.message}
        />
      </div>
      <div className=" col-span-10 md:col-span-5">
        <InputFieldWithLabel
          labelClasses="text-base sm:text-base xl:text-xs "
          additionalClasses="text-xxs sm:text-xxs 2xl:text-xs"
          register={register}
          label="Zip Code"
          id="shipping_response.zipcode"
          placeHolder="Enter Zip Code"
          handleOnKeyDown={(e) => handleAllowWholeNumberAsInput(e, true)}
          errorMessage={errors.shipping_response?.zipcode?.message}
        />
      </div>
    </div>
  );
}
export default MerchDropShippingInfoForm;
