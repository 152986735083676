import { MAGNETIQ_PROGRAM_ROUTE } from "../constants";

export const getMagnetiqMagnetDetailsEndpoint = `${MAGNETIQ_PROGRAM_ROUTE}default-brand/`;

export const getAirdropMagnetiqMagnetEndpoint = `${MAGNETIQ_PROGRAM_ROUTE}setup/`;

export const getDapperSetupEndpoint = "/api/v1/dapper/setup";

export const getEndWalkthroughEndpoint = `${MAGNETIQ_PROGRAM_ROUTE}end/`;

export const getRestartWalkthroughEndpoint = `${MAGNETIQ_PROGRAM_ROUTE}restart/`;
