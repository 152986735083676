import React from "react";

interface IProps {
  className?: string;
  content: string | number;
  backgroundColor?: string;
  forGroundCOlor?: string;
  style?: any;
}
// eslint-disable-next-line react/function-component-definition
const Badge: React.FC<IProps> = ({
  className,
  content,
  backgroundColor = "#ffffff",
  forGroundCOlor = "#000000",
  style = {},
}) => (
  <div
    className={`flex items-center justify-center rounded-3xl p-1 font-sora text-base font-medium ${className}`}
    style={{ ...style, background: backgroundColor, color: forGroundCOlor }}
  >
    {content}
  </div>
);

export default Badge;
