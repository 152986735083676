/* eslint-disable @typescript-eslint/indent */
import { useQuery } from "@tanstack/react-query";
import { getPublicMemento } from "apis/magnets";
import ViewMementoModal from "pages/UserProfile/ViewMementoModal";
import React, { useState } from "react";

import toast from "react-hot-toast";
import { get } from "utils/request";
import Loading from "./atoms/Loading";
import { useUserContext } from "./context/UserContext";
import ClaimModalContainer from "./organisms/claimcode/ClaimModalContainer";
import MerchDropPublicViewModalContainer from "./organisms/merchdrop/MerchDropPublicViewModalContainer";
import UserPollModalContainer from "./organisms/poll/UserPollModalContainer";
import ShareRequestModal from "./organisms/ShareRequest/ShareRequestModal";

function GlobalMementoModalProvider({ children }: { children: React.ReactNode }) {
  const { isMagnetInUserInventory, currentlyViewingMementoId, setCurrentlyViewingMementoId, notificationSubType } =
    useUserContext();

  const [isPoll, setIsPoll] = useState(false);

  // @ts-ignore
  const isMementoOwnedByUser = isMagnetInUserInventory(currentlyViewingMementoId);

  const getMementoData = () =>
    // In case of a poll, we public route is not available, so we use the private route
    currentlyViewingMementoId
      ? get(
          getPublicMemento(
            currentlyViewingMementoId,
            !isMementoOwnedByUser && !isPoll && !notificationSubType?.includes("SOCIAL_SHARE"),
          ),
          {
            processError: (errorMessage: string, error: any) => {
              // This is additional logic only used for polls. If the user is not logged in, the public route will return a 400 error with the message "Invalid activation Type Memento"
              if (error.response.status === 400 && errorMessage === "Invalid activation Type Memento") {
                // If poll is already set and we still get the same message from the server, then the user is not logged into in this case.
                if (isPoll) {
                  toast.error("You must be logged in to view the polls.");
                  return;
                }

                setIsPoll(true);
                return;
              }

              toast.error(errorMessage);
              if (error.response.status !== 404) {
                // onClose();
              }
            },
          },
        )
      : null;

  // Fetch memento data using tanstack/react-query
  const {
    data: mementoData,
    isLoading: isMementoDataLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: [currentlyViewingMementoId, isMementoOwnedByUser, isPoll],
    queryFn: getMementoData,
    enabled: !!currentlyViewingMementoId,
  });

  const handleRefetch = () => {
    refetch();
  };

  const handleClearCurrentlyViewingMementoId = () => setCurrentlyViewingMementoId(null);

  const handleRenderMementoModal = () => {
    if (!currentlyViewingMementoId) return null;

    if (isMementoDataLoading) return "Loading memento data";

    if (!mementoData) return "No memento data";

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { activation_key }: { activation_key: ActivationsKeys } = mementoData;

    if (mementoData) {
      switch (activation_key) {
        case "mem_drop":
          return (
            <ViewMementoModal
              onClose={handleClearCurrentlyViewingMementoId}
              mementoId={currentlyViewingMementoId}
              data={mementoData}
            />
          );
        case "merch_drop":
          return (
            <MerchDropPublicViewModalContainer
              brandId={mementoData.brand_id}
              mementoId={currentlyViewingMementoId}
              merchDropData={mementoData}
              onClose={handleClearCurrentlyViewingMementoId}
              isMementoOwnedByUser={isMementoOwnedByUser}
              refetch={refetch}
            />
          );
        case "claim_code":
          return (
            <ClaimModalContainer
              refetch={refetch}
              isMementoOwnerByUser={isMementoOwnedByUser}
              claimData={mementoData}
              onModalClose={handleClearCurrentlyViewingMementoId}
              claimId={currentlyViewingMementoId}
            />
          );
        case "poll":
          return (
            <UserPollModalContainer
              isModalOpen
              refetch={handleRefetch}
              pollData={mementoData}
              onModalClose={() => {
                handleClearCurrentlyViewingMementoId();
                setIsPoll(false);
              }}
              pollId={currentlyViewingMementoId}
              isLoading={isRefetching}
            />
          );
        case "social_share":
          return (
            <ShareRequestModal
              id={currentlyViewingMementoId}
              onClose={handleClearCurrentlyViewingMementoId}
              name={mementoData.name}
              thumbnailUrl={mementoData.thumbnail_url}
              description={mementoData.description}
              mediaUrl={mementoData.media_url}
              mediaType={mementoData.media_type}
              postData={mementoData.post_data}
              startTime={mementoData.start_time}
              endTime={mementoData.end_time}
              autoAirdropOnPurchase={mementoData.auto_drop_on_purchase}
              magnetId={mementoData.magnet_id}
              magnetName={mementoData.magnet_name}
              magnetImageUrl={mementoData.magnet_image_url}
              brandName={mementoData.brand_name}
              hasUserPosted={mementoData.has_posted}
              refetchMementoData={refetch}
              dropStatus={mementoData.status}
              brandId={mementoData.brand_id}
              eligibleIncentives={mementoData.eligible_incentives}
            />
          );
        default:
          return null;
      }
    }
    return null;
  };

  return (
    <>
      <div className="absolute">{handleRenderMementoModal()}</div>
      {isMementoDataLoading && currentlyViewingMementoId && <Loading />}
      {children}
    </>
  );
}

export default GlobalMementoModalProvider;
