import Table from "components/molecules/Table";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from "@tanstack/react-table";
import { MouseEventHandler } from "react";
import { ArrowUpIcon, ArrowDownIcon, ArrowsUpDownIcon } from "@heroicons/react/20/solid";
import { DateIcon, MagnetReleaseIcon, TimeIcon } from "components/atoms/Icon";
import Badge from "components/atoms/badge";
import {
  isReleaseDraft,
  isReleaseEnded,
  isReleaseInSchedulingState,
  isReleaseOnSale,
  isReleaseScheduled,
} from "utils/helpers";
import moment from "moment";
import { DATE_FORMAT } from "../../../constants";
// import CirclePreview from "./CirclePreview/CirclePreview";

const getStatusBadge = (status: ReleaseStatus) => {
  if (isReleaseDraft(status)) {
    return (
      <Badge
        className="border border-white px-3 py-0.5 text-xxs 2xl:px-5 2xl:py-1 2xl:text-xs"
        forGroundCOlor="#060F1B"
        backgroundColor="white"
        content="Draft"
      />
    );
  }

  if (isReleaseInSchedulingState(status)) {
    return (
      <Badge
        className="border border-white px-3 py-0.5 text-xxs 2xl:px-5 2xl:py-1 2xl:text-xs"
        forGroundCOlor="#060F1B"
        backgroundColor="white"
        content="Scheduling"
      />
    );
  }
  if (isReleaseScheduled(status)) {
    return (
      <Badge
        className="border border-white px-3 py-0.5 text-xxs 2xl:px-5 2xl:py-1 2xl:text-xs"
        forGroundCOlor="#060F1B"
        backgroundColor="white"
        content="Scheduled"
      />
    );
  }
  if (isReleaseOnSale(status)) {
    return (
      <Badge
        className="border border-white px-3 py-0.5 text-xxs 2xl:px-5 2xl:py-1 2xl:text-xs"
        forGroundCOlor="#060F1B"
        backgroundColor="white"
        content="On Sale"
      />
    );
  }

  if (isReleaseEnded(status)) {
    return (
      <Badge
        className="border border-white px-3 py-0.5 text-xxs 2xl:px-5 2xl:py-1 2xl:text-xs"
        forGroundCOlor="white"
        backgroundColor="#060F1B"
        content="Sale Ended"
      />
    );
  }
  return null;
};

export function DateTimeCell({ timestamp }: { timestamp: number }) {
  return (
    <div>
      <span
        className="flex items-center gap-1 font-satoshi text-xxs font-semibold text-white
 2xl:gap-2 2xl:text-sm"
      >
        <DateIcon className="h-4 w-4 2xl:h-5 2xl:w-5" color="text-secondary" />
        {moment(timestamp).format(DATE_FORMAT)}
      </span>
      <span
        className="mt-1 flex items-center gap-1 font-satoshi text-xxs font-semibold text-white
 2xl:gap-2 2xl:text-sm"
      >
        <TimeIcon className="h-4 w-4 2xl:h-5 2xl:w-5" color="text-secondary" />

        {moment(timestamp).format("hh:mm A")}
      </span>
    </div>
  );
}

interface IProps {
  data: (Release & { magnet_id: string; magnet_name: string })[];
  handleExport?: MouseEventHandler;
}

const columnHelper = createColumnHelper<Release & { magnet_id: string; magnet_name: string }>();

const columns = [
  columnHelper.accessor("id", {
    header: "Name",
    cell: (info: any) => (
      <div className="ml-2 flex items-center gap-1 2xl:gap-2">
        <MagnetReleaseIcon className="h-6 w-6 2xl:h-7 2xl:w-7" />
        <span className="font-satoshi text-sm font-semibold text-white">{`Release ${info.getValue()}`}</span>
      </div>
    ),
    footer: (info: any) => info.column.id,
  }),
  columnHelper.accessor("status", {
    header: "Status",
    footer: (info: any) => info.column.id,
    cell: (info: any) => (
      <div className="flex">
        <span className="font-satoshi text-lg font-semibold">{getStatusBadge(info.getValue())}</span>
      </div>
    ),
    size: 200,
  }),
  columnHelper.accessor("start_time", {
    header: () => <div className="inline-block">Start Time</div>,
    footer: (info: any) => info.column.id,
    cell: (info: any) => <DateTimeCell timestamp={info.getValue()} />,
  }),
  columnHelper.accessor("end_time", {
    header: () => <div className="inline-block">End Time</div>,
    footer: (info: any) => info.column.id,
    cell: (info: any) => <DateTimeCell timestamp={info.getValue()} />,
  }),
  columnHelper.accessor("sold", {
    header: "Total Sold",
    footer: (info: any) => info.column.id,
    cell: (info: any) => <div className="pr-8 text-center">{info.getValue()}</div>,
  }),
];

function TopReleasesTable({ data }: IProps) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className=" secondary-scrollbar  ">
      <div className="rounded-2xl border border-secondary bg-primary text-primary ">
        <Table>
          <Table.Header>
            {table.getHeaderGroups().map((headerGroup: any) => (
              <Table.Row isHeaderRow key={`audience_table_rows${headerGroup.id}`} headerRowClasses="sticky top-0">
                {headerGroup.headers.map((header: any) => (
                  <Table.HeaderCell
                    align="left"
                    label={
                      header.isPlaceholder ? null : (
                        <button
                          type="button"
                          {...{
                            className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <ArrowUpIcon className="ml-1 inline-block h-4 w-4" />,
                            desc: <ArrowDownIcon className="ml-1 inline-block h-4 w-4" />,
                            false: <ArrowsUpDownIcon className="ml-1 inline-block h-5 w-5" />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </button>
                      )
                    }
                    key={`audience_table_header_${header.id}`}
                  />
                ))}
              </Table.Row>
            ))}
          </Table.Header>

          <Table.Body className="font-satoshi text-sm  font-semibold text-white">
            {table.getRowModel().rows.map((row) => (
              <Table.Row key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Table.Description textAlign="start" key={`audience_table_cell_${cell.id}`}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Description>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}

export default TopReleasesTable;
