import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { clsx } from "clsx";

interface IProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  additionalClasses?: string;
  closeOnOutsideClick?: boolean;
}

export default function Modal({ open, onClose, closeOnOutsideClick, children, additionalClasses }: IProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeOnOutsideClick === false ? () => {} : onClose}>
        <Transition.Child
          as={Fragment}
          enter="duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={clsx("fixed inset-0 bg-white bg-opacity-40 transition-opacity", additionalClasses)} />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-0 md:p-4">
            <Transition.Child
              as={Fragment}
              enter="duration-200"
              enterFrom="opacity-30 translate-y-2/3 scale-0"
              enterTo="opacity-100 translate-y-0"
              leave="duration-200"
              leaveFrom="opacity-60 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-2/3 scale-0"
            >
              <Dialog.Panel className="max-w-fit">{children}</Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
