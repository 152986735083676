import { clsx } from "clsx";
import { MouseEventHandler, ReactNode } from "react";

export default function DesktopSidebarStaticNavButton({
  isSidebarCustomized = false,
  label,
  Icon,
  handleClick,
  isActive,
  additionalClasses,
  activeIcon,
  collapsed,
  renderStaticIcon = false,
}: {
  isSidebarCustomized?: boolean;
  label: ReactNode;
  Icon: any;
  handleClick: MouseEventHandler;
  isActive?: boolean;
  activeIcon?: ReactNode;
  additionalClasses?: string;
  collapsed?: boolean;
  renderStaticIcon?: boolean;
}) {
  return (
    <button
      type="button"
      onClick={handleClick}
      className={clsx(
        "group relative flex w-full items-center gap-x-1 rounded-sm py-2 font-satoshi font-medium leading-7 transition-colors 2xl:gap-x-2 2xl:rounded-md",
        isActive && (isSidebarCustomized ? "bg-[#22405a]" : "bg-[#1c1c1c]"),
        collapsed ? "justify-center pl-0" : "pl-4",
        additionalClasses,
        isSidebarCustomized ? "hover:bg-[#22405a]" : "hover:bg-primary",
      )}
    >
      {!collapsed &&
        (isActive || renderStaticIcon) &&
        (activeIcon ? (
          <div className="absolute right-1 rounded-xl">{activeIcon}</div>
        ) : (
          <div className="absolute right-1 mr-1 h-1/2 w-0.5 rounded-xl bg-secondary 2xl:w-1" />
        ))}
      {Icon}
      {!collapsed && (
        <span className="ml-0 w-full truncate text-left font-satoshi text-xs text-white 2xl:text-sm">{label}</span>
      )}
    </button>
  );
}
