import { Link } from "react-router-dom";

function MagnetImageContainer({
  image,
  altText,
  brandId,
  magnetId,
  magnetName,
  modalFunc,
}: {
  image: string;
  altText: string;
  brandId?: string;
  magnetId?: string;
  magnetName?: string;
  modalFunc?: () => void;
}) {
  return brandId && magnetId && magnetName ? (
    <Link to={`/brand/${brandId}/magnet/${magnetId}`} title={magnetName} onClick={modalFunc}>
      <img className="h-full w-full rounded-xl object-cover" src={image} alt={altText} />
    </Link>
  ) : (
    <img className="h-full w-full rounded-xl object-cover" src={image} alt={altText} />
  );
}

export default MagnetImageContainer;
