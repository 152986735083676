import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  databaseURL: "https://magnetiq-3f65e-default-rtdb.firebaseio.com/",
  projectId: "magnetiq-3f65e",
};

const app = initializeApp(firebaseConfig);

const db = getDatabase(app);
export default db;
