import { Popover } from "@headlessui/react";
import { BellIcon } from "@heroicons/react/24/outline";

type Props = {
  unreadNotificationCount: number | undefined;
};

function NotificationButton({ unreadNotificationCount }: Props) {
  return (
    <Popover.Button className="relative z-40 h-full p-0 focus:outline-none focus-visible:ring-0">
      <div className="relative pr-4 pt-2">
        {unreadNotificationCount ? (
          <p
            className="absolute left-3 top-0 flex h-5 w-5 origin-center
            items-center justify-center rounded-full bg-accent font-satoshi text-xxs font-semibold
           leading-none text-primary
           2xl:h-6 2xl:w-6 2xl:text-xs"
          >
            {unreadNotificationCount}
          </p>
        ) : null}
      </div>
      <BellIcon className="h-8 w-8 text-white" />
    </Popover.Button>
  );
}
export default NotificationButton;
