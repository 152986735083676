/* eslint-disable implicit-arrow-linebreak */
import { BRAND_MAGNET, MAGNET_RELEASE_STATUS, RELEASE } from "../constants";

// eslint-disable-next-line import/prefer-default-export
export const isDeviceMobile = () => (document.documentElement.clientWidth || window.innerWidth) <= 768;

export const convertMegabytesToBytes = (bytes: number) => bytes * 1024 * 1024;
export const convertBytesToMegabytes = (bytes: number) => bytes / (1024 * 1024);

export const humanReadableFileSize = (size: number) => {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / 1024 ** i).toFixed(2)} ${["B", "kB", "MB", "GB", "TB"][i]}`;
};

export const isFileTypeVideo = (file: File) => file.type === "video/mp4";
export const isMediaOfTypeAudioOrVideo = (url: string) =>
  url?.includes("audio") ||
  url?.includes("video") ||
  url?.includes(".mp4") ||
  url?.includes(".MP4") ||
  url?.includes(".MP3") ||
  url?.includes(".mp3");
export const isMediaOfTypeVideo = (url: string) =>
  url?.includes("video") || url?.includes(".mp4") || url?.includes(".MP4");
export const isMediaOfTypeImage = (url: string) =>
  url?.includes("image") ||
  url?.includes(".JPEG") ||
  url?.includes(".jpeg") ||
  url?.includes(".JPG") ||
  url?.includes(".jpg") ||
  url?.includes(".GIF") ||
  url?.includes(".gif") ||
  url?.includes(".PNG") ||
  url?.includes(".png") ||
  url?.includes(".BMP") ||
  url?.includes(".bmp") ||
  url?.includes(".WEBP") ||
  url?.includes(".webp");
export const isMediaOfTypeDocument = (url: string) =>
  !!url && !(isMediaOfTypeAudioOrVideo(url) || isMediaOfTypeImage(url));

export const getMagnetPageLink = (brandId: string = "brandId", magnetId: string = "magnetId") =>
  `/brand/${brandId}/magnet/${magnetId}`;

export const getReleaseUpdateEndpoint = (magnetId: number | string, releaseId: number) =>
  `${BRAND_MAGNET + magnetId + RELEASE}/${releaseId}`;

export const getReleaseEndEndpoint = (magnetId: number | string, releaseId: number) =>
  `${BRAND_MAGNET + magnetId + RELEASE}/${releaseId}/end/`;

export const getCreateMementoAirdropRoute = (brandId: string, magnetId: string) =>
  `/brand/${brandId}/magnet/${magnetId}/airdrop-memento`;

export const getCreateMementoRoute = (brandId: string, magnetId: string) =>
  `/brand/${brandId}/magnet/${magnetId}/:mementoType`;

export const getCreatePollRoute = (brandId: string, magnetId: string) => `/brand/${brandId}/magnet/${magnetId}/poll`;

export const getExclusiveMembersRoute = (brandId: string, magnetId: string) =>
  `/api/v1/brand/${brandId}/magnet/${magnetId}/members`;

export const getEligibilityForMembersRoute = (brandId: string, magnetId: string) =>
  `/api/v1/brand/${brandId}/magnet/${magnetId}/check-membership`;

export const getUpdateExclusiveMembersRoute = (brandId: string, magnetId: string) =>
  `/api/v1/brand/${brandId}/magnet/${magnetId}/update-members`;

export const getExportExclusiveMembersRoute = (brandId: string, magnetId: string) =>
  `/api/v1/brand/${brandId}/magnet/${magnetId}/download-member-data`;

export const getEditMementoAirdropRoute = (brandId: string, magnetId: string, mementoId: string) =>
  `/brand/${brandId}/magnet/${magnetId}/airdrop-memento/edit/${mementoId}`;

export const getEditMementoRoute = (brandId: string, magnetId: string, mementoId: string) =>
  `/brand/${brandId}/magnet/${magnetId}/:mementoType/edit/${mementoId}`;

export const getEditPollRoute = (brandId: string, magnetId: string, pollId: string) =>
  `/brand/${brandId}/magnet/${magnetId}/poll/edit/${pollId}`;

export const getCreateClaimCodeRoute = ({ brandId, magnetId }: { brandId: string; magnetId: string }) =>
  `/brand/${brandId}/magnet/${magnetId}/claim-code`;

export const exportPollData = (pollId: string) => `/api/v1/activations/poll/${pollId}/download/`;

export const getEditClaimCodeRoute = ({
  brandId,
  magnetId,
  claimId,
}: {
  brandId: string;
  magnetId: string;
  claimId: string;
}) => `/brand/${brandId}/magnet/${magnetId}/claim-code/edit/${claimId}`;

export const getCreateShareRequestRoute = ({ brandId, magnetId }: { brandId: string; magnetId: string }) =>
  `/brand/${brandId}/magnet/${magnetId}/share-request`;
export const getEditShareRequestRoute = ({
  brandId,
  magnetId,
  shareRequestId,
}: {
  brandId: string;
  magnetId: string;
  shareRequestId: string;
}) => `/brand/${brandId}/magnet/${magnetId}/share-request/edit/${shareRequestId}`;

export const downloadClaimResponse = (magnetId: string) => `/api/v1/magnet/${magnetId}/get-claim-form-data/`;

export const isMagnetInDraftState = (status: MagnetStates) => status === 0;
export const isMagnetInPublishedState = (status: MagnetStates) => status === 2;
export const isMagnetInPublishingState = (status: MagnetStates) => status === 1;
export const isMagnetDeleted = (status: MagnetStates) => status === 3;

export const isReleaseDraft = (status: ReleaseStatus) => status === MAGNET_RELEASE_STATUS.DRAFT;
export const isReleaseInSchedulingState = (status: ReleaseStatus) => status === MAGNET_RELEASE_STATUS.SCHEDULING;
export const isReleaseScheduled = (status: ReleaseStatus) => status === MAGNET_RELEASE_STATUS.SCHEDULED;
export const isReleaseOnSale = (status: ReleaseStatus) => status === MAGNET_RELEASE_STATUS.ON_SALE;
export const isReleaseEnded = (status: ReleaseStatus) => status === MAGNET_RELEASE_STATUS.SALE_ENDED;
export const isReleaseDeleted = (status: ReleaseStatus) => status === MAGNET_RELEASE_STATUS.DELETED;

export const getCreateMerchDropRoute = (brandId: string, magnetId: string) =>
  `/brand/${brandId}/magnet/${magnetId}/merch-drop`;

export const getEditMerchDropRoute = (brandId: string, magnetId: string, merchDropId: string) =>
  `/brand/${brandId}/magnet/${magnetId}/merch-drop/edit/${merchDropId}`;

export const isObjectEmpty = (obj: Object) => !Object.keys(obj).length;

export const getActivationTypeFromKey = (activation_key: ActivationsKeys) => {
  switch (activation_key) {
    case "mem_drop":
      return "Airdrop";
    case "claim_code":
      return "Claim Code";
    case "merch_drop":
      return "Merch Drop";
    case "poll":
      return "Poll";
    default:
      throw new Error("Invalid Activation Type");
  }
};
