/* eslint-disable operator-linebreak */
import { API_BASE_URL, LOCAL_STORAGE_KEYS } from "../constants";

// eslint-disable-next-line no-confusing-arrow
export const getUrl = (endpoint: string) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  endpoint.includes(API_BASE_URL as string) ? endpoint : API_BASE_URL + endpoint;

export const stringifyParams = (queryParams?: Record<string, string>) => {
  if (!queryParams) return "";

  return `?${new URLSearchParams(queryParams).toString()}`;
};
export const getEndpoint = (path: string, queryParams?: Record<string, string>) => {
  const parametrizedPath = `${path}${stringifyParams(queryParams)}`;
  return getUrl(parametrizedPath);
};

export const getAuthHeaders = (customHeaders?: Record<string, string>) => {
  let token = "";
  const ptoken = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_TOKEN) ?? "";
  if (ptoken) token = JSON.parse(ptoken);
  const headers = {
    "Content-Type": "application/json",
    ...(token && { Authorization: `Token ${token}` }),
    ...(customHeaders || {}),
  };
  return headers;
};

export const getRequestConfig = (options?: RequestOptions) => {
  const headers = getAuthHeaders(options?.headers);
  const body = options?.body ? { body: JSON.stringify(options.body) } : {};

  return {
    headers,
    ...(options?.requestConfig || {}),
    ...body,
  };
};

export const buildErrorMessage = (error: any): string => {
  // eslint-disable-next-line no-console
  console.log(error);
  if (typeof error === "string") {
    return error;
  }

  // Response from the endpoint in case of error.
  const { response } = error;
  // Return the error based on the failing phase
  return (
    response?.data?.message ||
    // Error Format returned from backend
    response?.data?.error?.detail ||
    // Error from axios
    error?.message ||
    error?.request ||
    "Unexpected error"
  );
};
