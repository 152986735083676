import { useEffect } from "react";

type Props = {
  open: boolean;
};

function OverFlowManager({ open }: Props) {
  useEffect(() => {
    if (open) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [open]);

  return null;
}

export default OverFlowManager;
