import { create } from "zustand";

interface BearState {
  sidebarExpanded: boolean | null;
  toggleSideBarVisibility: () => void;
  updateSideBarVisibility: (v: boolean) => void;
}

const useSideBarStore = create<BearState>()((set) => ({
  sidebarExpanded: null,
  toggleSideBarVisibility: () => set((state) => ({ sidebarExpanded: !state.sidebarExpanded })),
  updateSideBarVisibility: (v) => set(() => ({ sidebarExpanded: v })),
}));

export default useSideBarStore;
