/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Dispatch, SetStateAction, useEffect, useState, useCallback } from "react";
import GoogleIcon from "assets/Icons/google.png";
import OtpInput from "react-otp-input";
import ErrorMessage from "components/atoms/forms/ErrorMessage";
import { get, post } from "utils/request";
import { getLoginURL, getNewUserOtpRequestURL, getResendOtpURL, getVerifyOtpURL } from "apis/user";
import toast from "react-hot-toast";
import { useUserContext } from "components/context/UserContext";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { EyeIcon } from "@heroicons/react/20/solid";
import { useLoaderContext } from "components/context/LoaderContext";
import moment from "moment";
import { PasswordSchema } from "utils/HelperFunctions";
import { GOOGLE_RECAPTCHA_KEY } from "../../constants";
import { LoadingIcon } from "../../components/atoms/Icon";

type OnlyEmailType = {
  email: string;
};

type SignInType = OnlyEmailType & {
  password: string;
};

type AfterOtpType = SignInType & {
  confirmPassword: string;
  otp: string;
};

type Payload = {
  email: string;
  recaptchaToken: string;
  password?: string;
  otp?: string;
};

const EmailSchema = yup.string().email("Please enter a valid email address").required("Email is required");

const Schemas = {
  0: yup.object().shape({
    email: EmailSchema,
    password: yup.string().required("Please enter password"),
  }),
  1: yup.object().shape({
    email: EmailSchema,
  }),
  2: yup.object().shape({
    email: EmailSchema,
  }),
  3: yup.object().shape({
    email: EmailSchema,
    otp: yup.string().min(6, "Please enter complete OTP").required("Enter OTP to proceed"),
    password: PasswordSchema,
    confirmPassword: yup
      .string()
      .required("Please re-enter your password")
      .oneOf([yup.ref("password")], "Passwords doesn't match"),
  }),
};

function SignInForm({
  formType,
  setFormType,
  onLogin,
}: {
  formType: 0 | 1 | 2 | 3;
  setFormType: Dispatch<SetStateAction<0 | 1 | 2 | 3>>;
  onLogin?: () => void;
}) {
  const [token, setToken] = useState<string>("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const { setTokenId } = useUserContext();
  const [isProcessing, setIsProcessing] = useState(false);
  // const [formType, setFormType] = useState<0 | 1 | 2 | 3>(1); // 0- signin, 1- signup, 2- reset password, 3- Otp Sent
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const {
    formState: { errors },
    watch,
    register,
    setValue,
    handleSubmit,
    clearErrors,
    resetField,
    reset,
    setError,
  } = useForm<AfterOtpType>({ resolver: yupResolver(Schemas[formType]) });

  const [lastOtpTimeStamp, setLastOtpTimeStamp] = useState<number | null>(null);
  const [timeLeftForResendOtp, setTimeLeftForResendOtp] = useState<number | null>(null);
  const { setLoading } = useLoaderContext();

  useEffect(() => {
    if (lastOtpTimeStamp) {
      const resendOtpTicking = setInterval(() => {
        const msLeft = 120000 - moment.now() + lastOtpTimeStamp;
        if (msLeft >= 0) {
          setTimeLeftForResendOtp(Math.ceil(msLeft / 1000));
        } else {
          setTimeLeftForResendOtp(null);
          setLastOtpTimeStamp(null);
          clearInterval(resendOtpTicking);
        }
      }, 1000);
    }
  }, [lastOtpTimeStamp]);

  useEffect(() => {
    clearErrors("email");
    resetField("password");
    setIsPasswordVisible(false);
  }, [formType]);

  const onVerify = useCallback((token: string) => {
    setToken(token);
  }, []);
  return (
    <div className="my-2 flex flex-col gap-6 border-b border-white border-opacity-10 pb-4 font-satoshi md:border-0">
      <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_KEY}>
        <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
      </GoogleReCaptchaProvider>
      <form className="space-y-4">
        {formType === 1 && <h2 className="text-3xl font-black text-white opacity-80">Sign Up</h2>}
        <div>
          <label htmlFor="email" className="block text-sm font-bold leading-6 text-neutral-200">
            Email
          </label>
          <div className="mt-1">
            <input
              {...register("email")}
              placeholder={formType === 2 ? "Enter registered email" : "Enter your email"}
              id="email"
              name="email"
              type="email"
              required
              disabled={formType === 3}
              className="block w-full rounded-md border-0 bg-white bg-opacity-5 py-4 pl-3.5 font-medium text-neutral-200 shadow-sm ring-2 ring-inset ring-white ring-opacity-5 placeholder:text-neutral-500 focus:ring-2 focus:ring-inset focus:ring-accent disabled:cursor-not-allowed disabled:bg-opacity-20 disabled:ring-opacity-25 sm:text-sm sm:leading-6"
            />
          </div>
          <ErrorMessage errorMessage={errors?.email?.message} />
        </div>

        {formType === 0 && (
          <div>
            <label htmlFor="password" className="block text-sm font-bold leading-6 text-neutral-200">
              Password
            </label>
            <div className="relative mt-1">
              <input
                {...register("password")}
                placeholder="Enter password"
                id="password"
                name="password"
                type={isPasswordVisible ? "text" : "password"}
                required
                className="block w-full rounded-md border-0 bg-white bg-opacity-5 py-4 pl-3.5 font-medium text-neutral-200 shadow-sm ring-2 ring-inset ring-white ring-opacity-5 placeholder:text-neutral-500 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
              />
              <button
                type="button"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                className="absolute right-[3%] top-[35%] opacity-70 hover:opacity-95"
              >
                <EyeIcon className="h-5 w-5 text-white" />
              </button>
            </div>
            <ErrorMessage errorMessage={errors?.password?.message} />
          </div>
        )}

        {formType === 3 && (
          <>
            <div>
              <label htmlFor="password" className="block text-sm font-bold leading-6 text-neutral-200">
                Enter One Time Password sent to Your Email
              </label>
              <div className="mt-1 flex flex-col gap-3 sm:flex-row">
                {/* @ts-ignore */}
                <OtpInput
                  onChange={(val: any) => setValue("otp", val)}
                  value={watch("otp")}
                  numInputs={6}
                  inputType="number"
                  renderSeparator={<span>.</span>}
                  renderInput={(props) => (
                    <input
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                      style={{
                        width: "3em",
                        textAlign: "center",
                      }}
                      className="spin-button-none rounded-md border-0 bg-white bg-opacity-5 py-4 pl-3.5 font-medium text-neutral-200 shadow-sm ring-2 ring-inset ring-white ring-opacity-5 placeholder:text-neutral-500 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                    />
                  )}
                />
                <p className="flex flex-col items-start justify-center whitespace-nowrap text-xs text-gray-500">
                  Didn&apos;t Recieve?{" "}
                  <button
                    disabled={!!lastOtpTimeStamp}
                    type="button"
                    onClick={() => {
                      post(
                        getResendOtpURL,
                        { email: watch("email") },
                        {
                          processData: ({ data: { last_otp_timestamp } }) => {
                            toast.success(`OTP successfully sent to ${watch("email")}.`);
                            setLastOtpTimeStamp(last_otp_timestamp);
                          },
                          processError: () => {
                            toast.error("Unexpected error while sending OTP, please try again.");
                          },
                        },
                      );
                      setLastOtpTimeStamp(moment.now());
                      setRefreshReCaptcha(!refreshReCaptcha);
                    }}
                    className="font-semibold leading-6 text-accent text-opacity-90 hover:text-opacity-100 hover:underline disabled:text-neutral-400 disabled:hover:cursor-progress"
                  >
                    Resend{timeLeftForResendOtp && ` in ${timeLeftForResendOtp}`}
                  </button>
                </p>
              </div>
              <ErrorMessage errorMessage={errors?.otp?.message} />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-bold leading-6 text-neutral-200">
                New Password
              </label>
              <div className="relative mt-1">
                <input
                  {...register("password")}
                  placeholder="Enter password"
                  id="password"
                  name="password"
                  type={isPasswordVisible ? "text" : "password"}
                  required
                  className="block w-full rounded-md border-0 bg-white bg-opacity-5 py-4 pl-3.5 font-medium text-neutral-200 shadow-sm ring-2 ring-inset ring-white ring-opacity-5 placeholder:text-neutral-500 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                />
                <button
                  type="button"
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  className="absolute right-[3%] top-[35%] opacity-70 hover:opacity-95"
                >
                  <EyeIcon className="h-5 w-5 text-white" />
                </button>
              </div>
              <ErrorMessage errorMessage={errors?.password?.message} />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-bold leading-6 text-neutral-200">
                Confirm Password
              </label>
              <div className="mt-1">
                <input
                  {...register("confirmPassword")}
                  placeholder="Re-Enter password"
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  required
                  className="block w-full rounded-md border-0 bg-white bg-opacity-5 py-4 pl-3.5 font-medium text-neutral-200 shadow-sm ring-2 ring-inset ring-white ring-opacity-5 placeholder:text-neutral-500 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                />
              </div>
              <ErrorMessage errorMessage={errors?.confirmPassword?.message} />
            </div>
          </>
        )}

        {formType === 0 && (
          <div className="text-right text-sm">
            <button
              type="button"
              onClick={() => setFormType(2)}
              className="font-semibold text-accent text-opacity-90 underline-offset-4 hover:text-opacity-100 hover:underline"
            >
              Forgot password?
            </button>
          </div>
        )}

        <div className="space-y-2">
          <button
            disabled={isProcessing}
            onClick={handleSubmit((data) => {
              setIsProcessing(true);
              setRefreshReCaptcha(!refreshReCaptcha);
              let apiEndpoint = "";
              const payload: Payload = { email: data.email, recaptchaToken: token };

              switch (formType) {
                case 0:
                  payload.password = data.password;
                  apiEndpoint = getLoginURL;
                  break;
                case 1:
                  apiEndpoint = getNewUserOtpRequestURL;
                  break;
                case 2:
                  apiEndpoint = getResendOtpURL;
                  break;
                case 3:
                  payload.password = data.password;
                  payload.otp = data.otp;
                  apiEndpoint = getVerifyOtpURL;
                  break;
                default:
                  throw new Error("Unexpected Error!");
              }

              post(apiEndpoint, payload, {
                processData: ({ data }) => {
                  setIsProcessing(false);

                  if (data.success === false) {
                    toast.error("We are sorry, your request could not be processed right now. Please reload the page.");
                    return;
                  }

                  if (formType === 1 || formType === 2) {
                    setLastOtpTimeStamp(data.last_otp_timestamp);
                    setFormType(3);
                    setRefreshReCaptcha(!refreshReCaptcha);
                  } else {
                    setTokenId(data.token);
                    if (onLogin) {
                      onLogin();
                    }
                    // set Token and Sign in
                  }
                },
                processError: (err) => {
                  setIsProcessing(false);
                  let errField: "password" | "email" | "otp";
                  switch (formType) {
                    case 0:
                      errField = "password";
                      break;
                    case 1:
                    case 2:
                      errField = "email";
                      break;
                    case 3:
                      errField = "otp";
                      break;
                    default:
                      errField = "email";
                  }
                  setError(errField, { message: err });
                },
              });
            })}
            type="submit"
            className="flex w-full justify-center rounded-md  bg-accent bg-opacity-90 px-3 py-3 text-sm font-semibold leading-6 text-primary shadow-sm transition hover:bg-opacity-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent active:scale-90 disabled:cursor-wait disabled:opacity-70"
          >
            {isProcessing ? (
              <div className="aspect-1 h-6">
                <LoadingIcon color="black" />
              </div>
            ) : formType === 0 ? (
              "Sign In"
            ) : formType === 3 ? (
              "Submit"
            ) : (
              "Continue"
            )}
          </button>
          {formType === 3 && (
            <div className="flex flex-col text-left text-xxs text-gray-500">
              <div className=""> This site is protected by reCAPTCHA.</div>
              <div>
                {" "}
                Google
                <a className="text-accent" href="https://policies.google.com/privacy">
                  {" "}
                  Privacy Policy{" "}
                </a>
                and
                <a className="text-accent" href="https://policies.google.com/terms">
                  {" "}
                  Terms of Service{" "}
                </a>
                apply.
              </div>
            </div>
          )}

          {(formType === 0 || formType === 1) && (
            <>
              <h4 className="text-center text-xxs font-medium text-white">OR</h4>
              <button
                onClick={() => {
                  localStorage.setItem("redirectURI", window.location.search.split("redirectURI=")[1]);
                  setLoading(true, "Please Wait! Taking you to Google...");
                  get("/api/v1/social-media/social-auth/get-auth-code", {
                    processData: ({ data }) => {
                      window.location.assign(data.GoogleAuthCodeEndpoint);
                    },
                    processError: () => {
                      setLoading(false);
                      toast.error("Unexpected Error, Please try Again!");
                    },
                  });
                }}
                type="button"
                className="flex w-full items-center justify-center gap-2 rounded-md bg-white bg-opacity-90 p-2 font-medium text-primary shadow-sm transition hover:bg-opacity-100 active:scale-90"
              >
                <img src={GoogleIcon} alt="" className="h-8" />
                <div>Sign {formType === 0 ? "In" : "Up"} With Google</div>
              </button>
              <div className="flex flex-col text-left text-xxs text-gray-500">
                <div> This site is protected by reCAPTCHA.</div>
                <div>
                  {" "}
                  Google
                  <a className="text-accent" href="https://policies.google.com/privacy">
                    {" "}
                    Privacy Policy{" "}
                  </a>
                  and
                  <a className="text-accent" href="https://policies.google.com/terms">
                    {" "}
                    Terms of Service{" "}
                  </a>
                  apply.
                </div>
              </div>
            </>
          )}
        </div>
      </form>

      {formType !== 0 ? (
        <p className="mt-10 text-center text-sm text-gray-500">
          Already Registered?{" "}
          <button
            type="button"
            onClick={() => {
              reset();
              setFormType(0);
            }}
            className="font-semibold leading-6 text-accent text-opacity-90 hover:text-opacity-100 hover:underline"
          >
            Sign In!
          </button>
        </p>
      ) : (
        <p className="mt-6 text-center text-sm text-gray-500">
          Not a member?{" "}
          <button
            type="button"
            onClick={() => setFormType(1)}
            className="font-semibold leading-6 text-accent text-opacity-90 hover:text-opacity-100 hover:underline"
          >
            Register!
          </button>
        </p>
      )}
    </div>
  );
}

export default SignInForm;
