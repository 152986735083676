import { ClaimIcon, CloseIcon } from "components/atoms/Icon";
import Modal from "components/atoms/modal";
import Button from "components/atoms/buttons/Button";
import { pluralize } from "utils/HelperFunctions";
import MagnetImageContainer from "components/atoms/images/MagnetImageContainer";
import useCountdown from "components/hooks/useCountDown";
import moment from "moment";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

type Props = {
  claimData: ClaimCodeUserData;
  onClose: () => void;
  handleClaimButtonClick: () => void;
  disableClaimButton?: boolean;
};

function ClaimModal({ claimData, onClose, handleClaimButtonClick, disableClaimButton }: Props) {
  const { days, hours, minutes, seconds } = useCountdown(Number(claimData.end_time));
  const isCodeExpired = moment() > moment(claimData.end_time);

  const getRemainingPollTime = () => {
    if (days) return pluralize(days, "day");
    if (hours) return pluralize(hours, "hour");
    if (minutes) return pluralize(minutes, "min");
    if (seconds) return pluralize(seconds, "sec");
    return "0";
  };
  return (
    <Modal open onClose={onClose}>
      <div className="w-[calc(100vw-20px)] max-w-[600px] space-y-5 rounded-2xl bg-primary px-5 py-7 shadow-modal">
        {/* Section 1  */}
        <div className="flex justify-between">
          <div className="flex space-x-3">
            <img
              className="aspect-1 h-16 rounded-lg border border-white object-cover"
              src={claimData.magnet_image_url as string}
              alt={claimData.magnet_name}
            />
            <div className="flex flex-col font-satoshi text-white">
              <Link
                to={`/brand/${claimData.brand_id}/magnet/${claimData.magnet_id}`}
                className="text-xl font-bold leading-tight md:text-3xl"
              >
                {claimData.magnet_name}
              </Link>
              <Link to={`/brand-public-profile/${claimData.brand_id}`} className="font-semibold opacity-70">
                {claimData.brand_name}
              </Link>
            </div>
          </div>
          <div>
            <button type="button" onClick={onClose} className="cursor-pointer p-3">
              <CloseIcon className="h-4 w-4 text-white" />
            </button>
          </div>
        </div>
        {/* Section 2 */}
        <div className="mx-auto flex flex-col items-center font-satoshi text-white">
          <div className="relative h-40 w-40 rounded-xl border border-white md:h-72 md:w-72">
            <div
              className="absolute -right-5  -top-5 flex h-10 w-10 items-center justify-center
             rounded-full bg-accent"
            >
              <ClaimIcon className="h-6 w-6" />
            </div>
            <MagnetImageContainer image={claimData.thumbnail_url as string} altText={claimData.name} />
          </div>
          <div className="mt-4 flex justify-center text-white">
            <div
              className="rounded-ll bg-accent bg-opacity-20 p-2 font-satoshi
             text-sm font-semibold leading-5"
            >
              {isCodeExpired ? "Expired" : `Expiring in ${getRemainingPollTime()}`}
            </div>
          </div>
          <span className="mt-2 text-center font-satoshi text-2xl font-bold leading-tight text-white">
            {claimData.name}
          </span>
          <span className="secondary-scrollbar my-2 line-clamp-4 w-[90%] overflow-scroll overflow-x-hidden text-center font-satoshi text-xs font-450 text-white">
            {claimData.description}
          </span>
        </div>
        <div className="flex justify-center">
          <Button
            text="CLAIM"
            disabled={disableClaimButton || isCodeExpired}
            variant="magnetiqSolid"
            additionalClasses="xl:px-6 xl:py-3 xl:text-xs 2xl:text-sm 2xl:px-8 2xl:py-4"
            onClick={handleClaimButtonClick}
          />
        </div>
      </div>
      {disableClaimButton && (
        <div className="z-[100] mx-auto -mt-5 flex items-center justify-center gap-2 rounded-b-lg bg-accent p-2">
          <ExclamationTriangleIcon className="h-4 w-4 text-primary" />
          <p className="font-sora text-xxs font-semibold text-primary">Acquire the Magnet to claim this Memento</p>
        </div>
      )}
    </Modal>
  );
}

export default ClaimModal;
