// @ts-ignore
import * as fcl from "@onflow/fcl";
import { GET_NOUNCE } from "./constants";
import { get } from "./utils/request";

export default async function initFCL(useResolver: boolean = true) {
  const resolver = async () => {
    const response = await get(GET_NOUNCE);
    if (!response) {
      alert("Failed to get nonuce from BE.");
      return null;
    }
    return response;
  };

  fcl.config({
    "fcl.accountProof.resolver": useResolver ? resolver : undefined,
    // @ts-ignore
    "discovery.wallet": process.env.REACT_APP_WALLET_DISCOVERY,
    "discovery.wallet.method": process.env.REACT_APP_DAPPER_WALLET_DISCOVERY_METHOD,
    // Used to derive email,username from dapper.
    "service.OpenID.scopes": "email preferred_username",
    // @ts-ignore
    "accessNode.api": process.env.REACT_APP_FLOW_ACCESS_NODE,
    "app.detail.title": "Magnetiq",
    "app.detail.icon": "https://magnetiq-static.s3.amazonaws.com/media/public/Achievement_10Magnets_Owned_kCSc0sz.jpg",
  });
}
