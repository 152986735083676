import moment from "moment";

export const queryStringParser = (query: string) => {
  const searchParams = new URLSearchParams(query);
  const searchParamsEntries = [...(searchParams.entries() as any)];
  const queryStringParsedObj = searchParamsEntries.reduce((prevObject, currentEntry) => {
    // eslint-disable-next-line prefer-destructuring, no-param-reassign
    prevObject[currentEntry[0]] = currentEntry[1];
    return prevObject;
  }, {});
  return queryStringParsedObj || {};
};

export const getTzOffsetFromUTC = () => moment().utcOffset() * 60 * 1000;
/**
 *
 * @param {number} timestamp
 */
export const utcToLocalDate = (timestamp: number) => timestamp - getTzOffsetFromUTC();

export const downloadOnClick = (url: string, CustomFileName?: string) => {
  const fileName = url.split("/").pop() || "file";
  const anchor = document.createElement("a");
  anchor.href = url;

  anchor.download = CustomFileName ? `${CustomFileName}.csv` : fileName;
  anchor.target = "_blank";
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};
