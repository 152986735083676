import { useQuery } from "@tanstack/react-query";
import { getAllNotificationsURL, markAllNotificationsAsRead, markNotificationAsRead } from "apis/notification";
import { useBrandContext } from "components/context/BrandContext";
import { useUserContext } from "components/context/UserContext";
import { useRouter } from "components/hooks";
import toast from "react-hot-toast";
import { get, post } from "utils/request";
import { handleRedirectOrPopupActionForNotification } from "utils/HelperFunctions";
import { ROUTES } from "../../../../../constants";
import NotificationPopover from "./NotificationPopover";

type Props = {
  open: boolean;
  notificationsFromFirebase: any[];
  unreadNotificationCount: number | undefined;
  refetchUnreadNotificationCount: () => void;
  closePopOver: () => void;
  setNotificationsFromBackend: any;
};

function NotificationDropdown({
  setNotificationsFromBackend,
  open,
  unreadNotificationCount,
  refetchUnreadNotificationCount,
  closePopOver,
  notificationsFromFirebase,
}: Props) {
  const handleGetAllNotificationsError = () => {
    console.error("Failed to load new notifications");
    // toast.error("Failed to load new notifications");
  };

  const { revalidateBrandDetails } = useBrandContext();
  const { reloadUserData, setCurrentlyViewingMementoId } = useUserContext();

  const router = useRouter();

  const {
    isLoading,
    data: allNotificationsData,
    error,
    refetch: refetchAllNotificationsData,
  } = useQuery({
    onSuccess: (data) => data?.results?.length > 0 && setNotificationsFromBackend(data.results),
    // refetchOnMount: false,
    queryKey: ["notifications", handleGetAllNotificationsError],
    queryFn: () =>
      get(getAllNotificationsURL(), {
        queryParams: {
          page_size: "100",
        },
        processError: handleGetAllNotificationsError,
      }),
  });

  const handleMarkAllNotificationAsReadSuccess = () => {
    refetchAllNotificationsData();
    refetchUnreadNotificationCount();
  };

  const handleMarkAllNotificationAsReadError = (errorMessage: string) => {
    toast.error(errorMessage);
  };

  const handleMarkAllNotificationsAsRead = async () => {
    closePopOver();
    await post(
      markAllNotificationsAsRead(),
      {},
      {
        processData: handleMarkAllNotificationAsReadSuccess,
        processError: handleMarkAllNotificationAsReadError,
      },
    );
  };

  const handleMarkAsReadSuccess = () => {
    refetchAllNotificationsData();
    refetchUnreadNotificationCount();
  };

  const handleMarkNotificationAsReadAndNavigateToCta = async (notification: NotificationData) => {
    closePopOver();
    post(
      markNotificationAsRead(notification.id),
      {},
      {
        processData: () => {
          // TODO: This needs to be refactored.
          const isBrandReloadNotification = notification.cta?.includes("reloadBrand");
          const isUserReloadNotification = notification.cta?.includes("reloadUser");
          if (isBrandReloadNotification) {
            revalidateBrandDetails();
          }
          if (isUserReloadNotification) {
            reloadUserData();
          }
          handleMarkAsReadSuccess();
        },
      },
    );
    const navigationLink = handleRedirectOrPopupActionForNotification(notification, setCurrentlyViewingMementoId);
    if (navigationLink) router.go(navigationLink);
  };

  const handleNavigateToNotifcationPage = () => {
    router.go(ROUTES.NOTIFICATION);
    closePopOver();
  };

  if (!open) return null;

  return (
    <NotificationPopover
      isLoading={isLoading}
      error={!!error}
      unreadNotificationsCount={unreadNotificationCount}
      notifications={[...notificationsFromFirebase, ...(allNotificationsData?.results || [])]}
      handleMarkAllAsReadClick={handleMarkAllNotificationsAsRead}
      handleMarkNotificationAsReadAndNavigateToCta={handleMarkNotificationAsReadAndNavigateToCta}
      handleNavigateToNotifcationPage={handleNavigateToNotifcationPage}
    />
  );
}

export default NotificationDropdown;
