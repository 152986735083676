/* eslint-disable import/no-extraneous-dependencies */
// import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);

export default function StripeContext({ children }: { children: any }) {
  //   const [clientSecret, setClientSecret] = useState("");

  //   useEffect(() => {
  //     // Create PaymentIntent as soon as the page loads
  //     fetch("/create-payment-intent", {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
  //     })
  //       .then((res) => res.json())
  //       .then((data) => setClientSecret(data.clientSecret));
  //   }, []);

  //   const appearance = {
  //     theme: "stripe",
  //   };
  //   const options = {
  //     appearance,
  //   };

  return <Elements stripe={stripePromise}>{children}</Elements>;
}
