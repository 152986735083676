import { ACTIVATIONS_ROUTE } from "../constants";

export const getCreateClaimCodeEndpoint = (magnetId: string) =>
  `${ACTIVATIONS_ROUTE}magnet/${magnetId}/drop/claim_code/`;

export const getUpdateClaimCodeRoute = (magnetId: string, claimCodeId: string) =>
  `${ACTIVATIONS_ROUTE}magnet/${magnetId}/drop/claim_code/${claimCodeId}/`;

export const getClaimCodeRoute = (magnetId: string, claimCodeId: string) =>
  `${getUpdateClaimCodeRoute(magnetId, claimCodeId)}claim/`;

export const getEndClaimCodeEndPoint = (magnetId: string, claimCodeId: string) =>
  `${getUpdateClaimCodeRoute(magnetId, claimCodeId)}end/`;
