import moment from "moment";
import { useEffect, useState } from "react";

const getReturnValues = (countDown: number) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));

  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds, saleTimePastCurrentTime: false };
};

const useCountdown = (utcTimestamp: number) => {
  const countDownDate = utcTimestamp;

  const [countDown, setCountDown] = useState(utcTimestamp - moment().valueOf());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(utcTimestamp - moment().valueOf());
    }, 1000);
    return () => clearInterval(interval);
  }, [countDownDate]);

  const isCurrentTimePastTheSaleTime = moment(moment(countDownDate)).isBefore(moment());

  if (isCurrentTimePastTheSaleTime) return { days: 0, hours: 0, minutes: 0, seconds: 0, saleTimePastCurrentTime: true };

  return getReturnValues(countDown);
};

export default useCountdown;
