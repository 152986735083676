import { MAGNET_ACTIVATIONS } from "../constants";

export const getCreateShareRequestEndpoint = (magnetId: string) =>
  `${MAGNET_ACTIVATIONS}${magnetId}/response/social-share/`;

export const getUpdateShareRequestRoute = (magnetId: string, shareRequestId: string) =>
  `${MAGNET_ACTIVATIONS}${magnetId}/response/social-share/${shareRequestId}/`;

export const getShareRequestRoute = (magnetId: string, shareRequestId: string) =>
  `${getUpdateShareRequestRoute(magnetId, shareRequestId)}claim/`;

export const getEndShareRequestEndPoint = (magnetId: string, shareRequestId: string) =>
  `${getUpdateShareRequestRoute(magnetId, shareRequestId)}end/`;

export const getRespondShareRequestEndPoint = (magnetId: string, shareRequestId: string) =>
  `${getUpdateShareRequestRoute(magnetId, shareRequestId)}respond/`;
