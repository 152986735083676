import { MagnetiqLogo } from "components/atoms/Icon";
import DesktopSidebarImageCard from "components/atoms/sidebar/DesktopSidebarImageCard";
import { MouseEvent, MouseEventHandler, useState } from "react";
import { handleCreateMagnetAfterModal } from "utils/HelperFunctions";
import {
  BuildingLibraryIcon,
  ChartBarSquareIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import clsx from "clsx";
import CreateMagnetModal from "pages/BrandDashboard/CreateMagnetModal";
import { ROUTES } from "../../../constants";
import DesktopSidebarStaticNavButton from "../../atoms/sidebar/DesktopSidebarStaticButton";

import { CreateMagnetIcon, MagnetIcon, SettingsIcons } from "../../atoms/Icon";
import ResusableSidebar from "../../atoms/forms/Sidebar";
import { useRouter } from "../../hooks";

function BrandSidebar({ magnets }: { magnets: Magnet[] }) {
  const [isMagnetMenuExpanded, setIsMagnetMenuExpanded] = useState(true);
  const [isAnalyticsMenuExpanded, setIAnalyticsMenuExpanded] = useState(true);
  const [isCreateMagnetModalOpen, setIsCreateMagnetModalOpen] = useState(false);
  const router = useRouter();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { m: magnetIdFromUrl } = router.query;

  const { pathname } = router;

  const analyticsSection = pathname.includes(`${ROUTES.BRAND_DASHBOARD}/analytics/`)
    ? pathname.split(`${ROUTES.BRAND_DASHBOARD}/analytics/`)[1]
    : undefined;

  // Navigate to Magnet Page
  const handleMagnetClick = (magnetIdForNavigation: string) => {
    // if magnetId is present than only url will we replaced
    router.go(`${ROUTES.BRAND_DASHBOARD}?m=${magnetIdForNavigation}`, { replace: !!magnetIdFromUrl });
  };

  // Navigate to Brand Analytics
  const navigateToBrandAnalytics = () => {
    // if magnetId is present than only url will we replaced
    router.go(`${ROUTES.BRAND_DASHBOARD}/analytics/brand`);
  };

  // Navigate to Magnet Analytics
  const navigateToMagnetAnalytics = () => {
    // if magnetId is present than only url will we replaced
    router.go(`${ROUTES.BRAND_DASHBOARD}/analytics/magnet`);
  };

  // Navigate to Audience Analytics
  const navigateToAudienceAnalytics = () => {
    // if magnetId is present than only url will we replaced
    router.go(`${ROUTES.BRAND_DASHBOARD}/analytics/audience`);
  };

  // Navigate to Account Settings Page and checks if already on that page
  const handleAccountSettingClick = () => {
    if (pathname !== ROUTES.BRAND_SETTINGS_ROUTE) router.go(ROUTES.BRAND_SETTINGS_ROUTE);
  };

  // Navigate to All Magnets Page and Checks if already on that page
  const handleAllMagnetsClick = () => {
    if (magnetIdFromUrl || pathname !== ROUTES.BRAND_DASHBOARD) router.go(ROUTES.BRAND_DASHBOARD);
  };

  const handleCreateMagnetButtonClick = (e: MouseEvent) => {
    e.stopPropagation();
    setIsCreateMagnetModalOpen(true);
  };

  const handleMagnetiqLogoClick = () => {
    router.go(ROUTES.BRANDS_DISCOVER);
  };

  const collapseMagnetMenu: MouseEventHandler = (e) => {
    e.stopPropagation();
    setIsMagnetMenuExpanded(false);
  };

  const expandMagnetMenu: MouseEventHandler = (e) => {
    e.stopPropagation();
    setIsMagnetMenuExpanded(true);
  };

  const collapseAnalyticsMenu: MouseEventHandler = (e) => {
    e.stopPropagation();
    setIAnalyticsMenuExpanded(false);
  };

  const expandAnalyticsMenu: MouseEventHandler = (e) => {
    e.stopPropagation();
    setIAnalyticsMenuExpanded(true);
  };

  return (
    <div>
      <CreateMagnetModal
        handleCreateMagnet={handleCreateMagnetAfterModal}
        open={isCreateMagnetModalOpen}
        onClose={() => setIsCreateMagnetModalOpen(false)}
      />
      <ResusableSidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        topStickyItems={
          <div className="mb-2 mt-3 2xl:mt-4">
            <div className="my-7 flex items-start justify-start pl-5">
              <button type="button" onClick={handleMagnetiqLogoClick}>
                <MagnetiqLogo className="w-40 2xl:w-48" />
              </button>
            </div>
          </div>
        }
        desktopNavItems={
          <>
            <DesktopSidebarStaticNavButton
              isActive={!magnetIdFromUrl && pathname === ROUTES.BRAND_DASHBOARD}
              label={
                <div className="flex items-center justify-between pr-4">
                  <p>All Magnets</p>
                  <div className="flex">
                    <span
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => {}}
                      className="mr-1 transition-all hover:scale-110"
                      title="Create New Magnet"
                      onClick={handleCreateMagnetButtonClick}
                      aria-label="Create New Magnet"
                    >
                      <CreateMagnetIcon className="h-5 w-5 text-secondary" />
                    </span>
                    {isMagnetMenuExpanded ? (
                      <span
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {}}
                        title=""
                        onClick={collapseMagnetMenu}
                        aria-label="Create New Magnet"
                      >
                        <ChevronDownIcon className="h-6 w-6 text-secondary" />
                      </span>
                    ) : (
                      <span
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {}}
                        title=""
                        onClick={expandMagnetMenu}
                        aria-label="Create New Magnet"
                      >
                        <ChevronRightIcon className="h-6 w-6 text-secondary" />
                      </span>
                    )}
                  </div>
                </div>
              }
              Icon={<MagnetIcon className="h-7 w-7 rounded-lg bg-secondary 2xl:h-9 2xl:w-9" />}
              handleClick={handleAllMagnetsClick}
            />
            <div
              className={clsx(
                "flex max-h-72 flex-col overflow-scroll overflow-y-scroll bg-[#1a1a1a] pl-3 scrollbar-hide",
                isMagnetMenuExpanded ? "h-auto" : "h-0",
              )}
            >
              <DesktopSidebarStaticNavButton
                label="Create New Magnet"
                Icon={<CreateMagnetIcon className="m-0 h-6 w-6 text-secondary 2xl:h-8 2xl:w-8" />}
                handleClick={handleCreateMagnetButtonClick}
              />
              {magnets?.map((magnet) => (
                <DesktopSidebarImageCard
                  isActive={magnetIdFromUrl === magnet.id}
                  key={`sidebar_mag_card${magnet.id}`}
                  name={magnet.name}
                  image_url={magnet.image_url}
                  handleCardClick={() => {
                    handleMagnetClick(magnet.id);
                  }}
                  imageAdditionalClasses="border border-white"
                />
              ))}
            </div>
            <DesktopSidebarStaticNavButton
              label={
                <div className="flex items-center justify-between pr-4">
                  <p>Analytics</p>
                  <div className="flex">
                    {isAnalyticsMenuExpanded ? (
                      <span
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {}}
                        title=""
                        onClick={collapseAnalyticsMenu}
                        aria-label="Create New Magnet"
                      >
                        <ChevronDownIcon className="h-6 w-6 text-secondary" />
                      </span>
                    ) : (
                      <span
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {}}
                        title=""
                        onClick={expandAnalyticsMenu}
                        aria-label="Create New Magnet"
                      >
                        <ChevronRightIcon className="h-6 w-6 text-secondary" />
                      </span>
                    )}
                  </div>
                </div>
              }
              Icon={<ChartBarSquareIcon className="h-auto w-7 rounded-l text-secondary 2xl:w-11" />}
              handleClick={isAnalyticsMenuExpanded ? collapseAnalyticsMenu : expandAnalyticsMenu}
            />
            <div
              className={clsx(
                "flex max-h-72 flex-col overflow-scroll overflow-y-scroll bg-[#1a1a1a] pl-3 scrollbar-hide",
                isAnalyticsMenuExpanded ? "h-auto" : "h-0",
              )}
            >
              <DesktopSidebarStaticNavButton
                isActive={analyticsSection === "audience"}
                label="Audience"
                Icon={<UserGroupIcon className="h-auto w-7 rounded-l fill-secondary 2xl:w-7" />}
                handleClick={navigateToAudienceAnalytics}
              />
              <DesktopSidebarStaticNavButton
                isActive={analyticsSection === "brand"}
                label="Brand"
                Icon={<BuildingLibraryIcon className="h-auto w-7 rounded-l fill-secondary 2xl:w-7" />}
                handleClick={navigateToBrandAnalytics}
              />
              <DesktopSidebarStaticNavButton
                isActive={analyticsSection === "magnet"}
                label="Magnet"
                Icon={<MagnetIcon className="h-auto w-7 rounded-l text-secondary 2xl:w-7" />}
                handleClick={navigateToMagnetAnalytics}
              />
            </div>
          </>
        }
        bottomStickyItems={
          <div>
            <hr className="my-1 h-0.5 border-light-brown 2xl:my-2" />
            <DesktopSidebarStaticNavButton
              isActive={pathname === ROUTES.BRAND_SETTINGS_ROUTE}
              label="Account Settings"
              Icon={<SettingsIcons className="h-7  w-7  text-secondary 2xl:h-9 2xl:w-9" />}
              handleClick={handleAccountSettingClick}
            />
          </div>
        }
        additionalClasses="md:w-64 2xl:w-80"
      />
    </div>
  );
}

export default BrandSidebar;
