import { Popover } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { getAllUnreadNotificationsCount } from "apis/notification";
import OverFlowManager from "components/atoms/OverflowManager";
import useNotificationStore from "globalstore/notificationStore";
import toast from "react-hot-toast";
import { get } from "utils/request";

import NotificationButton from "./NotificationButton";
import NotificationDropdown from "./notificationDropdown";

function NotificationDropDownSection() {
  const handleGetAllUnreadNotificationCountError = (errorMessage: String) => {
    // @ts-ignore
    toast.error(errorMessage);
  };

  const { notifications: notificationsFromFirebase, setNotificationsFromBackend } = useNotificationStore();

  const {
    data: unreadNotificationData,
    error,
    refetch: refetchUnreadNotificationCount,
    isRefetching,
  } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ["unread-notifications-count"],
    queryFn: () =>
      get(getAllUnreadNotificationsCount(), {
        processError: handleGetAllUnreadNotificationCountError,
      }),
  });

  if (error) {
    toast.error("Failed to load unread notification count");
  }

  const unReadFirebaseNotificationCount = notificationsFromFirebase.reduce(
    (acc, currentValue) => acc + (currentValue.is_read ? 0 : 1),
    0,
  );

  return (
    <Popover>
      {({ open, close }) => (
        <>
          <NotificationButton
            unreadNotificationCount={
              isRefetching ? ".." : unReadFirebaseNotificationCount + (unreadNotificationData?.unread_count || 0)
            }
          />
          <OverFlowManager open={open} />

          <NotificationDropdown
            open={open}
            setNotificationsFromBackend={setNotificationsFromBackend}
            notificationsFromFirebase={notificationsFromFirebase}
            unreadNotificationCount={(unreadNotificationData?.unread_count || 0) + unReadFirebaseNotificationCount}
            refetchUnreadNotificationCount={refetchUnreadNotificationCount}
            closePopOver={close}
          />
        </>
      )}
    </Popover>
  );
}

export default NotificationDropDownSection;
