import { MAGNET_ACTIVATIONS } from "../constants";

export const getCreatePoll = (magnetId: string) => `${MAGNET_ACTIVATIONS}${magnetId}/response/poll/`;
export const getPollEndpoint = (magnetId: string, pollId: string) =>
  `${MAGNET_ACTIVATIONS}${magnetId}/response/poll/${pollId}/`;
export const publishPoll = (magnetId: string, pollId: string) => `${MAGNET_ACTIVATIONS}${magnetId}/publish/${pollId}/`;
export const endPoll = (magnetId: string, pollId: string) =>
  `${MAGNET_ACTIVATIONS}${magnetId}/response/poll/${pollId}/end/`;
export const postPollResponse = (magnetId: string, pollId: string) =>
  `${MAGNET_ACTIVATIONS}${magnetId}/response/poll/${pollId}/poll-response/`;
