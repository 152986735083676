/* eslint-disable max-len */
import {
  getCreateClaimCodeRoute,
  getCreatePollRoute,
  getEditClaimCodeRoute,
  getEditPollRoute,
  getCreateMerchDropRoute,
  getEditMerchDropRoute,
  getEditShareRequestRoute,
  getCreateShareRequestRoute,
  getEditMementoRoute,
  getCreateMementoRoute,
} from "utils/helpers";

import FlowLogo from "assets/Logos/FlowLogo.png";
import NolchaLogo from "assets/Logos/NolchaLogo.png";
import QueercandleCo from "assets/Logos/QueerCandleCo.png";
import Hcode from "assets/Logos/Hcode.svg";
import CoinCoffee from "assets/Logos/CoinCoffee.png";
import BlockChainLogo from "assets/Logos/BlockChain.png";

// export const API_BASE_URL = "https://magnetiq.hcode.tech";
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const BRAND_ROUTE = "/api/v1/brand/";
export const UPLOAD_TO_S3 = "/utilities/api/v1/upload/";
export const GET_STATES_BY_COUNTRY = "/api/v1/get-provinces";
export const GET_COUNTRY = "/api/v1/get-provinces/countries";
export const MAGNET = "/magnet/";
export const ALL_MAGNET_NAME = "/all-magnets-name";
export const RELEASE = "/release";
export const PUBLISH = "/publish/";
export const BRAND_MAGNET = "/api/v1/brand/magnet/";
export const USER_SELF = "/api/v1/user/self/";
export const GET_NOUNCE = "/api/v1/user/get-nonce";
export const VERIFY_SIGNATURE = "/api/v1/user/verify-signature";
export const GET_ALL_MAGNETS = "/api/v1/magnet/";
export const MAGNET_ROUTE = "/api/v1/magnet/";
export const USER_ROUTE = "/api/v1/user/";
export const ACTIVATIONS_ROUTE = "/api/v1/activations/";
export const MEMENTO_ROUTE = "/api/v1/memento/";
export const GET_USER = `${USER_ROUTE}self/`;
export const UPDATE_FEATURED_MAGNETS = "/api/v1/user/inventory/";
export const GET_ALL_NOTIFICATIONS = "/api/v1/user/notifications/";
export const MARK_NOTIFICATIONS_AS_READ = "/api/v1/user/notifications/read/";
export const GET_ALL_UNREAD_NOTIFICATION_COUNT = "/api/v1/user/notifications/unread-count";
export const SUBSCRIBE_TO_NOTIFICATIONS = "/api/v1/user/notifications/subscribe/";
export const MAGNET_ACTIVATIONS = "/api/v1/activations/magnet/";
export const MAGNET_ACTIVITY = "/api/v1/activity/magnet/";
export const USER_ACTIVITY = "/api/v1/activity/user/";
export const USER_INCENTIVES = "/api/v1/user/incentive/";
export const MAGNETIQ_PROGRAM_ROUTE = "/api/v1/magnetiq-program/";
export const USER_INVENTORY = "/api/v1/user/inventory";

export const ALLOW_UNAPPROVED_BRANDS = process.env.REACT_APP_ALLOW_UNAPPROVED_BRANDS;
export const DAPPER_WALLET_PAGE =
  process.env.REACT_APP_ENVIRONMENT === "development"
    ? "https://staging.accounts.meetdapper.com/"
    : "https://accounts.meetdapper.com/";

export const GOOGLE_RECAPTCHA_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || "";

export const REDIRECT_TO_TWITTER =
  process.env.REACT_APP_ENVIRONMENT === "development"
    ? "https://twitter.com/intent/tweet?text=Hey, check out my cool collection on MAGNETIQ: &url=http%3A//app.magnetiq.xyz/user-profile/"
    : "https://twitter.com/intent/tweet?text=Hey, check out my cool collection on MAGNETIQ: &url=http%3A//app.magnetiq.xyz/user-profile/";

export const REDIRECT_TO_LINKEDIN =
  process.env.REACT_APP_ENVIRONMENT === "development"
    ? "https://www.linkedin.com/shareArticle?mini=true&url=http%3A//app.magnetiq.xyz/user-profile/"
    : "https://www.linkedin.com/shareArticle?mini=true&url=http%3A//app.magnetiq.xyz/user-profile/";

export const MAX_MAGNET_BENEFIT_LENGTH = 300;

export const getMagnetDetailsEndpoint = (bId: string, mId: string) => BRAND_ROUTE + bId + MAGNET + mId;

// eslint-disable-next-line max-len, operator-linebreak
export const URL_VALIDATOR =
  // eslint-disable-next-line max-len, no-useless-escape
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

export const LOCAL_STORAGE_KEYS = {
  USER_TOKEN: "token",
  SKIP_ONBOARDING: "skipOnboarding",
  DISCOVER_PAGE_LAYOUT: "discoverPageLayout",
} as const;

export const ROUTES = {
  CREATE_PUBLIC_MAGNET_ROUTE: "/create-public-magnet",
  CREATE_CONTROLLED_MAGNET_ROUTE: "/create-controlled-magnet",
  CREATE_SUBSCRIPTION_MAGNET_ROUTE: "/create-subscription-magnet",
  EDIT_MAGNET: "/edit-magnet",
  AIRDROP_MEMENTO: getCreateMementoRoute(":brandId", ":magnetId"),
  EDIT_AIRDROP_MEMENTO: getEditMementoRoute(":brandId", ":magnetId", ":mementoId"),
  BRAND_SETTINGS_ROUTE: "/brand-settings",
  BRAND_DASHBOARD: "/brand-dashboard",
  BRAND_ANALYTICS: "/brand-dashboard/analytics/:analyticsSection",
  BRAND_ONBOARDING: "/brand-onboard",
  BRAND_PUBLIC_PROFILE: "/brand-public-profile",
  BRANDS_DISCOVER: "/",
  BRANDS_SIGNUP_SUCCESS: "/brand-signup-success",
  BRAND_TERMS_OF_SERVICE: "/terms-of-service",
  BRAND_HELP: "/help",
  COMING_SOON: "/coming-soon",
  PRIVACY_POLICY: "/privacy-policy",
  USER_PROFILE_PAGE: "/account",
  TERMS_AND_CONDITIONS: "/terms-of-service",
  PROFILE_SETTING: "/profile-setting",
  MAGNET_PAGE: "/brand/:brandId/magnet/:magnetId",
  USER_PUBLIC_PROFILE: "/user-profile",
  NOT_FOUND: "404",
  SIGNIN: "/signin",
  CREATE_USER_ACCOUNT: "/create-user-account",
  NOTIFICATION: "/notification",
  CREATE_POLL: getCreatePollRoute(":brandId", ":magnetId"),
  EDIT_POLL: getEditPollRoute(":brandId", ":magnetId", ":pollId"),
  MERCH_DROP: getCreateMerchDropRoute(":brandId", ":magnetId"),
  EDIT_MERCH_DROP: getEditMerchDropRoute(":brandId", ":magnetId", ":merchDropId"),
  CREATE_CLAIM_CODE: getCreateClaimCodeRoute({ brandId: ":brandId", magnetId: ":magnetId" }),
  EDIT_CLAIM_CODE: getEditClaimCodeRoute({ brandId: ":brandId", magnetId: ":magnetId", claimId: ":claimId" }),
  CREATE_SHARE_REQUEST: getCreateShareRequestRoute({ brandId: ":brandId", magnetId: ":magnetId" }),
  EDIT_SHARE_REQUEST: getEditShareRequestRoute({
    brandId: ":brandId",
    magnetId: ":magnetId",
    shareRequestId: ":shareRequestId",
  }),
  CLAIM_MAGNET: "/magnet/:magnetId/claim/:eventId?",
  TWITTER_AUTHORIZATION: "/twitter/:status",
  GOOGLE_SIGNIN: "/google/signin",
};

export const MAGNETIQ_LINKS = {
  TWITTER_LINK: "https://twitter.com/magnetiq_xyz",
  INSTAGRAM_LINK: "https://www.instagram.com/magnetiq_xyz/",
  TERMS_OF_SERVICE_LINK: "https://www.magnetiq.xyz/terms-of-service",
  PRIVACY_POLICY_LINK: "https://www.magnetiq.xyz/privacy",
};

export const sideBarWidth = "300";

export const TOAST_CONFIG = Object({
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  progress: undefined,
});

export const maxNoOfFeaturedMagnets = 12;

export const READABLE_DATE_FORMAT = "MMM DD, YYYY";
export const DATE_FORMAT = "MM/DD/YY";
export const TIME_FORMAT = "hh:mm A";
export const DATE_TIME_PICKER_FORMAT = "MMMM DD YY hh:mm A";

export const PARTNERS_LOGOS = [
  {
    id: 1,
    src: NolchaLogo,
    alt: "Fashion, Runway, New York, NY, New York Fashion, New York Fashion Week, NYFW, NFW, Nolcha, Nolcha Fashion Week, Dress, Celebs, Celebrities, Clothing, Independent, Fashion Runway, Independent Fashion",
    // class: "h-24",
  },
  {
    id: 2,
    src: QueercandleCo,
    alt: "Queer candle Co",
    // class: "h-24",
  },
  {
    id: 3,
    src: CoinCoffee,
    alt: "CoinCoffee a web3 coffee brand that makes premium coffee more accessible, customizable, and inspirational for coffee enthusiasts & brands.",
    // class: "h-24",
  },

  {
    id: 4,
    src: FlowLogo,
    alt: "A permissionless layer 1 blockchain, empowering developers to create limitless Web3 apps for mainstream adoption ",
    // class: "h-24",
  },
  {
    id: 5,
    src: BlockChainLogo,
    alt: "BlockChain Technology",
    class: "h-24",
  },
  {
    id: 6,
    src: Hcode,
    alt: "Hcode Technologies, Custom Software Development Services, Web Development Company, Mobile App Development Company, MERN Stack Development Services, React Development Services, Python Development Services, Java Development Services, Agile Software Development, Full-stack Development Services, UX/UI Design Services, Blockchain Development Services, Technology Consulting Services, Best Practices in Software Development",
    class: "h-24",
  },
];

export const SIGN_IN_SIDEBAR_TEXT = [
  {
    id: 1,
    line: "With MAGNETIQ, you can easily access premium digital memberships from your favorite brands that provide unique and valuable benefits.",
  },
  {
    id: 2,
    line: "MAGNETIQ offers payment options such as credit card and debit card to make the purchasing process easy and secure.",
  },
  {
    id: 3,
    line: "With MAGNETIQ, you can create a personalised profile and showcase your purchased digital assets to other users, as well as explore and discover new brands and memberships.",
  },
];

export const activationKeyUserDescriptionMap = {
  poll: "Poll",
  mem_drop: "Airdrop",
  merch_drop: "Merch Drop",
  claim_code: "Claim Code",
  social_share: "Share Request",
};

export const MAGNET_RELEASE_STATUS = {
  DRAFT: 0,
  SCHEDULING: 1,
  SCHEDULED: 2,
  ON_SALE: 3,
  SALE_ENDED: 4,
  DELETED: 5,
};

export const GLOBAL_LOADER_TEXT = {
  UPLOADING_MEDIA: "Please wait! Uploading media.",
  PUBLISHING_MEMENTO: "Please wait! Publishing Memento.",
  UPDATING_MEMENTO: "Please wait! Updating Memento details.",
};

export const MAX_FILE_UPLOAD_SIZE: number = 512; // in Megabytes
export const MAX_IMAGE_UPLOAD_SIZE: number = 10; // in Megabytes

// export const SPECIAL_RENDRING_BRAND_IDS = JSON.parse(process.env.REACT_APP_THEMED_BRAND_IDS || "[]");
export const SPECIAL_RENDRING_BRAND_IDS =
  process.env.REACT_APP_ENVIRONMENT === "development"
    ? JSON.parse('["FuH3yejHgC3UyDHNsJjgkf","2ncxeayoRR932oKVDqFiYj","EJFLoFY23ry36VEjre2FPV"]')
    : JSON.parse('["ir9CYdKgSAdvXMSUEcPSD2"]');

export const optionsForEffectiveDay = [
  { label: "1st", value: "1" },
  { label: "2nd", value: "2" },
  { label: "3rd", value: "3" },
  { label: "4th", value: "4" },
  { label: "5th", value: "5" },
  { label: "6th", value: "6" },
  { label: "7th", value: "7" },
  { label: "8th", value: "8" },
  { label: "9th", value: "9" },
  { label: "10th", value: "10" },
  { label: "11th", value: "11" },
  { label: "12th", value: "12" },
  { label: "13th", value: "13" },
  { label: "14th", value: "14" },
  { label: "15th", value: "15" },
  { label: "16th", value: "16" },
  { label: "17th", value: "17" },
  { label: "18th", value: "18" },
  { label: "19th", value: "19" },
  { label: "20th", value: "20" },
  { label: "21st", value: "21" },
  { label: "22nd", value: "22" },
  { label: "23rd", value: "23" },
  { label: "24th", value: "24" },
  { label: "25th", value: "25" },
  { label: "26th", value: "26" },
  { label: "27th", value: "27" },
  { label: "28th", value: "28" },
];
