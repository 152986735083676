import clsx from "clsx";
import { formatTimeStampToDateAndTime } from "utils/HelperFunctions";

type Props = {
  image: string;
  title: string;
  description: string;
  createdAt: string;
  isRead: boolean;
  additionalClasses?: string;
  handleMarkNotificationAsReadAndNavigateToCta: () => void;
};

function NotificationCard({
  image,
  description,
  title,
  additionalClasses,
  createdAt,
  isRead,
  handleMarkNotificationAsReadAndNavigateToCta,
}: Props) {
  return (
    <button
      type="button"
      onClick={handleMarkNotificationAsReadAndNavigateToCta}
      className={clsx(
        "relative flex items-start rounded-ll border-1 border-neutral-900 text-start",
        "border-cyprus cursor-pointer border-opacity-10 bg-white p-2 transition-opacity hover:bg-opacity-25 2xl:p-3",
        additionalClasses,
        isRead ? "bg-opacity-5" : "bg-opacity-20",
      )}
    >
      <img
        className="mr-2 aspect-1 h-14 rounded-lg border-1 border-neutral-500 object-cover 2xl:mr-4 2xl:h-16 2xl:w-16"
        src={image}
        alt={title}
      />
      <div className="my-auto">
        <p
          className="line-clamp-2 font-satoshi text-xs  font-semibold leading-5 text-white
         xl:mb-0 2xl:mb-1 2xl:text-sm"
        >
          {title}
        </p>
        {/* Html will be recieved for the heading */}
        <p
          className="font-satoshi text-xxs font-450 leading-5 text-white xl:mb-0 2xl:mb-1 2xl:text-sm"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <div className="font-satoshi text-xxs font-450 leading-5 text-white text-opacity-50 2xl:text-xs">
          {formatTimeStampToDateAndTime(createdAt)}
        </div>
        {!isRead && <div className="absolute right-2  top-2 h-2 w-2 origin-center rounded-full bg-accent" />}
      </div>
    </button>
  );
}
export default NotificationCard;
