import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { clsx } from "clsx";
import { useDeviceType } from "components/hooks";

export default function ResusableSidebar({
  desktopNavItems,
  bottomStickyItems,
  topStickyItems,
  additionalClasses,
  sidebarOpen,
  setSidebarOpen,
  isSidebarCustomized = false,
}: {
  desktopNavItems: JSX.Element | null;
  bottomStickyItems?: JSX.Element | null;
  topStickyItems?: JSX.Element;
  additionalClasses?: string;
  sidebarOpen: boolean;
  setSidebarOpen: (a: boolean) => void;
  isSidebarCustomized?: boolean;
}) {
  const { isMobile } = useDeviceType(767);

  return (
    <>
      {/* Mobile Sidebar */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className={clsx("relative z-40 flex h-[calc(100vh-4rem)] md:hidden", isSidebarCustomized ? "hidden" : "")}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div />
            {/* <div className="fixed inset-0 bg-gray-600 bg-opacity-75" /> */}
          </Transition.Child>

          <div className=" inset-0 top-[66px] z-40 flex grow">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full flex-1  grow flex-col">
                {isMobile && (
                  <div
                    className={clsx(
                      "flex flex-grow flex-col justify-between overflow-y-auto px-0",
                      isSidebarCustomized ? "bg-[#2a5070]" : "bg-black",
                    )}
                  >
                    <div className="overflow-y-scroll p-2">
                      <div>{topStickyItems}</div>
                      <nav className="space-y-1 px-2">{desktopNavItems}</nav>
                    </div>
                    <div className="relative bottom-0 p-4">{bottomStickyItems}</div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div
        className={clsx(
          // "hidden shadow-[2px_0px_4px_rgba(89,89,89,0.5)] scrollbar-hide md:fixed md:inset-y-0 md:flex md:w-96 md:flex-col ",
          "hidden h-screen shadow-[2px_0px_4px_rgba(89,89,89,0.5)] scrollbar-hide md:inset-y-0  md:flex-col",
          isSidebarCustomized ? "bg-[#2a5070]" : "bg-black md:flex",
          additionalClasses,
        )}
      >
        {!isMobile && (
          <div className="flex flex-grow flex-col overflow-y-auto">
            <div className="hidden md:block">{topStickyItems}</div>
            <div className="grow overflow-y-scroll scrollbar-hide">{desktopNavItems}</div>
          </div>
        )}
        <div className="px-2">{bottomStickyItems}</div>
      </div>

      {/* <div className="md:pl-64">
        <div className=" flex flex-col md:px-8 xl:px-8">
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-primary md:hidden">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div> */}
    </>
  );
}
