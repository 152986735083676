/* eslint-disable import/no-extraneous-dependencies */
// import Modal from "components/atoms/modal";
import TabButton from "components/atoms/buttons/TabButton";
import { ReactElement } from "react";
import DefaultImage from "assets/Images/BrandImage1.png";
import { MerchDropIcon } from "components/atoms/Icon";
import MagnetImageContainer from "components/atoms/images/MagnetImageContainer";
import Modal from "react-modal";
import MerchDropBasicInfoForm from "./MerchDropBasicInfoForm";
import MerchDropShippingInfoForm from "./MerchDropShippingInfoForm";

Modal.setAppElement("#root");

type Props = {
  merchDropImageUrl: string;
  merchDropDescription: string;
  brandName: string;
  brandId: string;
  magnetImageUrl: string;
  modalAdditionalClasses?: string;
  magnetName: string;
  getActionButtons: ReactElement;
  onClose: () => void;
  isUserViewModal?: boolean;
  currentFormStep: number;
  handleSetCurrentFormStep: (formStep: number) => void;
  merchDropName: string;
  merchDropOptions: Omit<MerchDropOptions, "id">[];
};

function MerchDropModal({
  merchDropImageUrl,
  merchDropDescription,
  brandName,
  brandId,
  magnetName,
  magnetImageUrl,
  getActionButtons,
  isUserViewModal,
  currentFormStep,
  merchDropName,
  modalAdditionalClasses,
  merchDropOptions,
  handleSetCurrentFormStep,
  onClose,
}: Props) {
  const getForm = (step: Number) => {
    switch (step) {
      case 0:
        return (
          <MerchDropBasicInfoForm
            merchDropDescription={merchDropDescription}
            merchDropOptions={merchDropOptions}
            isUserViewModal={isUserViewModal}
          />
        );
      case 1:
        return <MerchDropShippingInfoForm brandId={brandId} />;
      default:
        return (
          <MerchDropBasicInfoForm merchDropDescription={merchDropDescription} merchDropOptions={merchDropOptions} />
        );
    }
  };
  return (
    <Modal
      ariaHideApp={false}
      isOpen
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      className={modalAdditionalClasses}
      style={{
        content: {
          padding: 0,
          inset: "50% auto auto 50%",
          background: "none",
          border: "none",
          transform: "translate(-50%, -50%)",
          borderRadius: "1rem",
        },
        overlay: {
          zIndex: 500,
          backgroundColor: "rgba(255,255,255,0.5)",
          inset: 0,
        },
      }}
    >
      <div className="secondary-scrollbar relative max-h-[90vh] w-[calc(100vw-20px)] overflow-y-auto rounded-2xl bg-primary p-4 shadow-modal md:w-[80vw] md:p-7 lg:p-10">
        <div className="space-y-3 font-sora md:space-y-7">
          <div className="relative flex space-x-3 2xl:space-x-5">
            {/* <button
              type="button"
              className="absolute right-0  top-0 rounded-md p-4 text-white hover:text-opacity-70"
              onClick={onClose}
            >
              <XMarkIcon className="h-7 w-7" aria-hidden="true" />
            </button> */}
            <div className="h-20 w-20 2xl:h-24 2xl:w-24">
              <img
                className="h-full w-full rounded-xl border border-white object-cover"
                src={magnetImageUrl || DefaultImage}
                alt="sdv"
              />
            </div>
            <div className="mt-1">
              <div className="flex space-x-1 2xl:space-x-2">
                <MerchDropIcon className="h-4 w-4 2xl:h-5 2xl:w-5" />
                <p className="font-satoshi text-xxs font-semibold leading-5 text-white md:text-xs 2xl:text-sm">
                  Merchandise Drop Memento
                </p>
              </div>
              <p className="mt-0 font-satoshi text-lg  font-bold leading-7 text-white md:text-2xl md:leading-10 2xl:mt-2 2xl:text-3xl">
                {magnetName}
              </p>
              <p
                className="font-satoshi text-xxs font-semibold
                     leading-5 text-white text-opacity-70 2xl:text-xs"
              >
                {brandName}
              </p>
            </div>
          </div>

          {/* Form Tabs */}
          {isUserViewModal && (
            <div className="space-x-5 font-sora text-xs font-semibold lg:text-lg">
              <TabButton
                label="BASIC INFO"
                isActive={currentFormStep === 0}
                handleOnClick={() => handleSetCurrentFormStep(0)}
                bottomBarClasses="w-1/4 bg-black"
              />
              <TabButton
                label="SHIPPING INFO"
                isActive={currentFormStep === 1}
                handleOnClick={() => handleSetCurrentFormStep(1)}
                bottomBarClasses="w-1/4"
              />
            </div>
          )}

          <div className="grid gap-x-8 md:grid-cols-10 ">
            <div className="col-span-10 mx-auto aspect-1 w-1/2 rounded-2xl border border-white md:col-span-3 md:w-full">
              <MagnetImageContainer image={merchDropImageUrl || DefaultImage} altText="merch drop" />
            </div>

            <div className="secondary-scrollbar col-span-10 mt-8 space-y-2 overflow-y-auto pb-8 pr-3 md:col-span-7 md:mt-auto md:h-[50vh]">
              <p className="font-satoshi text-lg font-bold leading-tight text-white lg:text-2xl">{merchDropName}</p>
              {getForm(currentFormStep)}
            </div>
          </div>
          {/* Forms */}
        </div>

        <div
          className="flex w-full justify-end space-x-2 rounded-b-2xl 
        pt-4 shadow-2xl 2xl:pt-5"
        >
          {getActionButtons}
        </div>
      </div>
    </Modal>
  );
}
export default MerchDropModal;
