import React, { useContext, useState } from "react";
import Loading from "../atoms/Loading";

export type LoaderContextType = {
  loading: boolean;
  setLoading: (b: boolean, subtext?: string) => void;
};

export const LoaderContext = React.createContext<LoaderContextType>({
  loading: false,
  setLoading: () => {},
});

export default function LoaderProvider({ children }: any): JSX.Element {
  const [loadingData, setLoadingData] = useState({ isLoading: false, subtext: "" });

  const setGlobalLoader = (isLoading: boolean, subtext = "") => {
    setLoadingData({ isLoading, subtext });
  };
  return (
    // @ts-ignore
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <LoaderContext.Provider value={{ loading: loadingData.isLoading, setLoading: setGlobalLoader }}>
      {loadingData.isLoading && <Loading subtext={loadingData.subtext} />}
      {children}
    </LoaderContext.Provider>
  );
}

export const useLoaderContext = () => {
  const context = useContext(LoaderContext);
  return context;
};
