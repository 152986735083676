import { CloseIcon } from "components/atoms/Icon";
import Modal from "components/atoms/modal";
import Button from "components/atoms/buttons/Button";
import { useLoaderContext } from "components/context/LoaderContext";
import { post } from "utils/request";
import { getRespondShareRequestEndPoint } from "apis/shareRequest";
import toast from "react-hot-toast";
import { useUserContext } from "components/context/UserContext";
import { useRef } from "react";
import { Link } from "react-router-dom";
import AuthorizeButton from "../AuthorizeButton";

type Props = {
  isOpen: boolean;
  id: string;
  name: string;
  media?: { mediaUrl: string; mediaType: string };
  postPreview: any;
  magnetId: string;
  magnetName: string;
  magnetImageUrl: string;
  brandName: string;
  brandId: string;
  onClose: () => void;
  refetchMementoData?: Function;
};

function ShareRequestConfirmPostModal({
  isOpen,
  id,
  name,
  media,
  postPreview,
  magnetId,
  magnetName,
  magnetImageUrl,
  brandName,
  brandId,
  onClose,
  refetchMementoData,
}: Props) {
  const { loading, setLoading } = useLoaderContext();
  const { userDetails } = useUserContext();
  const { has_authorized_twitter: hasAuthorizedTwitter } = userDetails as User;

  const authorizeTwitterRef = useRef<HTMLButtonElement>(null);

  const acceptShareRequest = () => {
    if (!hasAuthorizedTwitter) {
      authorizeTwitterRef.current?.click();
      return;
    }
    try {
      setLoading(true, "Please Wait! Processing Share Request.");
      post(
        getRespondShareRequestEndPoint(magnetId, id),
        {},
        {
          processData: () => {
            if (refetchMementoData) {
              refetchMementoData();
            }
            setLoading(false);
            onClose();
            toast.success("Share Request Completed Successfully!");
          },
          processError: () => {
            setLoading(false);
            toast.error("Something went wrong! Couldn't process Share Request.");
          },
        },
      );
    } catch {
      setLoading(false);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="m-2 w-[95vw] max-w-[27rem] overflow-hidden rounded-2xl bg-primary p-5 font-satoshi text-white shadow-modal 2xl:p-7">
        {/* Section 1  */}
        <div className="flex items-start justify-between gap-14">
          <div className="flex items-start justify-center space-x-3">
            <img
              className="h-16 w-16 rounded-lg border border-white object-cover 2xl:h-20 2xl:w-16"
              src={magnetImageUrl}
              alt={magnetName}
            />
            <div className="flex flex-col items-start justify-start">
              <Link
                to={`/brand/${brandId}/magnet/${magnetId}`}
                onClick={onClose}
                className="text-lg font-bold leading-tight 2xl:text-xl"
              >
                {magnetName}
              </Link>
              <Link
                to={`/brand-public-profile/${brandId}`}
                onClick={onClose}
                className="-mt-1 text-base font-medium opacity-70 2xl:text-xxs"
              >
                {brandName}
              </Link>
            </div>
          </div>
          <button type="button" disabled={loading} onClick={onClose} className="p-3">
            <CloseIcon className="h-4 w-4" />
          </button>
        </div>
        <div className="max-w-96 mt-2 flex flex-col gap-2">
          {/* Section 3 */}
          <h1 className="-mb-1 text-sm font-bold 2xl:text-lg">{name}</h1>
          <div className="-mx-7 bg-black px-7 py-4">
            <div className="border-1 border-neutral-800 px-2.5 pb-3 pt-1.5 font-sora font-light">
              <p className="leading-snug">{postPreview}</p>
              <p className="cursor-pointer text-xxs text-blue-600 underline underline-offset-4">Link to Magnet</p>
              {media && (
                <p className="mt-2 max-h-[25rem] overflow-hidden rounded-lg border-1 border-neutral-900">
                  {media?.mediaType.startsWith("video") ? (
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    <video controls className="max-h-[25rem] w-full" src={media.mediaUrl} />
                  ) : (
                    <img src={media?.mediaUrl} alt="tweet media" className="object-contain" />
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="-mb-0.5 -mr-2 mt-4 flex justify-end gap-2">
          {!hasAuthorizedTwitter && <AuthorizeButton hidden authorizationFor="twitter" ref={authorizeTwitterRef} />}
          <Button
            disabled={loading}
            text="CANCEL"
            variant="whiteOutline"
            additionalClasses="xl:py-1.5"
            onClick={onClose}
          />
          <Button
            disabled={loading}
            text="TWEET"
            variant="magnetiqSolid"
            additionalClasses="xl:py-1.5"
            onClick={acceptShareRequest}
          />
        </div>
      </div>
    </Modal>
  );
}

export default ShareRequestConfirmPostModal;
