import { useContext, useEffect, useState } from "react";
import { get } from "../../../utils/request";
import { BRAND_ROUTE } from "../../../constants";
import { BrandContext } from "../../context/BrandContext";
import BrandSidebar from "./BrandSidebar";
import Loading from "../../atoms/Loading";

function BrandSidebarContainer() {
  const { brandDetails: brandDetailsFromContext, loading: brandDetailsLoading, brandId } = useContext(BrandContext);
  const [magnetsForBrand, setmagnetsForBrand] = useState<Magnet[] | null>(null);

  // TODO: @abhay use useQuery here

  async function getmagnetsForBrandWithMagnets(id: string) {
    try {
      const magnetsForBrandResponse = await get(`${BRAND_ROUTE}${id}/magnet/sidebar/?type=dashboard`);
      if (magnetsForBrandResponse === null) {
        alert("Failed to fetch brand details.");
      } else {
        setmagnetsForBrand(magnetsForBrandResponse);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      // TODO: Missing Loading State.
    }
  }

  useEffect(() => {
    if (!brandDetailsLoading && brandDetailsFromContext && brandId) {
      getmagnetsForBrandWithMagnets(brandId);
    }
  }, [brandDetailsFromContext, brandDetailsLoading]);

  if (brandDetailsLoading) return <Loading />;

  return <BrandSidebar magnets={magnetsForBrand || []} />;
}
export default BrandSidebarContainer;
