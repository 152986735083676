import { create } from "zustand";

interface NotificationState {
  notifications: any[];
  notificationsFromBE: any[];
  setNotificationsFromBackend: (notificationsFromBE: any[]) => void;
  setNotifications: (notificationsFromFirebase: any[]) => void;
}

const useNotificationStore = create<NotificationState>((set) => ({
  notifications: [],
  setNotifications: (notificationsFromFirebase: any[]) => set(() => ({ notifications: notificationsFromFirebase })),
  notificationsFromBE: [],
  setNotificationsFromBackend: (notificationsFromBE: any[]) => set(() => ({ notificationsFromBE })),
}));

export default useNotificationStore;
