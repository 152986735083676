import { useEffect, useState } from "react";

const useDeviceType = (width?: number) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function setIsMobileState() {
      setIsMobile((document.documentElement.clientWidth || window.innerWidth) <= (width || 1275));
    }
    window.addEventListener("resize", setIsMobileState);
    setIsMobileState();
    return () => window.removeEventListener("resize", setIsMobileState);
  }, []);

  return {
    isMobile,
  };
};

export default useDeviceType;
