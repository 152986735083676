export default function OnlyAvailableOnDesktop() {
  return (
    <div className="fixed top-0 z-40 h-[100vh] w-[100vw] bg-primary px-4 py-24 text-white">
      <div className="mx-auto min-w-full max-w-full px-4 text-center">
        <h1 className="text-center text-3xl font-bold">Brand Dashboard</h1>
        <hr className="mx-auto mb-20 w-[60%] opacity-70" />
        <h1 className="text-3xl font-bold leading-8 tracking-tighter">This Page is Only Available on Desktop</h1>
        <p className="mb-5 mt-2 text-base text-gray-500">Please use a desktop to manage your brands.</p>
        <hr className="mx-auto mb-4 mt-20 w-[70%] opacity-70" />
        <a href="/" className="block text-center text-sm font-medium underline underline-offset-1">
          Go back home
        </a>
      </div>
    </div>
  );
}
