import { useContext } from "react";
import { Outlet } from "react-router-dom";
import Loading from "../atoms/Loading";
import { UserContext } from "../context/UserContext";

function UserAccountRoute() {
  const { loading } = useContext(UserContext);

  if (loading) return <Loading />;

  return <Outlet />;
}

export default UserAccountRoute;
