import ErrorMessage from "components/atoms/forms/ErrorMessage";
import Label from "components/atoms/forms/Label";
import { TickIcon } from "components/atoms/Icon";
// import { UseFormRegister } from "react-hook-form";

type Props = {
  options: string[];
  // register: UseFormRegister<any>;
  id: string;
  label: string;
  optionIdx: number;
  isDisabled?: boolean;
  errorMessage: string | undefined;
  selected?: string;
  onClick: (id: string) => void;
};

function ChoiceCheckboxInputElement({
  options,
  // register,
  id,
  label,
  isDisabled,
  optionIdx,
  errorMessage,
  selected,
  onClick,
}: Props) {
  return (
    <div>
      <Label label={label} htmlFor={id} labelClasses="2xl:text-sm" />
      <div className="mt-2 grid grid-cols-1 gap-1 md:grid-cols-2 2xl:gap-2">
        {options.map((option, idx) => (
          <label
            // eslint-disable-next-line react/no-array-index-key
            key={id + optionIdx + idx + option}
            htmlFor={`${option}-${optionIdx}-${idx}`}
            onClick={() => onClick(`${idx}`)}
          >
            <div className="relative w-full">
              <input
                type="radio"
                // eslint-disable-next-line react/jsx-props-no-spreading
                checked={selected === String(idx)}
                value={idx}
                disabled={isDisabled}
                id={`${option}-${optionIdx}-${idx}`}
                className="peer hidden"
                required
                readOnly
              />
              <div
                className="flex h-full w-full cursor-pointer 
              items-center  rounded-xl  border border-white bg-primary p-3
              text-xs transition hover:bg-opacity-75 peer-checked:border-opacity-100 peer-disabled:cursor-default 2xl:p-4 2xl:text-sm"
              >
                <span className="line-clamp-1 flex w-full max-w-[93%] flex-wrap pr-4 font-medium text-white ">
                  {option}
                </span>
              </div>
              <div
                className="absolute bottom-0 right-4 top-0 my-auto flex h-7 w-7 cursor-default rounded-full
             border border-white  transition delay-100 duration-100  peer-checked:border-none
             peer-checked:bg-[#1EB236] peer-disabled:hidden"
              >
                <TickIcon />
              </div>
            </div>
          </label>
        ))}
      </div>
      <ErrorMessage errorMessage={errorMessage} />
    </div>
  );
}
export default ChoiceCheckboxInputElement;
