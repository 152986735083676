import {
  ACTIVATIONS_ROUTE,
  BRAND_ROUTE,
  GET_ALL_MAGNETS,
  MAGNET,
  MAGNET_ACTIVITY,
  MAGNET_ROUTE,
  MEMENTO_ROUTE,
  PUBLISH,
  RELEASE,
  USER_INVENTORY,
} from "../constants";

export const getAllMagnetsURL = () => MAGNET_ROUTE;
export const getTrendingMagnetsURL = () => `${MAGNET_ROUTE}?sort_type=trending`;
export const getMagnetDetailURL = (magnetId: string) => `${MAGNET_ROUTE}${magnetId}/`;
export const getCreateMagnetURL = (brandId: string) => `${BRAND_ROUTE}${brandId}${MAGNET}`;
export const getBuyMagnetURL = (magnetId: string) => `${GET_ALL_MAGNETS + magnetId}/buy/`;
export const getCreateReleaseEndpoint = (magnetId: string) => `${BRAND_ROUTE}magnet/${magnetId}${RELEASE}/`;
export const getPublishReleaseEndpoint = (magnetId: string, releaseId: string) =>
  `${`${BRAND_ROUTE}magnet/${magnetId}${RELEASE}/${releaseId}${PUBLISH}`}`;

export const getAirDropMementoRoute = (magnetId: string, activationKey: ActivationsKeys | "airdrop" = "airdrop") =>
  `${ACTIVATIONS_ROUTE}magnet/${magnetId}/drop/${activationKey}/`;
export const getAirDropEndRoute = (magnetId: string, mementoId: string) =>
  `${ACTIVATIONS_ROUTE}magnet/${magnetId}/drop/mem_drop/${mementoId}/end/`;
export const getActivationsForMagnet = (magnetId: string) => `${ACTIVATIONS_ROUTE}magnet/${magnetId}/`;
export const getMementoInfo = (magnetId: string, activationKey: ActivationsKeys, mementoId: string) =>
  `${ACTIVATIONS_ROUTE}magnet/${magnetId}/drop/${activationKey}/${mementoId}/`;

export const publishMemento = (magnetId: string, mementoId: string) =>
  `${ACTIVATIONS_ROUTE}magnet/${magnetId}/publish/${mementoId}/`;

export const getPublicMemento = (mementoId: string, isPublic?: boolean) =>
  `${MEMENTO_ROUTE}${mementoId}/${isPublic ? "?type=public" : ""}`;
export const getMagnetActivity = (magnetId: string) => `${MAGNET_ACTIVITY}${magnetId}`;

export const getUserMementosForMagnet = (magnetId: string) => `${USER_INVENTORY}/?type=MEMENTO&magnet_id=${magnetId}`;
export const getUserIncentivesForMagnet = (magnetId: string) =>
  `${USER_INVENTORY}/?type=INCENTIVE&magnet_id=${magnetId}`;
export const getClaimMagnetURL = (magnetId: string) => `${MAGNET_ROUTE}${magnetId}/event-claim-request/`;
export const getMementosForMagnet = (magnetId: string) => `${MAGNET_ROUTE}${magnetId}/mementos/`;
export const getIncentivesForMagnet = (magnetId: string) => `${MAGNET_ROUTE}${magnetId}/mementos/?type=incentive`;
