type Props = {
  htmlFor: string;
  label: React.ReactNode;
  labelClasses?: string;
};

function Label({ htmlFor, label, labelClasses }: Props) {
  return (
    <label htmlFor={htmlFor} className={`mb-1.5 block text-xs font-medium text-white ${labelClasses}`}>
      {label}
    </label>
  );
}
export default Label;
