import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import "./index.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ThemeProvider } from "@mui/material/styles";
import { HelmetProvider } from "react-helmet-async";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { theme } from "./config/theme";

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  environment: process.env.REACT_APP_ENVIRONMENT,
  dsn: "https://610f547c4f1ab388fa621fe051898f9d@o4507853463486464.ingest.us.sentry.io/4507853465059328",
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      useCompression: true,
    }),
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: process.env.REACT_APP_ENVIRONMENT === "production" ? 0.2 : 0.5,
  // debug: process.env.REACT_APP_ENVIRONMENT !== "production",
  release: `magnetiq-fe@${process.env.REACT_APP_VERSION}`,
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
