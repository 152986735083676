import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { MagnetiqLogo } from "components/atoms/Icon";
import { useRouter } from "components/hooks";
import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ALLOW_UNAPPROVED_BRANDS, ROUTES } from "../../constants";
import Loading from "../atoms/Loading";
import { BrandContext } from "../context/BrandContext";

function BrandRoute() {
  const { brandDetails, brandId, loading } = useContext(BrandContext);
  const router = useRouter();
  const handleNavigateToDiscoverPage = () => {
    router.go(ROUTES.BRANDS_DISCOVER);
  };

  if (loading) return <Loading />;

  if (!brandId) <Navigate to={ROUTES.BRAND_ONBOARDING} />;

  if (!brandDetails)
    return (
      <div className="flex h-full flex-col items-center justify-center gap-4 pb-60  text-white">
        <MagnetiqLogo className="w-56 2xl:w-48" />

        <h1 className="flex items-center gap-2 font-sora text-4xl font-medium ">
          Looks like you don&apos;t have access to this page
          <ExclamationCircleIcon className="h-8 w-8 text-white" />{" "}
        </h1>
        <p className="text-lg font-medium">Please try logging in with a different account or return to the homepage.</p>
        <button type="button" className="text-md underline underline-offset-4 " onClick={handleNavigateToDiscoverPage}>
          Goto Discover page
        </button>
        {/* <ul className="mb-1 list-disc space-y-2 pl-5 text-white opacity-80">
          <li>Please check your network connection</li>
          <li>Try reloading the page</li>
        </ul> */}
      </div>
    );

  return brandDetails.is_approved || ALLOW_UNAPPROVED_BRANDS === "true" ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTES.BRANDS_SIGNUP_SUCCESS} />
  );
}
export default BrandRoute;
