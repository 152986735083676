import clsx from "clsx";
import { useState } from "react";
import { Blurhash } from "react-blurhash";

type Props = {
  imageContainerClasses: string;
  imageHash: string | null;
  src: string;
  alt: string;
};

export default function BlurredImage({ imageContainerClasses, imageHash, src, alt }: Props) {
  const [renderBlurredImage, setRenderBlurredImage] = useState(!!imageHash);

  const onImageLoaded = () => {
    setRenderBlurredImage(false);
  };

  return (
    <>
      {renderBlurredImage && imageHash && (
        <div className={clsx(imageContainerClasses, "overflow-hidden")}>
          <Blurhash hash={imageHash} width="100%" height="100%" resolutionX={100} resolutionY={100} punch={1} />
        </div>
      )}
      <img
        // className={clsx(imageContainerClasses, renderBlurredImage && "h-0 w-0")}
        className={clsx(imageContainerClasses, renderBlurredImage && "hidden")}
        src={src}
        alt={alt}
        onLoad={onImageLoaded}
      />
    </>
  );
}
