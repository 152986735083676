import { InformationCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { InstagramIcon, TwitterIcon } from "components/atoms/Icon";
import { Ref, forwardRef, useRef } from "react";
import { Tooltip } from "react-tooltip";
import { get } from "utils/request";

type Props = {
  authorizationFor: "twitter" | "instagram";
  hidden?: boolean;
};

const AuthorizeButton = forwardRef(({ authorizationFor, hidden = false }: Props, ref: Ref<HTMLButtonElement>) => {
  const authPopupRef = useRef<Window | null>(null);

  return (
    <span className={clsx("flex items-center gap-1", hidden && "invisible")}>
      <button
        ref={ref}
        type="button"
        className={clsx(
          "right-0 top-[0] flex items-center gap-1 rounded-full px-3 pb-1 pt-0.5 font-satoshi text-base font-bold text-white",
          authorizationFor === "twitter"
            ? "bg-blue-500 hover:bg-blue-600 active:bg-blue-400"
            : "bg-fuchsia-700 hover:bg-fuchsia-600 active:bg-fuchsia-500",
        )}
        onClick={async () => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          get(`/api/v1/social-media/${authorizationFor}/oauth2`).then((response) => {
            authPopupRef.current = window.open(
              response.redirect_url,
              "hello",
              "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=900",
            );
            // authPopupRef.current = window.open("http://localhost:5500/", "hello", "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=900,top=-1000");
          });
        }}
      >
        {authorizationFor === "twitter" ? (
          <TwitterIcon className="mt-0.5 h-3.5 w-3.5 text-white opacity-90" />
        ) : (
          <InstagramIcon className="mt-0.5 h-3.5 w-3.5 opacity-90" />
        )}
        Authorize
      </button>
      <div>
        <Tooltip anchorSelect=".info-icon" place="bottom">
          <p className="w-80 whitespace-break-spaces text-[14px] font-semibold">
            Link your twitter account to participate in Share Requests and gather Exclusive Benefits!
          </p>
        </Tooltip>
        <InformationCircleIcon className="info-icon h-4 w-4 text-white opacity-90" />
      </div>
    </span>
  );
});

export default AuthorizeButton;
