import ErrorMessage from "../../atoms/forms/ErrorMessage";
import Label from "../../atoms/forms/Label";
import SelectField from "../../atoms/SelectField";

type Props = {
  label: string;
  id: string;
  placeHolder: string;
  errorMessage: string | undefined;
  register: any;
  options: string[] | { display: string; value: string }[];
  selectedValue?: string;
  additionalClasses?: string;
  optionsLoading: boolean;
  isCountrySelector?: boolean;
  labelClasses?: string;
};

function SelectElement({
  label,
  placeHolder,
  id,
  additionalClasses,
  selectedValue,
  errorMessage,
  register,
  options,
  optionsLoading,
  isCountrySelector,
  labelClasses = "",
}: Props) {
  return (
    <div className="relative mt-1 rounded-md">
      <Label labelClasses={labelClasses} htmlFor={id} label={label} />
      <SelectField
        placeHolder={placeHolder}
        id={id}
        additionalClasses={additionalClasses}
        selectedValue={selectedValue}
        register={register}
        options={options}
        optionsLoading={optionsLoading}
        isCountrySelector={isCountrySelector}
      />
      <ErrorMessage errorMessage={errorMessage} />
    </div>
  );
}
export default SelectElement;
