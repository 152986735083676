import MerchDropModal from "components/organisms/merchdrop/MerchDropModal";
import { useContext, useState } from "react";
import { FormProvider, useForm, UseFormHandleSubmit } from "react-hook-form";
import { UserContext } from "components/context/UserContext";
import Button from "components/atoms/buttons/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import { LoaderContext } from "components/context/LoaderContext";
import { post } from "utils/request";
import { getMerchDropClaimResponseEndPoint } from "apis/merchdrop";
import { handleSetErrorAndSwitchFormState } from "utils/HelperFunctions";

type Props = {
  merchDropDetails: MerchDropUserData;
  hideAddress: boolean;
  merchDropId: string;
  onClose: () => void;
  refetchMerchDropData: () => void;
};

function BottomBarButtons({
  handleGoBackButtonClick,
  handleNextOrClaimButtonClick,
  handleSubmit,
  currentStep,
}: {
  handleSubmit: UseFormHandleSubmit<any>;
  handleGoBackButtonClick: () => void;
  handleNextOrClaimButtonClick: (formData: MerchDropFormData) => void;
  currentStep: number;
}) {
  return (
    <>
      <Button
        variant="whiteOutline"
        text="GO BACK"
        additionalClasses="xl:px-6 xl:py-3 xl:text-xs 2xl:text-sm 2xl:px-8 2xl:py-4"
        onClick={handleGoBackButtonClick}
      />

      <Button
        text={currentStep ? "CLAIM" : "NEXT"}
        additionalClasses="xl:px-6 xl:py-3 xl:text-xs 2xl:text-sm 2xl:px-8 2xl:py-4"
        variant="magnetiqSolid"
        onClick={handleSubmit(handleNextOrClaimButtonClick)}
      />
    </>
  );
}

const formFields = {
  shipping_response: "1",
  options_response: "0",
};

type FormFieldsType = "shipping_response" | "options_response";

export type MerchDropUserResponse = {
  options_response: { choices_selected: string }[];
  shipping_response: {
    first_name: string;
    last_name: string;
    address_line_1: string | null;
    address_line_2?: string | null;
    country: string | null;
    state: string | null;
    city: string | null;
    zipcode: string | null;
  };
};

const merchDropFormSchema = (responseCount: number) => [
  yup.object().shape({
    options_response: yup
      .array()
      .of(
        yup.object().shape({
          choices_selected: yup.number().typeError("* Required field").required("* Required field"),
        }),
      )
      .min(responseCount, "* Required field"),
  }),
  yup.object().shape({
    shipping_response: yup.object().shape({
      first_name: yup.string().trim().nullable().required("* Required"),
      last_name: yup.string().trim().nullable().required("* Required"),
      address_line_1: yup.string().trim().nullable().required("* Required"),
      // address_line_2: yup.string().trim().nullable().required("* Required"),
      country: yup.string().trim().nullable().required("* Required"),
      state: yup.string().trim().nullable().required("* Required"),
      city: yup.string().trim().nullable().required("* Required"),
      zipcode: yup
        .string()
        .trim()
        .max(15, "Zip Code Cannot Be Longer Than 15 Characters")
        .nullable()
        .required("* Required"),
    }),
  }),
];

function MerchDropUserResponseModalContainer({
  merchDropDetails,
  onClose,
  merchDropId,
  refetchMerchDropData,
  hideAddress,
}: Props) {
  const [currentFormStep, setCurrentFormStep] = useState<number>(0);

  const { userDetails, reloadUserData } = useContext(UserContext);
  const { setLoading } = useContext(LoaderContext);
  const userData = userDetails as User;

  const formMethods = useForm<MerchDropUserResponse>({
    resolver: yupResolver(merchDropFormSchema(merchDropDetails?.options?.length)[currentFormStep]),
    defaultValues: {
      options_response: [],
      shipping_response: {
        first_name: userData.first_name,
        last_name: userData.last_name,

        ...(hideAddress
          ? {
              address_line_1: "",
              address_line_2: "",
              country: "",
              state: "",
              city: "",
              zipcode: "",
            }
          : {
              address_line_1: userData.address_line1,
              address_line_2: userData.address_line2,
              city: userData.city,
              country: userData.country,
              state: userData.state,
              zipcode: userData.zipcode,
            }),
      },
    },

    mode: "onChange",
  });
  const { handleSubmit, trigger, setError } = formMethods;

  const handleSetCurrentFormStep = async (formStep: number) => {
    if (await trigger()) setCurrentFormStep(formStep);
  };

  const handleClaimMerchDropSuccess = () => {
    setLoading(false);
    refetchMerchDropData();
    // This needs to triggered to update the inventory for the user.
    reloadUserData();
    toast.success("Merch successfully claimed");
    onClose();
  };
  const handleClaimMerchDropError = (errorMessage: string, error: any, errorData: any) => {
    toast.error(errorMessage);
    setLoading(false);
    const step = handleSetErrorAndSwitchFormState<FormFieldsType>(errorData, formFields, setError);
    if (step) setCurrentFormStep(Number(step));
  };

  const transformFormDataToPayload = (formData: MerchDropUserResponse) => ({
    ...formData,
    // merchdrop question id added in response payload.
    // Choice index is required by backend to determine which option is chosen.
    options_response: formData.options_response.map((option, idx) => ({
      id: idx + 1,
      choices_selected: [Number(option.choices_selected) + 1],
    })),
  });

  const claimMerchDrop = async (formData: MerchDropUserResponse) => {
    if (!formData.shipping_response.address_line_2) {
      // eslint-disable-next-line no-param-reassign
      delete formData.shipping_response.address_line_2;
    }
    setLoading(true);
    post(
      getMerchDropClaimResponseEndPoint(merchDropDetails.magnet_id, merchDropId),
      transformFormDataToPayload(formData),
      {
        processData: handleClaimMerchDropSuccess,
        processError: handleClaimMerchDropError,
      },
    );
  };

  const handleNextOrClaimButtonClick = (formData: any) => {
    if (currentFormStep === 1) {
      claimMerchDrop(formData);
    } else {
      handleSetCurrentFormStep(1);
    }
  };

  return (
    <FormProvider
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...formMethods}
    >
      <MerchDropModal
        brandName={merchDropDetails.brand_name}
        brandId={merchDropDetails.brand_id}
        magnetName={merchDropDetails.magnet_name}
        magnetImageUrl={merchDropDetails.magnet_image_url as string}
        merchDropDescription={merchDropDetails.description}
        merchDropName={merchDropDetails.name}
        merchDropImageUrl={merchDropDetails.thumbnail_url as string}
        isUserViewModal
        onClose={onClose}
        currentFormStep={currentFormStep}
        handleSetCurrentFormStep={handleSetCurrentFormStep}
        getActionButtons={
          <BottomBarButtons
            handleGoBackButtonClick={onClose}
            handleNextOrClaimButtonClick={handleNextOrClaimButtonClick}
            handleSubmit={handleSubmit}
            currentStep={currentFormStep}
          />
        }
        merchDropOptions={merchDropDetails.options}
      />
    </FormProvider>
  );
}
export default MerchDropUserResponseModalContainer;
