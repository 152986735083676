import clsx from "clsx";

type Props = any;

function ErrorMessage({ errorMessage, errorMessageClasses }: Props) {
  return (
    <p className={clsx("text-xxs font-normal text-red-600 first-letter:uppercase", errorMessageClasses)}>
      {errorMessage}
    </p>
  );
}
export default ErrorMessage;
