import { useUserContext } from "components/context/UserContext";
import { isMediaOfTypeAudioOrVideo, isMediaOfTypeDocument, isMediaOfTypeImage } from "utils/helpers";
import Modal from "components/atoms/modal";
import { BrightBoxIcon, CloseIcon } from "components/atoms/Icon";
import VideoPreviewer from "components/atoms/VideoPreviewer";
import BlurredImage from "components/atoms/BlurredImage";
// import Button from "components/atoms/buttons/Button";
import { CheckCircleIcon, ExclamationTriangleIcon, LockClosedIcon, LockOpenIcon } from "@heroicons/react/24/solid";
// import { useRouter } from "components/hooks";
// import { useMemo } from "react";
import { downloadOnClick } from "utils";
import { Link } from "react-router-dom";

function ViewMementoModal({ mementoId, onClose, data }: { mementoId: string; onClose: any; data: any }) {
  const { isMagnetInUserInventory } = useUserContext();
  // const router = useRouter();
  const isMagnetOwnedByUser = isMagnetInUserInventory(data.magnet_id);
  const isMementoOwnedUser = isMagnetInUserInventory(mementoId);

  const isFileAudioOrVideo = isMediaOfTypeAudioOrVideo(data.media_type || data.media_url || "");
  const isFileImage = isMediaOfTypeImage(data.media_type || data.media_url || "");
  const isFileDocument = isMediaOfTypeDocument(data.media_type || data.media_url || "");

  const isGatedContent = data.is_gated;

  const bottomBarContent = (() => {
    if (isMagnetOwnedByUser && isMementoOwnedUser) {
      return (
        <>
          <CheckCircleIcon color="white" className="h-4 w-4 opacity-50" />
          <p className="font-sora text-xxs font-semibold text-white opacity-50">
            Exclusive Gated Content: You have access to this Content
          </p>
        </>
      );
    }

    // User doesn't own the magnet.
    if (!isMagnetOwnedByUser) {
      if (data.auto_drop_on_purchase)
        return (
          <>
            <ExclamationTriangleIcon color="white" className="h-4 w-4 opacity-50" />
            <p className="font-sora text-xxs font-semibold text-white opacity-50">Buy the Magnet now to get access</p>
          </>
        );

      return (
        <>
          <ExclamationTriangleIcon color="white" className="h-4 w-4 opacity-50" />
          <p className="font-sora text-xxs font-semibold text-white opacity-50">
            Exclusive Gated Content: Only owners of the memento can view this
          </p>
        </>
      );
    }
    // User Owns the Magnet
    if (isMagnetOwnedByUser) {
      // Since autodrop is set to false. user will never get the memento.
      if (!data.auto_drop_on_purchase) {
        return (
          <>
            <LockClosedIcon color="white" className="h-4 w-4 opacity-50" />
            <p className="font-sora text-xxs font-semibold text-white opacity-50">
              Exclusive Gated Content: Only owners of the memento can view this
            </p>
          </>
        );
      }
      // Allowed Airdrop Purchase.
      return (
        <>
          <ExclamationTriangleIcon color="white" className="h-4 w-4 opacity-50" />
          <p className="font-sora text-xxs font-semibold text-white opacity-50">
            Exclusive Gated Content: You need to claim this memento before you can view it
          </p>
        </>
      );
    }

    return null;
  })();

  // const buttonText = useMemo(() => {
  //   if (isGatedContent && !data.auto_drop_on_purchase) {
  //     return "ALREADY DROPPED";
  //   }
  //   return "BUY THE MAGNET";
  // }, [data]);

  // const redirectToMagnetPage = () => {
  //   router.go(getMagnetPageLink(data.brand_id, data.magnet_id));
  //   onClose();
  // };

  return (
    <Modal open={Boolean(mementoId)} onClose={onClose} closeOnOutsideClick>
      <div className="mx-auto w-[95vw] max-w-[550px] space-y-5 rounded-2xl bg-primary p-5 py-7 shadow-modal">
        {/* Section 1  */}
        <div className="flex justify-between gap-4">
          <div className="flex space-x-3 overflow-x-clip ">
            <Link to={`/brand/${data.brand_id}/magnet/${data.magnet_id}`} title={data.magnet_name} onClick={onClose}>
              <img
                className="aspect-1 h-16 rounded-lg border border-white object-cover"
                src={data.magnet_image_url ?? undefined}
                alt={data.magnet_image_url}
              />
            </Link>
            <div className="flex flex-col overflow-x-clip font-satoshi text-white">
              <span className="text-lg font-semibold leading-tight">{data.magnet_name}</span>
              <span className="opacity-70">{data.brand_name}</span>
              {isMagnetOwnedByUser && (
                <span className="inline-flex items-center gap-1 rounded-xl bg-accent bg-opacity-10 px-2">
                  <BrightBoxIcon color="text-accent" className="h-3 w-3  md:h-4 md:w-4" />
                  <p className="font-satoshi text-[10px] font-bold uppercase text-accent">You own this Magnet</p>
                </span>
              )}
            </div>
          </div>
          <button type="button" className="self-start p-1" onClick={onClose}>
            <CloseIcon className="h-5 w-5 text-white" />
          </button>
        </div>
        {/* Section 2 */}
        <div className="mx-auto flex flex-col items-center font-satoshi text-white">
          <div className="grid max-h-72 w-10/12 place-items-stretch">
            {/* {!isGatedContent || isMementoOwnedUser ? <></> : <></>} */}
            {isFileAudioOrVideo && ((isMementoOwnedUser && isMagnetOwnedByUser) || !isGatedContent) ? (
              <div className="h-72">
                <VideoPreviewer
                  videoPlayerId="memento_video_viewer"
                  src={data.media_url}
                  thumbnailUrl={data.thumbnail_url}
                />
              </div>
            ) : (
              <div className="relative h-full max-h-72">
                {isGatedContent && (
                  <div className="absolute -right-3 -top-3 rounded-full bg-accent p-1.5">
                    {isMagnetOwnedByUser ? (
                      <LockOpenIcon className="h-5 w-5" color="black" />
                    ) : (
                      <LockClosedIcon className="h-5 w-5" color="black" />
                    )}
                  </div>
                )}
                {isFileDocument ? (
                  <img
                    className="mx-auto max-h-72 rounded-2xl border border-white object-contain"
                    // eslint-disable-next-line no-nested-ternary
                    src={data.thumbnail_url}
                    alt={data.thumbnail_url}
                  />
                ) : (
                  // <img
                  //   className="mx-auto max-h-72 rounded-2xl border border-white object-contain"
                  //   // eslint-disable-next-line no-nested-ternary
                  //   src={
                  //     // eslint-disable-next-line no-nested-ternary
                  //     isFileImage || (isGatedContent && isMagnetOwnedByUser)
                  //       ? data.media_url || data.thumbnail_url
                  //       : data.thumbnail_url
                  //   }
                  //   alt={data.media_url}
                  // />
                  <BlurredImage
                    imageHash={
                      // eslint-disable-next-line no-nested-ternary
                      isFileImage || (isGatedContent && isMagnetOwnedByUser)
                        ? data.media_hash || data.thumbnail_hash
                        : data.thumbnail_hash
                    }
                    imageContainerClasses="mx-auto max-h-72 rounded-2xl border border-white object-contain"
                    src={
                      // eslint-disable-next-line no-nested-ternary
                      isFileImage || (isGatedContent && isMagnetOwnedByUser)
                        ? data.media_url || data.thumbnail_url
                        : data.thumbnail_url
                    }
                    alt={data.media_url}
                  />
                )}
              </div>
            )}
          </div>
          {data.secondary_media_url && (
            <div title={isGatedContent && !isMementoOwnedUser ? "You dont have access to this content" : ""}>
              <button
                onClick={() => downloadOnClick(data.secondary_media_url)}
                disabled={isGatedContent && !isMementoOwnedUser}
                type="button"
                className="mt-3 block rounded-full bg-accent bg-opacity-90 px-4 py-2.5 text-xxs font-semibold text-primary shadow-sm hover:bg-opacity-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent disabled:cursor-not-allowed disabled:opacity-40"
              >
                View Content
              </button>
            </div>
          )}
          {!data.secondary_media_url && (
            <div title={isGatedContent && !isMementoOwnedUser ? "You dont have access to this content" : ""}>
              <button
                onClick={() => downloadOnClick(data.media_url)}
                disabled={isGatedContent && !isMementoOwnedUser}
                type="button"
                className="mt-3 block rounded-full bg-accent bg-opacity-90 px-4 py-2.5 text-xxs font-semibold text-primary shadow-sm hover:bg-opacity-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent disabled:cursor-not-allowed disabled:opacity-40"
              >
                View Content
              </button>
            </div>
          )}
          <span className="mt-3 text-center text-lg font-450 leading-tight">{data.name}</span>

          <span className="secondary-scrollbar my-2 line-clamp-4 w-[90%] overflow-scroll overflow-x-hidden text-center font-satoshi text-xs font-450 text-white">
            {data.description}
          </span>
          {/* Buttons */}
          {/* {!isMagnetOwnedByUser && (
            <div className="flex h-12 space-x-4">
              <Button
                text={buttonText}
                additionalClasses="sm:px-14"
                variant="magnetiqSolid"
                onClick={redirectToMagnetPage}
                // When content is gated and autodrop is set to false
                disabled={isGatedContent && !data.auto_drop_on_purchase}
              />
            </div>
          )} */}
        </div>
      </div>
      {isGatedContent && (
        <div className="mx-auto -mt-6 inline-flex w-[95vw] max-w-[550px] items-center justify-center gap-2 rounded-b-lg bg-[#102D4E] p-2">
          {bottomBarContent}
        </div>
      )}
    </Modal>
  );
}

export default ViewMementoModal;
